<template>
  <div>
    <div class="navbox">展会</div>
      <div class="fenleibox">
        <div class="femls">
          <div class="taball" v-for="(item, index) in fenlArr" :key="index" @click="flclick(index,item. corpclass_id)">
          
             <div class="tabtext" :style="{'color': (index==ssid?'#000':'')}">{{ item.corp_class_name}}({{item.count}})</div>
             <div class="sansan" v-if="index==ssid?true:false"></div>
        </div>
    </div>
  </div>
    <div class="allbox">
      <div class="zhlist" v-for="(item,index) in zhArr" :key="index">
        <router-link :to="'/Exhibidetail?id='+item.expo_id">
        <img class="zhimg" :src="item.expo_avatar" alt="" />
        <div class="zhcon">
            <div class="zhtit">{{item.expo_name}}</div>
            <div class="zhtext">{{item.expo_address_detail}}</div>
            <div class="zhtime">
              <div class="zhtimenum">
                <img class="z1img" src="../assets/imgs/z1.png" alt="">
                  2022.07.20-07.29
              </div>
              <div class="timebox">展会已进行</div>
            </div>
        </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        zhArr:[],
        fenlArr:[],
        ssid:'0',
        expo_class_id:'',
    };
  },

  created() {
    this.getAllArr();
  },
  methods: {
    getAllArr() {
      let formData = new FormData()
      formData.append("expo_class_id", this.expo_class_id);
      this.$http({
        method: "post",
        url: "/v1/app-expo/get-expo-list",
        // params: {
        //   expo_class_id: "999999", //
        //   // expo_area_id:"",//
        // },
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.zhArr=res.data.data.expoList
          this.fenlArr=res.data.data.CorpArr

        })
        .catch((err) => {
          console.log(err);
        });
    },
    flclick(index,corpclass_id){
      this.ssid=index,
      this.expo_class_id = corpclass_id
      console.log(this.expo_class_id)
      console.log(this.ssid,index)
      this.getAllArr();
    }
  },
};
</script>
<style scoped>
.allbox{
    margin-top: 1rem;
    background-color: #f5f5f5;
}
.navbox {
  width: 100%;
  height: 1rem;
  background: #fecb32;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 1.1rem;
  font-weight: bold;
  font-size: 0.3rem;
  justify-content: center;
}
.zhlist{
    margin-bottom: 0.2rem;
    background-color: #fff;
}
.zhimg{
    width: 100%;
    height: 3.8rem;
    /* background-color: aquamarine; */
}
.zhcon{
    padding: 0.3rem;
    padding-top: 0;
}
.zhtit{
    font-size: 0.3rem;
    font-weight: bold;
}
.zhtext{
    font-size: 00.22rem;
    color: #b1b1b1;
    margin-bottom: 0.1rem;
    margin-top: 0.3rem;
}
.zhtime{
    display: flex;
    font-size: 00.22rem;
    justify-content: space-between;

}
.zhside{
    font-size: 00.22rem;

}
.zhtimenum{
    display: flex;

}
.z1img{
    width: 0.29rem;
    height: 00.22rem;
    margin-top: 0.055rem;
    margin-right: 0.1rem;
}
.fenleibox {
  width: 7.5rem;
  height: 00.6rem;
  position: fixed;
  top: 1rem;
  left: 0;
  display: flex;
  justify-content: center;
  font-size: 0;
  background-color: rgb(241, 241, 241);
  overflow-x: scroll;
  display: flex;
  padding-left: 4rem;
  padding-right: 4rem;
}
.taball {
  width: 1.4rem;
  /* background-color: #d6d6d6; */
  text-align: center;
  /* margin: 0 0.2rem; */
}
.tabtext {
  font-size: 0.23rem;
  color: #5d5f6a;
  line-height: 0.6rem;
  /* margin: 0 0.1rem; */
}
.sansan {
  width: 0.28rem;
  height: 0.08rem;
  background: #fecb32;
  border-radius: 0.04rem;
  margin: 0 auto;
  margin-top: -0.08rem;
}
.femls {
  /* width: 100%; */
  display: flex;
  /* overflow-x: scroll; */
  
}
</style>