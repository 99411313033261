<template>
<div class="navbox">加盟</div>
<div class="fenleibox">
  <div class="taball">
    <div class="tabtext">行业</div>
    <div class="sansan"></div>
  </div>
  <div class="taball">
    <div class="tabtext">投资金额</div>
    <div class="sansan"></div>
  </div>
  <div class="taball">
    <div class="tabtext">企业所在地</div>
    <div class="sansan"></div>
  </div>
</div>
  <div class="listbox">
   <div class="cylmlist" v-for="(item,index) in AllArr" :key="index">
    <div class="cylmimg">
      <router-link :to="'/Branddetail?corp_id='+item.corp_id">
      <img class="c1img" :src="item.brand_logo" alt="" />
      </router-link>
    </div>
    <div class="cylmcon">
      <div class="cymltit">{{item.brand_name}}</div>
      <div class="cymlsite">{{item.company.company_name}}</div>
      <div class="cymlqt">预算投资：<div class="qtnum"> {{item.money_min}}万-{{item.money_max}}万</div></div>
      <div class="jmbut" @click.stop="showtc(item)">我要加盟</div>
    </div>
  </div>
  </div>
    <!-- 品牌弹窗 -->
    <div class="tcbox" v-if="showpptc">
      <img class="h22img" src="../assets/imgs/h22.png" alt="">
      <div class="tccon">
        <div class="tcxinxin">
          <img class="tcimg" :src="pptccon.corp_avatar" alt="">
          <div>
            <div class="tctit">{{pptccon.brand_name}}</div>
            <div class="touzi">基本投资: <div class="tcnum">{{pptccon.money_min}}万-{{pptccon.money_max}}万</div> </div>
            <div class="touzi">全国门店: <div class="tcnum">{{pptccon.corp_store_num}}家</div> </div>
           
          </div>
        </div>
        <div class="inpbox">
            <input  class="inp" type="text" placeholder="请输入您的姓名">
            <input  class="inp" type="text" placeholder="请输入手机号码">
        </div>
        <div class="tcbut" @click="yctc">提交加盟咨询</div>
        <div class="tctext">留下您的联系方式，我们马上为您服务</div>
      </div>
      <img class="h23img" @click="yctc" src="../assets/imgs/h23.png" alt="">
    </div>
    <div class="black" v-if="showpptc"></div>
</template>
<script>
export default {
  data() {
    return {
      AllArr:[],
            showpptc:false,//品牌弹窗
      pptccon:[],//品牌弹窗内容
    };
  },

  created() {
    this.getAllArr()
  },
  methods: {
    getAllArr() {
      let formData = new FormData()
      formData.append("category", '');
      formData.append("money_min", '');
      formData.append("money_max", '');
      formData.append("province", '');
      formData.append("city", '');
      this.$http({
        method: "post",
        url: "/v1/app-join/get-join-list",

        data:formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.AllArr=res.data.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
        //显示品牌弹窗
    showtc(item){
      console.log(item)
      this.pptccon=item
      this.showpptc=true
    },
    yctc(){
      this.showpptc=false

    }

  },

};
</script>
<style scoped>
.cylmlist {
  width: 6.9rem;
  margin: 0 auto;
  display: flex;
  padding: 0.3rem 0;
  border-bottom: 0.01rem solid #e8eaf3;
}
.cylmimg {
  width: 3.1rem;
  height: 2.3rem;
  /* border: 0.01rem solid #e8eaf3; */
  margin-right: 0.3rem;
}
.cymltit {
  font-size: 0.31rem;
  margin-top: 0.1rem;
  font-weight: bold;
}
.cymlsite {
  font-size: 00.22rem;
  color: #5d5f6a;
  margin-top: 0.1rem;
          width: 3.6rem;
        margin-top: 0.1rem;
        font-weight: bold;
        overflow: hidden;  
	      white-space: nowrap;
	      text-overflow: ellipsis;
}
.cymlqt {
  font-size: 0.24rem;
  color: #03050f;
  /* line-height: 0.24rem; */
  /* margin-top: 0.4rem; */
  display: flex;
  margin-top: 0.15rem;

}
.c1img {
  width: 100%;
  height: 100%;
}
.jmbut {
  width: 1.8rem;
  height: 0.56rem;
  background: #fecb32;
  border-radius: 0.1rem;
  text-align: center;
  line-height: 0.56rem;
  font-size: 00.26rem;
  margin-top: 0.2rem;
}
.qtnum{
    font-size: 00.3rem;
    color: #EE1818;
    font-weight: bold;
    margin-top: -0.03rem;
}
.listbox{
  margin-top: 1.6rem;
}
.navbox{
  width: 100%;
  height: 1rem;
  background: #fecb32;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 1.1rem;
  font-weight: bold;
  font-size: 0.3rem;
  justify-content: center;

}
.fenleibox{
  width: 100%;
  height: 00.6rem;
  position: fixed;
  top: 1rem;
  left: 0;
  display: flex;
  justify-content: center;
  font-size: 0.;
  background-color: rgb(241, 241, 241);

}
.taball{
  display: flex;
  width: 2.4rem;
  /* background-color: #c8cff5; */
  justify-content: center;
}
.tabtext{
  font-size: 0.23rem;
  color: #5d5f6a;
  line-height: 0.6rem;

}
.sansan{
  width: 0;
  height: 0;
  border: 0.08rem solid transparent;
  border-top: 0.08rem solid #5d5f6a;
  margin-top: 0.24rem;
  margin-left: 0.1rem;
}
.tcbox{
  width: 6.90rem;
  /* height: 8.4rem; */
  /* border: 0.01rem solid red; */
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 11;
  text-align: center;

}
.black{
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.h22img{
    width: 6.90rem;
  height: 8.4rem;

}
.tccon{
  width: 6.50rem;
height: 5.47rem;
background: #FFFFFF;
border-radius: 0.1rem;
position: absolute;
top: 2.76rem;
left: 0.2rem;
padding: 0.2rem;
box-sizing: border-box;
}
.h23img{
  width: 00.72rem;
  height: 0.72rem;

}
.tcimg{
  width: 2.78rem;
height: 2.02rem;
background: #B5001F;
margin-right: 0.3rem;
}
.tcxinxin{
  display: flex;
}
.tctit{
  font-size: 0.3rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: 0.1rem;
}
.touzi{
  font-size: 0.2rem;
  color: #03050F;
  display: flex;
}
.tcnum{
  
font-weight: bold;
color: #EE1818;
margin-bottom: 0.15rem;
margin-left: 0.1rem;
}
.inpbox{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.inp{
  width: 2.8rem;
  height: 0.86rem;
  background: rgba(127,127,127,0);
  border: 0.01rem solid #8C8F9F;
  border-radius: 0.1rem;
  padding-left: 0.12rem;
}
.tcbut{
  width: 6.10rem;
height: 0.81rem;
background: #FECB32;
border-radius: 0.1rem;

font-size: 0.3rem;
font-weight: bold;
color: #03050F;
line-height: 0.24rem;
text-align: center;
line-height: 0.81rem;
margin-top: 0.2rem;
}
.tctext{
  
font-size: 0.24rem;

color: #8C8F9F;
margin-top: 0.3rem;

}
</style>