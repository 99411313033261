<template>
<!-- <router-view v-wechat-title="Title"/> -->
<!-- <router-view v-wechat-title="HHH" /> -->
<div class="navbox">家居卖场</div>
    <div class="allbox">
       <div v-for="(item,index) in jiajulist" :key="index" >
         <router-link :to="'/Storedetail?id='+item.market_id">
         <div class="cylmlist">
        <div class="cylmimg">
            <!-- <img class="c1img" :src="item.market_avatar" alt=""> -->
           <img class="c1img" v-lazy="item.market_avatar" :alt="require('../assets/imgs/zw.png')">

        </div>
        <div class="cylmcon">
            <div class="cymltit">{{item.market_name}}</div>
            <div class="cymlsite">{{item.marketJoinin.company_address+item.marketJoinin.company_address_detail}}</div>
            <div class="cymljuli">6.6公里</div>
            <div class="cymlqt">电话：<div class="telnum">{{item.marketJoinin.company_phone}}</div></div>
        </div>
        </div>
        </router-link>
    </div>
    </div>

</template>
<script>
export default {
  data() {
    return {
      jiajulist:[],
    };
  },

  created() {
    document.title = '家居卖场'
    this.getAllArr()
  },
  methods: {
    getAllArr() {
      let formData = new FormData()
      formData.append("lng", '1');
      formData.append("lat", '1');
      formData.append("province", '天津');
      formData.append("page", '1');
      formData.append("pageSize", '100');
      this.$http({
        method: "post",
        url: "/v1/app-market/get-market-list",
        data:formData,
      params: {
          lng:'1',//	是	float	经度
          lat	:'1',//是	float	维度
          province:'天津',//	否	string	省份
          page:'1',//	否	int	页码 默认1
          pageSize:'10',//	否	int	数量默认10  建材城定位: 定位：天津市當前经度：当前维度：
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          // "Access-Control-Allow-Origin": "*",
      
        },
      })
        .then((res) => {
          console.log(res.data);
          this.jiajulist=res.data.data
        
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

};
</script>
<style scoped>
    .cylmlist{
        width: 6.9rem;
        margin: 0 auto;
        display: flex;
        padding: 0.3rem 0;
        border-bottom: 0.01rem solid #E8EAF3;
    }
    .cylmimg{
        width: 2.9rem;
        height: 1.8rem;
        border: 0.01rem solid #E8EAF3;
        margin-right: 0.3rem;
    }
    .cymltit{
        width: 3.5rem;
        font-size: 0.31rem;
        margin-top: 0.1rem;
        font-weight: bold;
        overflow: hidden;  
	      white-space: nowrap;
	      text-overflow: ellipsis;
    }
    .cymlsite{
        font-size: 00.22rem;
        /* color: #5D5F6A; */
        margin-top: 0.05rem;
        width: 3.21rem;
        height: 0.7rem;
        /* margin-top: -0.2rem; */
        font-family: PingFang SC;
        font-weight: 500;
       	overflow:hidden;
        text-overflow:ellipsis; 
        white-space: normal;  
        display:-webkit-box;  
        -webkit-box-orient:vertical; 
        -webkit-line-clamp:2;
    }
    .cymlqt{
       font-size: 0.24rem;
       color: #03050F;
       /* line-height: 0.24rem; */
       margin-top: 0.15rem;
       display: flex;

    }
    .c1img{
        width: 100%;
        height: 100%;
    }
    .telnum{
        color: red;
    }
    .cymljuli{
        color: #5D5F6A;
        font-size: 0.1rem;
        margin-top: 0.02rem;

    }
    .navbox{
  width: 100%;
  height: 1rem;
  background: #fecb32;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 1.1rem;
  font-weight: bold;
  font-size: 0.3rem;
  justify-content: center;

}
.allbox{
  margin-top: 1rem;
}
</style>