<template>

<meta name="format-detection" content="telephone=yes" />

<div class="page">
    <div>
    <img class="heaimg" v-lazy="marketdetail.corp_pic" :alt="require('../assets/imgs/zw.png')">

    <div class="headbox">
        <div class="headtit">{{marketdetail.corp_name}}</div>
        <div class="hsjbox">
        <div class="headtime"> 
          <img class="ttimg" src="../assets/imgs/weizhi.png" alt=""> <div>展位号：{{marketdetail.corp_local}}</div>
          </div>

            <div class="dhbox">
                <div class="dhbox1" @click="callphone">
                  <img class="dhimg" src="../assets/imgs/dh.png" alt="">
                  <div class="headjuli dianhua">电话</div>  
                </div>
            </div>
        </div>

    </div>

    </div>
   <div class="tittle">商家介绍</div>
    <div class="jiben" v-if="corp_content!==''">
   
      <div>
        <div class="xinxibox"  v-html="corp_content"></div>
        <!-- <div class="zhanshou" @click="zhanshouu">{{zhanshou}}</div> -->
      </div>
    </div>
  

      <div class="tittle">商家图集</div>

    <div class="shopbox">
        <div class="shopli" v-for="(item,index) in expoMcorpPic" :key="index">
           <!-- <router-link :to="'/Sintroduce?id='+item.mcorp_id"> -->
           <img class="shopimg" v-lazy="item.expo_path_pic" :alt="require('../assets/imgs/zw.png')">
            <!-- <div class="shoptit" :style="{'background': (item.bc_id!==null?'#FECB32':'')}">{{item.corp_name}}</div>    -->
            <!-- <img class="s11img" src="../assets/imgs/s11.png" alt="" v-if="item.bc_id!==null"> -->
          <!-- </router-link> -->
        </div>
    </div>


    <div class="dibox">
<div >

  </div>
  <div v-if="marketdetail.bc_id!=='0'" >    
     <wx-open-launch-weapp  v-if="marketdetail.bc_id!==null" id="launch-btn" username="gh_019f12d74e33" :path="'pages/index/index?share_id='+share_id" @launch="handleLaunchFn" @error="handleErrorFn">
      <div v-is="'script'" type="text/wxtag-template">
        <div class="btn" style="font-size:24px;color:white;width:6.9rem;visibility: hidden;height:1rem;">打开小程序</div>
      </div>
    </wx-open-launch-weapp>
    <div class="dibutton" @click.stop="showtccc(item)" v-if="marketdetail.bc_id!==null">立即进入展商微站</div> 
    </div>
  <div class="dibutton1" @click.stop="showtccc(item)" v-if="marketdetail.bc_id==null||marketdetail.bc_id=='0'">
         <wx-open-launch-weapp  v-if="marketdetail.bc_id!==null" id="launch-btn" username="gh_019f12d74e33" :path="'pages/index/index'" @launch="handleLaunchFn" @error="handleErrorFn">
      <div v-is="'script'" type="text/wxtag-template">
        <div class="btn" style="font-size:24px;color:white;width:6.9rem;visibility: hidden;height:1rem;">打开小程序</div>>
      </div>
    </wx-open-launch-weapp>
    该展商暂未开通线上微站  立即联系开通
    </div>
  
</div>
    <div :style="{'height':'2rem'}"></div>
</div>

</template>
<script>
import axios from 'axios'
// import wx from 'weixin-js-sdk';

export default {
  data() {
    return {
      id:'',
      marketdetail:[],
      content:'',
      mclist:[],
      market:[],
      market_mark:'',
      marketindex:'0',
      mc_corplist:[],
      url: "https://djdshop.vjiashuzi.com",
      marketMactivity:[],
      marketJoinin:{},
      market_select:'',
      market_good:'',
      m_tags:[],
      showpptc:false,//品牌弹窗
      messagename:'',
      messagephone:'',
      zhanshou:'展开↓',
      zhanshou1:'展开↓',
      zhanshou2:'展开↓',
      xinxibox:'',
      hei:'1rem',
      hei2:'1rem',
      hei2:'1rem',
      getLongitude:'',
      getLatitude:'',
      lng:"117.20809275",
      lat:'39.0911026',
      scrollTop: '',  /*保存当前滚动条*/
      h5Map:'',
      expo_introduce:'',
      expo_range:'',
      expoArea:'',
      expo_area_id:'',
      expoCorp:[],
      corp_content:'',
      expoMcorpPic:[],
      share_id:'',
      type:'',
      showtcijd:false,
      doc_content:''


    };
  },

  created() {
    let ua = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
    
    this.type = "ios";
    
    } else if (/android/.test(ua)) {
    
    this.type = "android";
    
    }
    
    if(isWeixinBrowser()){
    
    this.show_wx = true;
    
    }
    
    function isWeixinBrowser() {
    
    return (/micromessenger/.test(ua)) ? true : false;
    
    }
    // console.log("ididididid",this.$route.query.id)
    this.id=this.$route.query.id
    console.log(this.id)
  },
  mounted() {
    this.getAllArr();
    this.Config();
    // this.getLongitudeLatitude();
  },
  methods: {
    // 关于ijd
    guanyu(){
      this.showtcijd=true
    },
    guanyu1(){
      this.showtcijd=false
    },
        //分享id
    Shareid(){
      var timestamp = Date.parse(new Date());
    timestamp = timestamp / 1000;
    var n = timestamp * 1000; //获取当前时间
    var date = new Date(n);
    var Y = date.getFullYear(); //年`
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1); //月
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); //日
    var h = date.getHours(); //时
    var m = date.getMinutes(); //分
    var s = date.getSeconds(); //秒
    var i = Math.floor(Math.random() * 999999+100000);
    // console.log(Y+M+D+h+m+s+i)
    var share_id = 'f'+Y + M + D + h + m + s + i

    this.share_id=share_id
    console.log(share_id)
    console.log(this.marketdetail.bc_id)
     let formData = new FormData()
      formData.append("share_id", share_id);
      formData.append("bc_id", this.marketdetail.bc_id);//bc_id: this.data.message.id,
      this.$http({
        method: "post",
        url: "/api/wx/set-share-id",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);

        })
        .catch((err) => {
          console.log(err);
        });
    },
    Config(){
      // alert('get_url:'+window.location.href);
      
     let formData = new FormData()
      formData.append("url",window.location.href);
      axios({
         method: "post",
         url: "https://ajdh5.vjiashuzi.com/GetWxConfig.php",
        data: formData,
         
       }).then(
         response => {
           console.log("返回信息", response.data);
          //  var obj = JSON.parse(response.data);
          //  console.log(obj)
          //wx.config({
             this.$wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
            appId: 'wxfbd6f16c8b572b3d', // 必填，公众号的唯一标识
            timestamp:response.data.timestamp , // 必填，生成签名的时间戳
            nonceStr:response.data.noncestr, // 必填，生成签名的随机串
            signature:response.data.signature,// 必填，签名
            jsApiList: ['openLocation','chooseImage', 'previewImage'], // 必填，需要使用的 JS 接口列表
            openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
           })
          //  alert('ddd');

         },
         (error) => {
           ElMessage.error("注册请求失败");
          
         }
       );
    },


    handleScroll(){
        //或者使用document.querySelector('.class或者#id').scrollTop
        this.scroll = document.documentElement.scrollTop||document.body.scrollTop
        console.log(this.scroll)
    },
   getLongitudeLatitude() {
    // let diff=1000;//这里是要滚动到的位置高度
    //   var container = this.$refs.box
      // this.$refs.box.offsetTop = 0;
    document.documentElement.scrollTop = 100000
    // console.log( document.documentElement.scrollTop)
  
      var _this = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          //locationSuccess 获取成功的话
          function (position) {
            _this.getLongitude = position.coords.longitude;
            _this.getLatitude = position.coords.latitude;
            // alert(_this.getLongitude,)
            // alert( _this.getLatitude)
            this.lng=_this.getLongitude
            this.lat=_this.getLatitude
            this.getAllArr()

          },
          //locationError  获取失败的话
          function (error) {
            var errorType = [
              "您拒绝共享位置信息",
              "获取不到位置信息",
              "获取位置信息超时",
            ];
            console.log(errorType[error.code - 1]);
          }
        );
      }
    },

    getAllArr(){
      let formData = new FormData()
      formData.append("corp_id", this.id);

      this.$http({
        method: "post",
        url: "/v1/app-expo/get-corp-detail",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.marketdetail=res.data.data
          this.corp_content=res.data.data.new_content
          this.expoMcorpPic=res.data.data.expoMcorpPic
          document.title = this.marketdetail.corp_name

          this.Shareid();


        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    getijdjs(){
      let formData = new FormData()
      // formData.append("corp_id", this.id);

      this.$http({
        method: "post",
        url: "v1/system/about-us",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.doc_content=res.data.data.doc_content

        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    getCustomerMarket() {
      console.log(this.messagephone)
      console.log(this.messagename)
      let formData = new FormData()
      formData.append("market_id", this.id);
      formData.append("phone", this.messagephone);
      formData.append("name",this.messagename);
      this.$http({
        method: "post",
        url: "/v1/app-join/save-customer-market",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data)
        if (res.data.error==0) {
           this.showpptc=false
        alert(res.data.data);
        this.messagename=''
        this.messagephone=''

        }else{
        alert(res.data.error_msg);

        }

     

        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    zhanshouu(){
      if(this.zhanshou=='展开↓'){
        console.log('222')
        this.zhanshou='收起↑'
        this.hei='100%'
      }else{
        this.zhanshou='展开↓',
        this.hei='1rem'

      }
      
  
    },
    zhanshouu1(){
      if(this.zhanshou1=='展开↓'){
        console.log('222')
        this.zhanshou1='收起↑'
        this.hei1='100%'
      }else{
        this.zhanshou1='展开↓',
        this.hei1='1rem'

      }
      
  
    },
    zhanshouu2(){
      if(this.zhanshou2=='展开↓'){
        console.log('222')
        this.zhanshou2='收起↑'
        this.hei2='100%'
      }else{
        this.zhanshou2='展开↓',
        this.hei2='1rem'
      }
    },
    showtc(index,item){
      this.marketindex=index
      this.expo_area_id=item.area_id,
      console.log(item)
      this.getAllArr()

    },
        //显示品牌弹窗
    //显示品牌弹窗
    showtccc(item){
      this.showpptc=true
    },
    yctc(){
      this.showpptc=false

    },
    // 调用拨号功能2
    callphone () {
     window.location.href = 'tel://' + this.marketdetail.corp_phone
    },
    download(){
    window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.lpy.djd';
    // if(this.type == 'android') {
    //   console.log('android')
    // window.location.href = 'https://admin.vjiashuzi.com/app/ajd/ajd.apk';
    // } else {
    //   console.log('ios')
    // window.location.href = 'https://apps.apple.com/cn/app/%E7%88%B1%E5%AE%B6%E5%B8%9D/id1638856394?mt=8';
    
    // }
    }
  },
  
  

};
</script>
<style scoped>
.doc_content{
  font-size: 0.24rem;
  padding: 0 0.2rem;
}
.ijdimg{
  width: 4rem;
  height: 1rem;
  margin-left: 0.2rem;

}
.ijdbut{
  font-size: 0.26rem;
  background-color: #FECB32;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  padding: 0 0.4rem;
  font-weight: bold;
  border-radius: 0.5rem;
  margin-top: 0.18rem;
  margin-left: 0.1rem;
}
.xiazaiapp{
  width: 100%;
  height: 1rem;
  background-color: #ffffff;
  display: flex;
}
.qingq{
  width: 6.8rem;
  margin: 0 auto;
  font-size: 0.3rem;
  background-color: #FECB32;
  line-height: 0.75rem;
  text-align: center;
  font-weight: bold;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}
.page{
    background-color:#F7F8FB;
    /* height: 100vh; */
}
.heaimg{
    width: 100%;
    position: relative; 
    top: 0;
    left: 0;
    height: 4.7rem;
    /* background-color: aqua; */
    /* margin-bottom: 2.2rem; */
}
.headbox{
    /* width: 6.9rem; */
    /* height: 3.1rem; */

    background-color: rgb(255, 255, 255);
    /* border: 0.01rem solid rgb(44, 42, 42); */
    border-radius: 0.1rem;
    box-sizing: border-box;
    padding:  0.35rem;
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
}
.headtit{
  font-size: 0.3rem;
  font-weight: bold;
  display: flex;
  margin-bottom: 0.35rem;
}
.headtime{
  font-size: 0.24rem;
  display: flex;
  margin-top: 0.2rem;

}
.headside{
  width: 4.3rem;
  font-size: 0.2rem;
  font-weight: bold;
  display: flex;
  overflow: hidden; 
   text-overflow: ellipsis; 
  -webkit-box-orient:vertical;
  display: -webkit-box; 
    -webkit-line-clamp:2;
  /* background: #fff; */


}
.headjuli{
  font-size: 0.2rem;
  color: #8C8F9F;
  margin-top: 0.2rem;
}
.ttimg{
    width: 0.25rem;
    height: 0.25rem;
    margin-top: 0.02rem;
    margin-right: 0.1rem;
    margin-bottom: 0.2rem;
}
.ttimg1{
    width: 0.25rem;
    height: 0.25rem;
    margin-top: 0.05rem;
    margin-right: 0.1rem;
    /* margin-bottom: 0.2rem; */
}
.hsjbox{
    display: flex;
    justify-content: space-between;
}
.dhbox{
    border-left: 0.01rem solid #E8EAF3;
    padding: 0 0.31rem;
    height: 0.7rem;
}
.dhimg{
    width: 0.23rem;
    height: 0.23rem;
    margin-left: 0.1rem;
}
.dhbox1{
    margin-top: -0.75rem;
}
.tittle{
    border-left: 0.3rem solid #FECB32;
    font-size: 0.35rem;
    font-weight: bold;
    padding-left: 0.2rem;
    box-sizing: border-box;
    height: 0.45rem;
    margin: 0rem 0; 
    margin-bottom: 0.1rem;


}
.xinxibox{
  /* height: 1rem; */
  overflow: hidden;
    /* width: 6.9rem; */
    padding: 0.3rem;
    /* border-radius: 0.2rem; */
    background-color: #fff;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 0.28rem;
    margin-bottom: 0.2rem;
    padding-top: 0;
}
.shopbox{
    width: 6.9rem;
    /* padding:  0.15rem; */
    border-radius: 0.2rem;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 0.28rem;
    display: flex;
    flex-wrap: wrap;
}
.shopli{
  width: 2rem;
  /* height: 2.5rem; */
  border-radius: 0.1rem;
  margin: 0.15rem;
    /* background-color: #fff; */
    overflow: hidden;
    position: relative;

}
.shopimg{
  width: 100%;
  height: 2rem;
  border-radius: 0.1rem;

  /* background-color: #FECB32; */
}
.shoptit{
  text-align: center;
  font-size: 0.24rem;
  font-weight: bold;
  line-height: 0.54rem;
  /* background-color: #adaca8; */
  width: 2rem;
  white-space: nowrap; 
text-overflow: ellipsis; 
overflow: hidden; 


}
.classbox{
  width: 6rem;
  /* height: 1rem; */
  /* background: rgb(83, 76, 76); */
  overflow-x: scroll;
  white-space:nowrap;
  /* display: flex; */
  /* padding-top: -1rem; */
  margin-top: -0.35rem;


}
.classitem{
  /* width: 0.6rem; */
  height: 0.7rem;
  background: #fff;
  font-size: 0.24rem;
  display: inline-block;
  margin: 0.05rem;
  white-space:nowrap;
  padding:  0.05rem 0.2rem;
  text-align: center;
}
.clbox{
  display: flex;
  justify-content: space-between;
  padding: 0 0.3rem;
  /* margin-top: 0.2rem; */
}

.gengduo{
    margin-top: 0.07rem;
    line-height: 0.7rem;
    /* color: #000; */
    
}
.color{
  background: #FECB32;
}
.jiben{
  width: 100%;
  background-color: #fff;
  padding: 0.3rem 0;
  margin: 0.3rem 0;
}
.jibentext{
  display: flex;
  padding: 0 0.3rem;
  margin: 0.15rem 0;

}
.jibentit{
  width: 1.2rem;
 font-size: 00.24rem;
 margin-right: 0.2rem;
color: rgb(97, 96, 96);
}
.jibencon{
 font-size: 00.24rem;
 display: flex;


}
.connn{
  margin-right: 0.15rem;
}
.red{
  color: red;
}
.hdox{
  width: 6.9rem;
  margin: 0 auto;
  /* border: 0.01rem solid #d7d7d8; */
  margin-top: 0.2rem;
  
}
.hdoxx{
  width: 6.9rem;
  /* height: 4rem; */
  /* background: #FECB32; */
  margin: 0 auto;
  border: 0.01rem solid #d7d7d8;
  margin-top: 0.2rem;
  display: flex;
  overflow-x: scroll;
  white-space:nowrap;

}
.hdoxxs{

  margin: 0 auto;
  /* border: 0.01rem solid #d7d7d8; */
  /* margin-top: 0.2rem; */
  display: flex;
  /* overflow-x: scroll;
  white-space:nowrap; */

}
.hdimg{
  width: 100%;
  height: 3.5rem;
}
.hdtit{
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.5rem;
  margin-left: 0.2rem;
  margin-bottom: 0.15rem;
}
.dibox{
  width: 100%;
  background: #ffffff;
  /* padding: 0.2rem; */
  position: fixed;
  bottom: 0;
  left: 0;
  
}
.dibutton{
  width: 6.8rem;
  background-color: #FECB32;
  /* color: #fff; */
  line-height: 0.7rem;
  font-size: 0.3rem;
  text-align: center;
  margin: 0.2rem auto;
  font-weight: bold;
  border-radius: 0.1rem;

}
.tcbox{
  width: 6.90rem;
  /* height: 8.4rem; */
  /* border: 0.01rem solid red; */
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 11;
  text-align: center;

}
.black{
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.h22img{
    width: 6.90rem;
  height: 8.4rem;

}
.tccon{
  width: 6.50rem;
height: 5.47rem;
background: #FFFFFF;
border-radius: 0.1rem;
position: absolute;
top: 2.76rem;
left: 0.2rem;
padding: 0.2rem;
box-sizing: border-box;
}
.h23img{
  width: 00.72rem;
  height: 0.72rem;

}
.tcimg{
  width: 2.78rem;
height: 2.02rem;
background: #B5001F;
margin-right: 0.3rem;
}
.tcxinxin{
  display: flex;
}
.tctit{
  width: 3rem;

  font-size: 0.3rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: 0.1rem;
  	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;

}
.touzi1{
  font-size: 0.3rem;
  color: #03050F;
  display: flex;
  font-weight: bold;
    	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
}
.touzi2{
  width: 3rem;
  font-size: 0.28rem;
  color: #03050F;
  font-weight: bold;
    	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
  margin-bottom: 0.3rem;
}
.touzi3{
  font-size: 0.2rem;
  color: #8d8d8d;
  display: flex;
  /* font-weight: bold; */
}
.tcnum{
  
font-weight: bold;
color: #EE1818;
margin-bottom: 0.15rem;
margin-left: 0.1rem;
}
.inpbox{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.inp{
  width: 2.8rem;
  height: 0.86rem;
  background: rgba(127,127,127,0);
  border: 0.01rem solid #8C8F9F;
  border-radius: 0.1rem;
  padding-left: 0.12rem;
}
.tcbut{
  width: 6.10rem;
height: 0.81rem;
background: #FECB32;
border-radius: 0.1rem;

font-size: 0.3rem;
font-weight: bold;
color: #03050F;
line-height: 0.24rem;
text-align: center;
line-height: 0.81rem;
margin-top: 0.2rem;
}
.tctext{
  
font-size: 0.24rem;

color: #8C8F9F;
margin-top: 0.3rem;

}
.s11img{
  position: absolute;
  top: 0;
  right: 0;
  width: 0.42rem;
  height: 0.37rem;

}
.zhanshou{
  text-align: center;
  font-size: 0.3rem;
  color: #FECB32;
}
.conbox{
    width: 100%;
    border-top: 0.01rem solid #d9d9db;
    display: flex;
    justify-content: space-between;
    /* margin-top: 0.34rem; */
    background-color: #fff;
}
.context{
    font-size: 0.2rem;
    display: flex;
    line-height: 0.8rem;
    padding: 0 0.5rem;
}
.xian{
    height: 0.2rem;
    width: 0.01rem;
    background: #c7c7ca;
    margin-top: 0.28rem;
}
.dizhibox{
  background: #fff;
  display: fixed;
  position: relative;
  margin-top: 0.2rem;
  padding: 0.3rem;
  box-sizing: border-box;
}
.daohang{
  position: absolute;
  font-size: 0.26rem;
  top: 0.5rem;
  right: 0.3rem;
  width: 1.5rem;
  text-align: center;
  background: #141414;
  color: #fff;
  line-height: 0.57rem;
  border-radius: 0.35rem;
  
}
.fangdaimg{
  width: 100%;
  height: 4.5rem;
  margin-top: 0.3rem;
}
.dianhua{
  margin-top: -0.1rem;
}
.dibox{
  width: 100%;
  background: #ffffff;
  /* padding: 0.2rem; */
  position: fixed;
  bottom: 0;
  left: 0;
  
}
.dibutton{
  width: 6.8rem;
  background-color: #FECB32;
  /* color: #fff; */
  line-height: 0.7rem;
  font-size: 0.3rem;
  text-align: center;
  margin: 0.2rem auto;
  font-weight: bold;
  border-radius: 0.1rem;
}
.dibutton1{
  width: 6.8rem;
  background-color: #b3b3b3;
  /* color: #fff; */
  line-height: 0.7rem;
  font-size: 0.3rem;
  text-align: center;
  margin: 0.2rem auto;
  font-weight: bold;
  border-radius: 0.1rem;
}
#launch-btn{
    width: 7rem;
  height: 1rem;
  background: #B5001F;
  position: fixed;
  bottom: 0.5rem;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
}
.tanchuang{
  width: 6.5rem;
  height: 8.5rem;
  background-color: #fff;
  border-radius: 0.2rem;
  border: 0.1rem solid #FECB32;
  position: fixed;
  top: 40%;
  left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;

}
.black{
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5rem;
  position: fixed;
  top: 0;
  left: 0;
  }
  .h23img{
  width: 1rem;
  height: 1rem;
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%,-50%);
    z-index: 100;

  }

</style>