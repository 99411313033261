<template>
    <div>
      <div class="heanav">
        <div class="navtab1">总访问量：<div class="navnum">25</div> 人 | 今日访问量：<div class="navnum">20</div> 人</div>
        <div class="navtab2">
          <img class="navimg" src="../assets/imgs/h.png" alt="">
          <img class="navimg" src="../assets/imgs/h.png" alt="">
          <img class="navimg" src="../assets/imgs/h.png" alt="">
        </div>
      </div>
      <div class="radarlist">
        <div class="listtab">
          <img class="listtabimg" src="../assets/imgs/h.png" alt="">
          <div class="listtabcon">
            <div class="listname">张明宇 </div>
            <div class="listtime">昨天12：55</div>
          </div>
        </div>
        <div class="listtext">查看你的名片第45次，建议重点关注持续跟进</div>
        <div class="listdibu">
          <div></div>
          <div>查看更多</div>
        </div>
      </div>
      <div class="radarlist">
        <div class="listtab">
          <img class="listtabimg" src="../assets/imgs/h.png" alt="">
          <div class="listtabcon">
            <div class="listname">张明宇 </div>
            <div class="listtime">昨天12：55</div>
          </div>
        </div>
        <div class="listtext">查看你的名片第45次，建议重点关注持续跟进</div>
        <div class="listdibu">
          <div></div>
          <div>查看更多</div>
        </div>
      </div>
      <div class="radarlist">
        <div class="listtab">
          <img class="listtabimg" src="../assets/imgs/h.png" alt="">
          <div class="listtabcon">
            <div class="listname">张明宇 </div>
            <div class="listtime">昨天12：55</div>
          </div>
        </div>
        <div class="listtext">查看你的名片第45次，建议重点关注持续跟进</div>
        <div class="listdibu">
          <div></div>
          <div>查看更多</div>
        </div>
      </div>
      <div class="radarlist">
        <div class="listtab">
          <img class="listtabimg" src="../assets/imgs/h.png" alt="">
          <div class="listtabcon">
            <div class="listname">张明宇 </div>
            <div class="listtime">昨天12：55</div>
          </div>
        </div>
        <div class="listtext">查看你的名片第45次，建议重点关注持续跟进</div>
        <div class="listdibu">
          <div></div>
          <div>查看更多</div>
        </div>
      </div>
    <!-- 底部导航 -->
    <div class="tabBar">
      <div class="tab">
        <router-link to="/">
          <img class="t1img" src="../assets/imgs/t11.png" alt="">
          <div class="tabtext">首页</div>
        </router-link>
      </div>
      <div class="tab">
        <router-link to="/workbench">
          <img class="t1img" src="../assets/imgs/t3.png" alt="">
          <div class="tabtext">工作台</div>
       </router-link>
      </div>
      <div class="tab">
        <router-link to="/radar">
          <img class="t1img" src="../assets/imgs/t22.png" alt="">
          <div class="tabtext">雷达</div>
        </router-link>
      </div>
      <div class="tab">
        <router-link to="/mine">
          <img class="t1img" src="../assets/imgs/t4.png" alt="">
          <div class="tabtext">我的</div>
        </router-link>
      </div>

    </div>     

    </div>
</template>
<style scoped>
.tabBar{
  width: 100%;
  height: 1rem;
  background: #FFFFFF;
  /* box-shadow: 0rem -0.01rem 0.1rem 0rem #EAEAEA; */
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.5rem;
}

.tab{
  text-align: center;
  /* background-color: #814220; */
  position: relative;
  width: 0.8rem;
}
.t1img{
  width: 0.38rem;
  height: 0.38rem;
  position: absolute;
  top: 0.15rem;
  left: 50%;
  transform: translate(-50%);
}
.tabtext{
  font-size: 0.18rem;
  margin-top: 0.6rem;

}
.heanav{
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding: 0 0.25rem;

}
.navtab1{
  display: flex;
  font-size: 0.3rem;
  line-height: 1rem;
}
.navtab2{
  display: flex;
  padding-top: 0.3rem;
}
.navimg{
  width: 0.42rem;
  height: 0.42rem;
  background-color: #FECB32;
  border-radius: 50%;
  margin-left: -0.1rem;
}
.navnum{
  color: #FECB32;
}
.radarlist{
  padding: 0.3rem;
  background-color: #FFFFFF;
  margin-top: 0.2rem;
}
.listtab{
  display: flex;
}
.listtabimg{
  width: 0.73rem;
  height: 0.73rem;
  border-radius: 50%;
  background-color: #FECB32;
  margin-right: 0.2rem;
}
.listname{
  font-size: 0.3rem;
  font-weight: bold;
}
.listtime{
  font-size: 0.2rem;
}
.listtext{
  font-size: 0.28rem;
  margin-top: 0.3rem;
  border-bottom: 0.01rem solid rgb(236, 236, 236);
  padding-bottom: 0.2rem;
}
.listdibu{
  display: flex;
  justify-content: space-between;
  font-size: 0.28rem;
  margin-top: 0.3rem;
  color: #FECB32;
}
</style>