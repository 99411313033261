import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import VuewechatTitle from 'vue-wechat-title'
import VueLazyload from 'vue-lazyload'
import Weixinjssdk from 'weixin-js-sdk'
axios.defaults.baseURL = "https://djdwap.vjiashuzi.com";//http://localhost:8080/api
import ElementPlus from 'element-plus';
// import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css'
const app = createApp(App);
app.config.globalProperties.$http = axios;
app.config.isCustomElement=tag=>tag.startWith('wx-open-launch-weapp')
app.config.globalProperties.$wx = wx;
app.use(Weixinjssdk);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VuewechatTitle);
app.use(VueLazyload, {
    loading: require('./assets/imgs/zw.png'),
    error: require('./assets/imgs/bg.png'),
    try: 3 // default 1p
  })

app.mount("#app");
  