<template>
<meta name="format-detection" content="telephone=yes" />

<div class="page">
    <div class="headimgbox">
    <!-- <img class="heaimg" :src="market.market_avatar" alt=""> -->
    <img class="heaimg" v-lazy="market.market_avatar" :alt="require('../assets/imgs/zw.png')">
    <!-- <div class="lookbox">11</div> -->

    <div class="headbox">
        <div class="headtit">{{market.market_name}}</div>
        <div class="headtime"> <img class="ttimg" src="../assets/imgs/time.png" alt="">{{market.market_run_time}}</div>
        <div class="hsjbox">
            <div>
              <router-link :to="'/Map?lng='+h5Map.lng+'&lat='+h5Map.lat">
             <div class="headside"><img class="ttimg1"  src="../assets/imgs/side.png" alt="">{{marketJoinin.company_address+marketJoinin.company_address_detail}} </div>
             <div class="headjuli">距您{{market.market_distance}}公里 </div>
             </router-link>
            </div>  
            <div class="dhbox">
                <div class="dhbox1" @click="callphone">
                  <img class="dhimg" src="../assets/imgs/dh.png" alt="">
                  <div class="headjuli">商家电话</div>  
                </div>
            </div>
        </div>
    </div>
    </div>
     <!-- <button @click="shearConfig">分享</button> -->
    <div class="jiben">
    <div class="tittle">基本信息</div>
      <div class="jibentext">
        <div class="jibentit">租金预算</div>
        <div class="jibencon">面议</div>
      </div>
      <div class="jibentext">
        <div class="jibentit">代理模式</div>
        <div class="jibencon"><div class="connn" v-for="(item,index) in m_tags" :key="index">{{item.proxy_name}}</div></div>
      </div>
      <div class="jibentext">
        <div class="jibentit">联系人</div>
        <div class="jibencon">{{marketJoinin.contacts_name}}</div>
        <div></div>
      </div>
      <div class="jibentext">
        <div class="jibentit">招商地区</div>
        <div class="jibencon">{{market.market_area}}</div>
      </div>
      <div class="jibentext">
        <div class="jibentit">招商电话</div>
        <div class="jibencon red" >{{marketJoinin.contacts_phone}}</div>
      </div>
    </div>
    <div class="jiben" v-if="marketMactivity.length!==0">
      <div class="tittle">活动信息</div>
           
      <div class="hdoxx">
        <div class="hdoxxs">
             <div class="hdox" v-for="(item,index) in marketMactivity" :key="index" :style="{'width': (marketMactivity.length!==1?'8rem':'6.9rem')}">  
        <router-link :to="'/Activity?id='+item.mactivity_id">
        <img class="hdimg" :src="item.mactivity_pic" :style="{'height': (marketMactivity.length!==1?'4rem':'3.5rem')}" alt="">
        <div class="hdtit">{{item.mactivity_title}}</div>
        </router-link>
      </div>
        </div>
     
      </div>

    </div>
    <div class="jiben" v-if="market_mark!==''">
      <div class="tittle">项目介绍</div>
      <div>
        <div :class="{ 'xinxibox':true}" :style="{'height': hei}" v-html="market_mark"></div>
        <div class="zhanshou" @click="zhanshouu">{{zhanshou}}</div>
      </div>
    </div>
    <div class="jiben" v-if="market_good!==''">
      <div class="tittle">招商支持</div>
      <div>
       <div class="xinxibox" :style="{'height': hei1}" v-html="market_good"></div>
       <div class="zhanshou" @click="zhanshouu1">{{zhanshou1}}</div>
      </div>


    </div>
    <div class="jiben" v-if="market_select!==''">
      <div class="tittle">招商流程</div>
      <div>
        <div class="xinxibox" :style="{'height': hei2}" v-html="market_select"></div>
        <div class="zhanshou" @click="zhanshouu2">{{zhanshou2}}</div>
      </div>


    </div>

    <div class="clbox">
      <div class="classbox">
        <div class="classitem " :style="{'background': (index==marketindex?'#FECB32':'')}"  @click="showtc(index)" v-for="(item,index) in mclist" :key="index" >{{item.market_class_name}} <div>{{item.mc_count}}</div> </div>
      </div>
      <div class="classitem gengduo">
      <router-link :to="'/Storejihe?id='+id">
       <div>更多</div>
      </router-link>
      </div>
    </div>
    <div class="shopbox">
        <div class="shopli" v-for="(item,index) in mc_corplist" :key="index">
           <router-link :to="'/Sintroduce?id='+item.mcorp_id" v-if="$route.meta.keepAlive">
           <img class="shopimg" v-lazy=" url+item.mcorp_pic" :alt="require('../assets/imgs/zw.png')">
            <div class="shoptit" :style="{'background': (item.bc_id!==null?'#FECB32':'')}">{{item.mcorp_name}}</div>   
            <img class="s11img" src="../assets/imgs/s11.png" alt="" v-if="item.bc_id!==null">
          </router-link>
        </div>
    </div>

<div :style="{'height':'2rem'}"></div>
<div class="dibox">
  <div class="dibutton" @click.stop="showtccc(item)">立即申请入驻</div>
</div>
    <!-- 品牌弹窗 -->
    <div class="tcbox" v-if="showpptc">
      <img class="h22img" src="../assets/imgs/dpbj.png" alt="">
      <div class="tccon">
        <div class="tcxinxin">
          <img class="tcimg" :src="market.market_avatar" alt="">
          <div>
            <div class="tctit">{{market.market_name}}</div>
            <!-- <div class="touzi1">天津市苏宁易购</div> -->
            <div class="touzi2">{{marketJoinin.company_address+marketJoinin.company_address_detail}} </div>
            <div class="touzi3">距那您4.5公里 </div>
          </div>
        </div>
        <div class="inpbox">
            <input  class="inp" type="text" placeholder="请输入您的姓名" v-model="messagename" >
            <input  class="inp" type="text" placeholder="请输入手机号码" v-model="messagephone" >
        </div>
        <div class="tcbut" @click="getCustomerMarket">立即申请入驻</div>
        <div class="tctext">留下您的联系方式，我们马上为您服务</div>
      </div>
      <img class="h23img" @click="yctc" src="../assets/imgs/h23.png" alt="">
    </div>
    <div class="black" v-if="showpptc"></div>
</div>
<div id="box" ref="box" class="aui-content"></div>

</template>
<script>
import axios from 'axios'
// import wx from 'weixin-js-sdk';

export default {
  data() {
    return {
      id:'',
      marketdetail:[],
      content:'',
      mclist:[],
      market:[],
      market_mark:'',
      marketindex:'0',
      mc_corplist:[],
      url: "https://djdshop.vjiashuzi.com",
      marketMactivity:[],
      marketJoinin:{},
      market_select:'',
      market_good:'',
      m_tags:[],
      showpptc:false,//品牌弹窗
      messagename:'',
      messagephone:'',
      zhanshou:'展开↓',
      zhanshou1:'展开↓',
      zhanshou2:'展开↓',
      xinxibox:'',
      hei:'1rem',
      hei2:'1rem',
      hei2:'1rem',
      getLongitude:'',
      getLatitude:'',
      lng:"117.20809275",
      lat:'39.0911026',
      scrollTop: '',  /*保存当前滚动条*/
      h5Map:'',
      reload:'',
      reloadaaa:'221'


    };
  },

  created() {
    // console.log("ididididid",this.$route.query.id)
    this.id=this.$route.query.id
    console.log(this.id)
   localStorage.setItem('userString', '1');

  },
  mounted() {
    this.getAllArr();
    this.getLongitudeLatitude();
    // this.shearConfig();
    // this.getCurrentPosition();
  //  window.addEventListener('scroll',this.handleScroll)
 
  },
  methods: {
    shearConfig(){
     let formData = new FormData()
      formData.append("url",window.location.href);
      axios({
         method: "post",
         url: "https://ajdh5.vjiashuzi.com/GetWxConfig.php",
        data: formData,
         
       }).then(
         response => {
          //  console.log("返回信息", response.data);
    
            this.$wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
            appId: 'wxfbd6f16c8b572b3d', // 必填，公众号的唯一标识
            timestamp:response.data.timestamp , // 必填，生成签名的时间戳
            nonceStr:response.data.noncestr, // 必填，生成签名的随机串
            signature:response.data.signature,// 必填，签名
            jsApiList: ['updateAppMessageShareData','updateTimelineShareData'], // 必填，需要使用的 JS 接口列表
           })
            this.$wx.checkJsApi({
              jsApiList: ['chooseImage', 'updateAppMessageShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: function(res) {
                  // 以键值对的形式返回，可用的api值true，不可用为false
                  // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                  console.log(res, 'checkJsApi')
              }
           })        
           this.$wx.ready(()=>{
            this.$wx.updateTimelineShareData({//配置去分享朋友圈的选项
               title :this.market.market_name,//分享标题
               link : window.location.href, //分享链接
               imgUrl : this.market.market_avatar, //分享图标
               success : function(res){//分享成功 
                 console.log("已分享朋友圈成功")
                 //如分享成功后想做一些其他的业务处理，可以在这里做
               },
               cancel:function(){
                 //that.alert="失败弹框话术等等(注意 这里拿不到this实例的)"
                 console.log("已分享失败")
               }
             })
           });
            this.$wx.updateAppMessageShareData({//配置去分享好友的选项
                title:this.market.market_name,  
                link: window.location.href,    		
                desc:this.marketJoinin.company_address+this.marketJoinin.company_address_detail,             
                imgUrl :this.market.market_avatar,                            
                success : function(res){
                  console.log("分享好友成功")
                },
                cancel:function(res){
                  console.log("分享失败") 
                }
            });
         },
         (error) => {
           ElMessage.error("注册请求失败");
          
         },
        //  console.log(this.marketJoinin.company_address+this.marketJoinin.company_address_detail)
       );


    },

    handleScroll(){
        //或者使用document.querySelector('.class或者#id').scrollTop
        // this.scroll = document.documentElement.scrollTop||document.body.scrollTop
        // console.log(this.scroll)
    },
   getLongitudeLatitude() {
    // let diff=1000;//这里是要滚动到的位置高度
    //   var container = this.$refs.box
      // this.$refs.box.offsetTop = 0;
    document.documentElement.scrollTop = 0
    // console.log( document.documentElement.scrollTop)
    // document.getElementsByClassName("single-con")[0].scrollTop = 0; 
      var _this = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          //locationSuccess 获取成功的话
          function (position) {
            _this.getLongitude = position.coords.longitude;
            _this.getLatitude = position.coords.latitude;
            // alert(_this.getLongitude,)
            // alert( _this.getLatitude)
            this.lng=_this.getLongitude
            this.lat=_this.getLatitude
            this.getAllArr()

          },
          //locationError  获取失败的话
          function (error) {
            var errorType = [
              "您拒绝共享位置信息",
              "获取不到位置信息",
              "获取位置信息超时",
            ];
            console.log(errorType[error.code - 1]);
          }
        );
      }
    },

    getAllArr() {
  //     Vue.prototype.$qgMapTransBMap=(lng, lat)=>{
  //   let x_pi = (3.14159265358979324 * 3000.0) / 180.0
  //   let x = lng
  //   let y = lat
  //   let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi)
  //   let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi)
  //   let lngs = z * Math.cos(theta) + 0.0065
  //   let lats = z * Math.sin(theta) + 0.006
  //   return {
  //     lng: lngs,
  //     lat: lats,
  //   }
  // },
      let formData = new FormData()
      formData.append("market_id", this.id);
      formData.append("market_class_id", '1111');
      formData.append("lng",this.lng);
      formData.append("lat",this.lat);
      this.$http({
        method: "post",
        url: "/v1/app-market/get-market-detail",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.marketdetail=res.data.data
          this.marketdetail=res.data.data
          this.mclist=res.data.data.mc_list.mc_list
          this.market=res.data.data.market
          this.h5Map=res.data.data.market.h5Map
          document.title = this.market.market_name
          this.m_tags=res.data.data.market.m_tags
          this.market_select=res.data.data.market.market_select
           var content=this.market_select
            content = content.replace(/\<img/gi, '<img style="width: 100%;"')
          this.market_select=content
          console.log(content)
          console.log(this.market_select)
            var data = [];
					  content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/, function (match, capture) {
					  data.push(capture);
					  });
						console.log(data)
          this.market_good=res.data.data.market.market_good
          this.marketJoinin=res.data.data.market.marketJoinin
          this.marketMactivity=res.data.data.market.marketMactivity
          this.market_mark=res.data.data.market.market_mark
          this.mc_corplist=res.data.data.mc_list.mc_list[0].mc_corplist
          // console.log(this.mc_corplist);
          this.shearConfig();

          // this.content=res. data.data.content
          // var content=this.content
          //   content = content.replace(/\<img/gi, '<img class="img"')
          // this.content=content
          // // console.log(content);
          // var content=this.content
          //   content = content.replace(/\height/gi, ' ')
          // this.content=content
        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    getCustomerMarket() {
      console.log(this.messagephone)
      console.log(this.messagename)
      let formData = new FormData()
      formData.append("market_id", this.id);
      formData.append("phone", this.messagephone);
      formData.append("name",this.messagename);
      this.$http({
        method: "post",
        url: "/v1/app-join/save-customer-market",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data)
        if (res.data.error==0) {
           this.showpptc=false
        alert(res.data.data);
        this.messagename=''
        this.messagephone=''

        }else{
        alert(res.data.error_msg);

        }

     

        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    zhanshouu(){
      if(this.zhanshou=='展开↓'){
        console.log('222')
        this.zhanshou='收起↑'
        this.hei='100%'
      }else{
        this.zhanshou='展开↓',
        this.hei='1rem'

      }
      
  
    },
    zhanshouu1(){
      if(this.zhanshou1=='展开↓'){
        console.log('222')
        this.zhanshou1='收起↑'
        this.hei1='100%'
      }else{
        this.zhanshou1='展开↓',
        this.hei1='1rem'

      }
      
  
    },
    zhanshouu2(){
      if(this.zhanshou2=='展开↓'){
        console.log('222')
        this.zhanshou2='收起↑'
        this.hei2='100%'
      }else{
        this.zhanshou2='展开↓',
        this.hei2='1rem'

      }
      
  
    },
    showtc(index){
      this.marketindex=index
      this.mc_corplist=this.mclist[index].mc_corplist
      // console.log(this.mc_corplist)
      // console.log(this.mclist)
      // console.log(index)
      // console.log(this.mclist[index])
    },
        //显示品牌弹窗
    //显示品牌弹窗
    showtccc(item){
      this.showpptc=true
    },
    yctc(){
      this.showpptc=false

    },
    // 调用拨号功能2
    callphone () {
     window.location.href = 'tel://' + this.marketJoinin.contacts_phone
    }

  },
  // watch: {
  //        $route: function (to, form) {
  //         console.log('wwww',window.scrollTo(0,0))
  //         window.scrollTo(0,0)
  //         // if (oldVal.path === '/admissionServices/chooseArea') {  /*给滚动条赋值*/
  //         //   eventHub.$on('chooseAreaChange', this.chooseArea)
  //         //   this.$refs.Box.scrollTop = this.scrollTop
  //         // } else {
  //         //   this.scrollTop = this.$refs.Box.scrollTop /*保存当前滚动条位置*/
  //         // }
   
  //       // 深度观察监听
  //       // deep: true
  //     }
  //   },

beforeRouteLeave(to,from,next){
   if(to.path == '/Sintroduce'){
          // window.location.reload(); //监测到路由发生跳转时刷新一次页面
          console.log('离开历',this.reloadaaa)

   }

   next()
},

};
</script>
<style scoped>
.page{
    background-color:#F7F8FB;
    /* height: 100vh; */
}
.heaimg{
    width: 100%;
    position: relative; 
    top: 0;
    left: 0;
    height: 5.7rem;
    /* background-color: aqua; */
    margin-bottom: 2.2rem;
}
.headimgbox{
  position: relative;
}
.lookbox{
 padding: 0.5;
  background-color: #000000;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
}
.headbox{
    width: 6.9rem;
    height: 3.1rem;
    position: absolute;
    top: 5rem;
    left: 50%;
    transform: translate(-50%);
    background-color: rgb(255, 255, 255);
    /* border: 0.01rem solid rgb(44, 42, 42); */
    border-radius: 0.1rem;
    box-sizing: border-box;
    padding:  0.35rem;
}
.headtit{
  font-size: 0.3rem;
  font-weight: bold;
  display: flex;
  margin-bottom: 0.35rem;
}
.headtime{
  font-size: 0.2rem;
  display: flex;

}
.img{
  width: 100%;
}
.headside{
  width: 4.3rem;
  font-size: 0.2rem;
  font-weight: bold;
  display: flex;
  overflow: hidden; 
   text-overflow: ellipsis; 
  -webkit-box-orient:vertical;
  display: -webkit-box; 
    -webkit-line-clamp:2;


}
.headjuli{
  font-size: 0.2rem;
  color: #8C8F9F;
  display: flex;
}
.ttimg{
    width: 0.25rem;
    height: 0.25rem;
    margin-top: 0.05rem;
    margin-right: 0.1rem;
    margin-bottom: 0.2rem;
}
.ttimg1{
    width: 0.25rem;
    height: 0.25rem;
    margin-top: 0.05rem;
    margin-right: 0.1rem;
    /* margin-bottom: 0.2rem; */
}
.hsjbox{
    display: flex;
    justify-content: space-between;
}
.dhbox{
    border-left: 0.01rem solid #E8EAF3;
    padding: 0 0.31rem;
    height: 0.8rem;
}
.dhimg{
    width: 0.23rem;
    height: 0.23rem;
    /* margin-top: -5rem; */
    margin-left: 0.3rem;
}
.dhbox1{
    margin-top: -0.75rem;
}
.tittle{
    border-left: 0.3rem solid #FECB32;
    font-size: 0.35rem;
    font-weight: bold;
    padding-left: 0.2rem;
    box-sizing: border-box;
    height: 0.45rem;
    margin: 0rem 0; 
    margin-bottom: 0.1rem;


}
.xinxibox{
  height: 1rem;
  overflow: hidden;
    /* width: 6.9rem; */
    padding: 0.3rem;
    /* border-radius: 0.2rem; */
    background-color: #fff;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 0.28rem;
    margin-bottom: 0.2rem;
    padding-top: 0;
}
.shopbox{
    width: 6.9rem;
    /* padding:  0.15rem; */
    border-radius: 0.2rem;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 0.28rem;
    display: flex;
    flex-wrap: wrap;
}
.shopli{
  width: 2rem;
  /* height: 2.5rem; */
  border-radius: 0.1rem;
  margin: 0.15rem;
    background-color: #fff;
    overflow: hidden;
    position: relative;

}
.shopimg{
  width: 100%;
  height: 1.5rem;
  /* background-color: #FECB32; */
}
.shoptit{
  text-align: center;
  font-size: 0.24rem;
  font-weight: bold;
  line-height: 0.54rem;
  /* background-color: #adaca8; */
  width: 2rem;
  white-space: nowrap; 
text-overflow: ellipsis; 
overflow: hidden; 


}
.classbox{
  width: 6rem;
  /* height: 1rem; */
  /* background: rgb(83, 76, 76); */
  overflow-x: scroll;
  white-space:nowrap;
  /* display: flex; */
  /* padding-top: -1rem; */
  margin-top: -0.35rem;


}
.classitem{
  /* width: 0.6rem; */
  height: 0.7rem;
  background: #fff;
  font-size: 0.24rem;
  display: inline-block;
  margin: 0.05rem;
  white-space:nowrap;
  padding:  0.05rem 0.2rem;
  text-align: center;
}
.clbox{
  display: flex;
  justify-content: space-between;
  padding: 0 0.3rem;
}
.gengduo{
    margin-top: 0.03rem;
    line-height: 0.7rem;
    /* color: #000; */
}
.color{
  background: #FECB32;
}
.jiben{
  width: 100%;
  background-color: #fff;
  padding: 0.3rem 0;
  margin: 0.3rem 0;
}
.jibentext{
  display: flex;
  padding: 0 0.3rem;
  margin: 0.15rem 0;

}
.jibentit{
  width: 1.2rem;
 font-size: 00.24rem;
 margin-right: 0.2rem;
color: rgb(97, 96, 96);
}
.jibencon{
 font-size: 00.24rem;
 display: flex;


}
.connn{
  margin-right: 0.15rem;
}
.red{
  color: red;
}
.hdox{
  width: 6.9rem;
  margin: 0 auto;
  /* border: 0.01rem solid #d7d7d8; */
  margin-top: 0.2rem;
  
}
.hdoxx{
  width: 6.9rem;
  /* height: 4rem; */
  /* background: #FECB32; */
  margin: 0 auto;
  border: 0.01rem solid #d7d7d8;
  margin-top: 0.2rem;
  display: flex;
  overflow-x: scroll;
  white-space:nowrap;

}
.hdoxxs{

  margin: 0 auto;
  /* border: 0.01rem solid #d7d7d8; */
  /* margin-top: 0.2rem; */
  display: flex;
  /* overflow-x: scroll;
  white-space:nowrap; */

}
.hdimg{
  width: 100%;
  height: 3.5rem;
}
.hdtit{
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.5rem;
  margin-left: 0.2rem;
  margin-bottom: 0.15rem;
}
.dibox{
  width: 100%;
  background: #ffffff;
  /* padding: 0.2rem; */
  position: fixed;
  bottom: 0;
  left: 0;
  
}
.dibutton{
  width: 6.8rem;
  background-color: #FECB32;
  /* color: #fff; */
  line-height: 0.7rem;
  font-size: 0.3rem;
  text-align: center;
  margin: 0.2rem auto;
  font-weight: bold;
  border-radius: 0.1rem;

}
.tcbox{
  width: 6.90rem;
  /* height: 8.4rem; */
  /* border: 0.01rem solid red; */
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 11;
  text-align: center;

}
.black{
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.h22img{
    width: 6.90rem;
  height: 8.4rem;

}
.tccon{
  width: 6.50rem;
height: 5.47rem;
background: #FFFFFF;
border-radius: 0.1rem;
position: absolute;
top: 2.76rem;
left: 0.2rem;
padding: 0.2rem;
box-sizing: border-box;
}
.h23img{
  width: 00.72rem;
  height: 0.72rem;

}
.tcimg{
  width: 2.78rem;
height: 2.02rem;
background: #B5001F;
margin-right: 0.3rem;
}
.tcxinxin{
  display: flex;
}
.tctit{
  width: 3rem;

  font-size: 0.3rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: 0.1rem;
  	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;

}
.touzi1{
  font-size: 0.3rem;
  color: #03050F;
  display: flex;
  font-weight: bold;
    	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
}
.touzi2{
  width: 3rem;
  font-size: 0.28rem;
  color: #03050F;
  font-weight: bold;
    	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
  margin-bottom: 0.3rem;
}
.touzi3{
  font-size: 0.2rem;
  color: #8d8d8d;
  display: flex;
  /* font-weight: bold; */
}
.tcnum{
  
font-weight: bold;
color: #EE1818;
margin-bottom: 0.15rem;
margin-left: 0.1rem;
}
.inpbox{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.inp{
  width: 2.8rem;
  height: 0.86rem;
  background: rgba(127,127,127,0);
  border: 0.01rem solid #8C8F9F;
  border-radius: 0.1rem;
  padding-left: 0.12rem;
}
.tcbut{
  width: 6.10rem;
height: 0.81rem;
background: #FECB32;
border-radius: 0.1rem;

font-size: 0.3rem;
font-weight: bold;
color: #03050F;
line-height: 0.24rem;
text-align: center;
line-height: 0.81rem;
margin-top: 0.2rem;
}
.tctext{
  
font-size: 0.24rem;

color: #8C8F9F;
margin-top: 0.3rem;

}
.s11img{
  position: absolute;
  top: 0;
  right: 0;
  width: 0.42rem;
  height: 0.37rem;

}
.zhanshou{
  text-align: center;
  font-size: 0.3rem;
  color: #FECB32;
}
</style>