<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- 头部 -->
    <div class="heanav">
      <router-link to="/Search">
      <div class="heainputbox">
        <img class="h0img" src="../assets/imgs/h0.png" alt="" />
      </div>
      </router-link>
      <img class="xiaoxiimg" src="../assets/imgs/xiaoxi.png" alt="" />
    </div>
    <!-- 轮播 -->
    <div class="bannerbox">
      <div class="bannerbox">
        
        <el-carousel indicator-position="outside" height="3rem" v-if="bannerarr[0]">
          <el-carousel-item v-for="(item, index) in bannerarr" :key="index">
            <img class="himg" :src="item.image" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 分类 -->
    <div class="flbox">
      <div class="fenlei">
        <router-link to="/Homestore">
          <img class="h1img" src="../assets/imgs/h1.png" alt="" />
          <div class="fltext">家居卖场</div>
        </router-link>
      </div>
      <div class="fenlei">
        <router-link to="/Cncba">
          <img class="h1img" src="../assets/imgs/h2.png" alt="" />
          <div class="fltext">产业联盟</div>
        </router-link>
      </div>
      <div class="fenlei">
        <router-link to="/Information">
          <img class="h1img" src="../assets/imgs/h3.png" alt="" />
          <div class="fltext">资讯</div>
        </router-link>
      </div>
      <div class="fenlei">
        <router-link to="/Tojoin">
          <img class="h1img" src="../assets/imgs/h4.png" alt="" />
          <div class="fltext">加盟</div>
        </router-link>
      </div>
      <div class="fenlei">
        <router-link to="/Exhibition">
          <img class="h1img" src="../assets/imgs/h5.png" alt="" />
          <div class="fltext">展会</div>
        </router-link>
      </div>
    </div>
    <!-- 爱家头条 -->
    <router-link :to="'/Headline?id='+News.id">
      <div class="aijbox">
        <img class="h6img" src="../assets/imgs/h6.png" alt="" />
        <img class="h7img" src="../assets/imgs/h7.png" alt="" />
        <div class="kxbox">
          <div class="kuaixun">快讯</div>
          <div class="kxtit">{{ News.title }}</div>
        </div>
        <div class="kxdibu">
          <div class="kditu">
            <img
              v-for="(item, index) in News.article_avatar"
              :key="index"
              class="ktuimg"
              :src="item.avatar_url"
              alt=""
            />
          </div>
          <div class="fxnum">{{ News.set_look_nums }}人分享</div>
          <div class="flbut">分享</div>
        </div>
      </div>
    </router-link>
    <!-- 行业热点 -->
    <div class="titbox">
      <div class="tittext">行业热点</div>
      <div class="titgd">更多频道</div>
    </div>

      <div class="redcon">
       <router-link :to="'/Headline?id='+erdian0.article_id">
        <div class="redian">
          <img class="reimg" src="../assets/imgs/h8.png" alt="" />
          <div class="rdtext">{{erdian0.article_title}}</div>
        </div>
      </router-link>
       <router-link :to="'/Headline?id='+erdian1.article_id">
        <div class="redian">
          <img class="reimg" src="../assets/imgs/h9.png" alt="" />
          <div class="rdtext">{{erdian1.article_title}}</div>
        </div>
      </router-link>
       <router-link :to="'/Headline?id='+erdian2.article_id">
        <div class="redian">
          <img class="reimg" src="../assets/imgs/h10.png" alt="" />
          <div class="rdtext">{{erdian2.article_title}}</div>
        </div>
      </router-link>
       <router-link :to="'/Headline?id='+erdian3.article_id">
        <div class="redian" v-if="erdian3==[]">
          <img class="reimg" src="../assets/imgs/h88.png" alt="" />
          <div class="rdtext">{{erdian3.article_title}}</div>
        </div>
      </router-link>

        <!-- <div class="redian">
          <img class="reimg" src="../assets/imgs/h9.png" alt="" />
          <div class="rdtext">{{erdian1}}</div>
        </div>  
        <div class="redian">
          <img class="reimg" src="../assets/imgs/h10.png" alt="" />
          <div class="rdtext">{{erdian2}}</div>
        </div>
        <div class="redian">
          <img class="reimg" src="../assets/imgs/h88.png" alt="" />
          <div class="rdtext">{{erdian3}}</div>
        </div> -->
      </div>

    <!-- 推荐品牌 -->
    <div class="pinpbox">
      <img class="h11img" src="../assets/imgs/h11.png" alt="" />
      <div class="titbox">
        <div class="tittext tittext1">推荐品牌</div>
        <div class="titgd titgd1">更多</div>
      </div>
      <div class="pinpnav">
        <div class="h20box" v-for="(item,index) in tjpinpai" :key="index" @click="tuijian(item,index)">
          <div class="htit" :style="{'color': (index==clickid?'#fff':'')}" >{{item.title}}</div> 
          <img v-if="index==clickid?true:false" class="h20img" src="../assets/imgs/h20.png" alt="" />
          <img v-if="index!==clickid?true:false" class="h20img" src="../assets/imgs/h21.png" alt="" />
        </div>
      </div>
      <div class="pinplistbox">
        <div v-for="(item,index) in pinpailist" :key="index">
         
            <div class="pinplist">
            <router-link :to="'/Branddetail?corp_id='+item.corp_id">
              <img class="ppimg" :src="item.brand_logo_new" alt="" />
           </router-link>

          <div class="pptjtit">{{item.brand_name}}</div>
          <div class="pptjbut" @click.stop="showtc(item)">了解详情</div>
            </div>

        </div>
      </div>
    </div>
    <!-- 品牌弹窗 -->
    <div class="tcbox" v-if="showpptc">
      <img class="h22img" src="../assets/imgs/h22.png" alt="">
      <div class="tccon">
        <div class="tcxinxin">
          <img class="tcimg" :src="pptccon.brand_logo_new" alt="">
          <div>
            <div class="tctit">{{pptccon.brand_name}}</div>
            <div class="touzi">基本投资: <div class="tcnum">{{pptccon.money_min}}万-{{pptccon.money_max}}万</div> </div>
            <div class="touzi">全国门店: <div class="tcnum">{{pptccon.corp_store_num}}家</div> </div>
          </div>
        </div>
        <div class="inpbox">
            <input  class="inp" type="text" placeholder="请输入您的姓名">
            <input  class="inp" type="text" placeholder="请输入手机号码">
        </div>
        <div class="tcbut" @click="yctc">提交加盟咨询</div>
        <div class="tctext">留下您的联系方式，我们马上为您服务</div>
      </div>
      <img class="h23img" @click="yctc" src="../assets/imgs/h23.png" alt="">
    </div>
    <div class="black" v-if="showpptc"></div>
    <!-- 推荐资讯 -->
    <div class="titbox">
      <div class="tittext">推荐资讯</div>
      <div class="titgd">更多</div>
    </div>
    <div class="zxbox">
     
      <div v-for="(item, index) in tjzixun" :key="index">
         <router-link :to="'/Headline?id='+item.article_id">
         <div class="zxlist">
        <img class="zxlistimg" :src="url + item.path_pic" alt="" />
        <div>
          <div class="listtit">{{ item.article_title }}</div>
          <div class="listtime">{{ item.source }} {{ item.create_time }}</div>
          <div class="listnum">
            访问 <div class="listnum11">{{ item.set_visitors_nums }} </div> &nbsp; ·&nbsp;  浏览 <div  class="listnum11">{{ item.set_look_nums }}</div>
          </div>
        </div>
          </div>
      </router-link>
   
      </div>

    </div>

    <!-- 底部导航 -->
    <!-- <div class="tabBar">
      <div class="tab">
        <router-link to="/">
          <img class="t1img" src="../assets/imgs/t1.png" alt="">
          <div class="tabtext">首页</div>
        </router-link>
      </div>
      <div class="tab">
        <router-link to="/workbench">
          <img class="t1img" src="../assets/imgs/t3.png" alt="">
          <div class="tabtext">工作台</div>
       </router-link>
      </div>
      <div class="tab">
        <router-link to="/radar">
          <img class="t1img" src="../assets/imgs/t2.png" alt="">
          <div class="tabtext">雷达</div>
        </router-link>
      </div>
      <div class="tab">
        <router-link to="/mine">
          <img class="t1img" src="../assets/imgs/t4.png" alt="">
          <div class="tabtext">我的</div>
        </router-link>
      </div>

    </div>       -->
    <!-- 
    <router-link to="/">首页</router-link>|
    <router-link to="/about">我的</router-link> -->
  </div>
</template>
<script>
import wx from 'weixin-js-sdk';

export default {
  data() {
    return {
      url: "https://djdshop.vjiashuzi.com",
      allArr: [], //全部
      bannerarr: [], //轮播
      Headlines: [], //头条
      erdian: [], //行业热点
      erdian0: [], //行业热点
      erdian1: [], //行业热点
      erdian2: [], //行业热点
      erdian3: [], //行业热点
      tjzixun: [], //推荐资讯
      News: [], //头条
      tjpinpai:[],//推荐品牌
      clickid:'0',
      pinpailist:'',//推荐品牌列表
      showpptc:false,//品牌弹窗
      pptccon:[],//品牌弹窗内容
      
    };
  },

  created() {},
  mounted() {
    this.getAllArr();
    // this.Config();
      // alert(location.href.split('#')[0])

  },
  methods: {
  Config(){
   let formData = new FormData()
      formData.append("url",location.href.split('#')[0]);
      this.$http({
        method: "post",
        url: "/v1/wx-public/h5-share-token",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          wx.checkJsApi({
            jsApiList: ['openLocation'], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
            success: function(res) {
            console.log(res)
            // console.log('111111')
            // alert('111111')

            // 以键值对的形式返回，可用的 api 值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            }
          });
          wx.config({
              debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
              appId: 'wxfbd6f16c8b572b3d', // 必填，公众号的唯一标识
              timestamp:res.data.jasp.timestamp , // 必填，生成签名的时间戳
              nonceStr:res.data.jasp.nonceStr, // 必填，生成签名的随机串
              signature:res.data.jasp.signature,// 必填，签名
              jsApiList: ['openLocation'], // 必填，需要使用的 JS 接口列表
              openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
          })
          wx.ready(function () {
            alert('ready')

            // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中
          });
          // wx.error(function (res) {
          //   alert('error',res)
          //   // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中
          // });

        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllArr() {
      this.$http({
        method: "post",
        url: "/v1/app-index/home-page-information",
        params: {},
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          //  "Access-Control-Allow-Origin": "*",
        },
      })
        .then((res) => {
          console.log(res);
          this.allArr = res.data;
          this.bannerarr = res.data.data.HomeChangeData[0].item;
          this.News = res.data.data.News;
          this.erdian = res.data.data.HomeChangeData[1].item[0].list[0].item;
          if(this.erdian.length==2){
          this.erdian0 = res.data.data.HomeChangeData[1].item[0].list[0].item[0];
          this.erdian1 = res.data.data.HomeChangeData[1].item[0].list[0].item[1];
          }
          if(this.erdian.length==3){
          this.erdian0 = res.data.data.HomeChangeData[1].item[0].list[0].item[0];
          this.erdian1 = res.data.data.HomeChangeData[1].item[0].list[0].item[1];
          this.erdian2 = res.data.data.HomeChangeData[1].item[0].list[0].item[2];
          }
          if(this.erdian.length==4){
          this.erdian0 = res.data.data.HomeChangeData[1].item[0].list[0].item[0];
          this.erdian1 = res.data.data.HomeChangeData[1].item[0].list[0].item[1];
          this.erdian2 = res.data.data.HomeChangeData[1].item[0].list[0].item[2];
          this.erdian3 = res.data.data.HomeChangeData[1].item[0].list[0].item[3];
          }
          console.log('this.erdian0',this.erdian0)
          this.tjzixun = res.data.data.HomeChangeData[4].item[0].list[0].item;
          this.tjpinpai = res.data.data.HomeChangeData[3].item[0].list;
          this.pinpailist = res.data.data.HomeChangeData[3].item[0].list[0].item;
          console.log(this.allArr);
          // console.log("轮播", this.bannerarr);
          // console.log("头条", this.News);
          console.log("热点", this.erdian);
          // console.log("资讯", this.tjzixun);
          // console.log("品牌", this.tjpinpai);
          // console.log("品牌列表", this.pinpailist);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //推荐品牌
    tuijian(item,index){
      console.log('11111',item)
      // console.log('index',index)
      this.pinpailist=item.item
      this.clickid=index
      console.log(this.clickid)
    },
    //显示品牌弹窗
    showtc(item){
      this.pptccon=item
      this.showpptc=true
    },
    yctc(){
      this.showpptc=false

    }
  },
};
</script>

<style scoped>
::v-deep .el-carousel__indicators--horizontal{
    position: absolute !important;
    left: 35%;
    top: 60%;
}
.heanav {
  background-color: #fecb32;
  height: 1.28rem;
  position: relative;
}
.heainputbox {
  width: 6rem;
  height: 0.68rem;
  background-color: #fff;
  border-radius: 0.34rem;
  position: absolute;
  bottom: 0.3rem;
  left: 0.34rem;
  display: flex;
}
.bannerbox{
  overflow: hidden;
  height:3rem;
  overflow-y: hidden;
}
.xiaoxiimg {
  width: 0.38rem;
  height: 0.36rem;
  position: absolute;
  right: 0.32rem;
  bottom: 0.45rem;
}
.himg {
  height: 100%;
}
.flbox {
  display: flex;  
  justify-content: space-between;
  background-color: #fff;
  padding: 0rem 0.28rem;
  box-sizing: border-box;
  padding-bottom: 0.33rem;
  padding-top: 0.1rem;
  /* margin-top: .23rem; */
}
.h1img {
  width: 0.9rem;
  height: 00.9rem;
  border-radius: 50%;
  margin: 0;
}
.fltext {
  font-size: 0.28rem;
  margin-top: -0.1rem;
}
.fenlei {
  text-align: center;
}
.h0img {
  width: 0.26rem;
  height: 0.27rem;
  margin-left: 0.2rem;
  margin-top: 0.21rem;
}
.aijbox {
  width: 6.9rem;
  height: 2.71rem;
  margin: 0 auto;
  /* background-color: aqua; */
  margin-top: 0.3rem;
  position: relative;
}
.h6img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.h7img {
  width: 1.32rem;
  height: 0.26rem;
  margin-left: 0.27rem;
  position: absolute;
  top: 0.32rem;
}
.kxbox {
  display: flex;
  /* margin-top: 0.84rem; */

  padding-top: 0.84rem;
}
.kuaixun {
  width: 0.52rem;
  height: 0.26rem;
  background: #fde7db;
  font-size: 0.18rem;
  margin: 0 0.3rem;
}
.kxtit {
  font-size: 0.3rem;
  width: 5.47rem;
}
.kxdibu {
  display: flex;
  margin-top: 0.2rem;
  justify-content: space-between;
  padding: 0 0.25rem;
}
.ktuimg {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: #fecb32;
  margin-left: -0.1rem;
  border: 0.02rem solid rgb(255, 255, 255);
  box-sizing: border-box;
}
.fxnum {
  font-size: 0.28rem;
  color: #5d5f6a;
}
.flbut {
  width: 1.6rem;
  height: 0.6rem;
  background: #fecb32;
  border-radius: 0.3rem;
  font-size: 0.3rem;
  text-align: center;
  line-height: 0.6rem;
}
.kditu {
  margin-top: -0.65rem;
}
.titbox {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0.3rem;
}
.tittext {
  font-size: 0.3rem;
  font-weight: bold;
  color: #222222;
}
.titgd {
  font-size: 0.22rem;
  color: #929294;
}
.redcon {
  width: 6.9rem;
  /* height: 2.66rem; */
  background: #ffffff;
  border-radius: 0.1rem;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0.3rem 0.3rem;
  padding-bottom: 0.04rem;
}
.redian {
  display: flex;
  margin-bottom: 0.26rem;
}
.reimg {
  width: 0.24rem;
  height: 0.36rem;
  margin-right: 0.15rem;
}
.rdtext {
  font-size: 0.3rem;
  width: 6rem;
	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
}
.pinpbox {
  width: 6.9rem;
  /* height: 9.71rem; */
  background: #fff3e4;
  border-radius: 0.1rem;
  margin: 0.4rem auto;
  position: relative;
  z-index: 1;
}
.h11img {
  width: 100%;
  height: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.tittext1 {
  color: #501f08;
}
.titgd1 {
  color: #814220;
}
.zxbox {
  width: 6.9rem;
  margin: 0 auto;
  padding: 0.4rem;
  background-color: #fff;
  box-sizing: border-box;
}
.zxlist {
  display: flex;
  border-bottom: 0.01rem solid #eeeeee;
  padding: 0.3rem;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 0.3rem;
}
.zxlistimg {
  background: #222222;
  width: 2.9rem;
  height: 2rem;
  border-radius: 0.1rem;
  margin-top: -0.3rem;
  margin-left: -0.3rem;
  margin-right: 0.2rem;
}
.listtit {
  font-size: 0.3rem;
  /* background: #501F08; */
  width: 3.21rem;
  height: 0.8rem;
  margin-top: -0.2rem;
  font-family: PingFang SC;
  font-weight: 500;
	overflow:hidden;
  text-overflow:ellipsis; 
  white-space: normal;  
  display:-webkit-box;  
  -webkit-box-orient:vertical; 
  -webkit-line-clamp:2;
}
.listtime {
  font-size: 0.22rem;
  color: #8c8f9f;
  margin-top: 0.18rem;
  margin-bottom: 0.21rem;
}
.listnum {
  font-size: 0.22rem;
  color: #8c8f9f;
  display: flex;
}
.listnum11 {
  color: #000000;
}
.tabBar {
  width: 100%;
  height: 1rem;
  background: #ffffff;
  /* box-shadow: 0rem -0.01rem 0.1rem 0rem #EAEAEA; */
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.5rem;
}

.tab {
  text-align: center;
  /* background-color: #814220; */
  position: relative;
  width: 0.8rem;
}
.t1img {
  width: 0.38rem;
  height: 0.38rem;
  position: absolute;
  top: 0.15rem;
  left: 50%;
  transform: translate(-50%);
}
.tabtext {
  font-size: 0.18rem;
  margin-top: 0.6rem;
}
.pinpnav {
  display: flex;
  padding-left: 0.3rem;
}
.h20box {
  width: 1.4rem;
  height: 0.61rem;
  margin-right: 0.2rem;
  position: relative;
}
.htit{
  font-size: 0.2rem;
  text-align: center;
  line-height: 0.54rem;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 00.15rem;
  color: #AA816B;
}
.h20img {
  width: 1.4rem;
  height: 0.61rem;
  margin-right: 0.2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.pinplistbox {
  display: flex;
  padding: 0.2rem;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pinplist {
  width: 3rem;
  /* height: 3.7rem; */
  text-align: center;
  background-color: #fff;
  border-radius: 00.1rem;
  /* margin-right: 0.1rem; */
  text-align: center;
  margin: 0  0.1rem;
  margin-bottom: 0.3rem;
  padding-bottom: 0.1rem;

}
.ppimg {
  width: 2.63rem;
  height: 1.6rem;
  background-color: blueviolet;
  margin-top: 0.18rem;
  border-radius: 00.1rem;
}
.pptjtit {
  font-size: 0.24rem;
  font-weight: bold;
  color: #03050f;
}
.pptjtext {
  display: flex;
  font-size: 0.1rem;
  color: #666666;
}
.pptjnum {
  font-size: 0.22rem;
  font-weight: bold;
  color: #ee1818;
}
.pptjbut {
  background-color: #fecb32;
  width: 1.4rem;
  height: 0.46rem;
  text-align: center;
  font-size: 0.24rem;
  font-weight: bold;
  color: #03050f;
  line-height: 0.46rem;
  margin: 0.2rem auto;
  /* transform: scale(); */
  border-radius: 00.1rem;
}
.fffcolor{
  color: rgb(255, 0, 0);
}
.tcbox{
  width: 6.90rem;
  /* height: 8.4rem; */
  /* border: 0.01rem solid red; */
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 11;
  text-align: center;

}
.black{
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.h22img{
    width: 6.90rem;
  height: 8.4rem;

}
.tccon{
  width: 6.50rem;
height: 5.47rem;
background: #FFFFFF;
border-radius: 0.1rem;
position: absolute;
top: 2.76rem;
left: 0.2rem;
padding: 0.2rem;
box-sizing: border-box;
}
.h23img{
  width: 00.72rem;
  height: 0.72rem;

}
.tcimg{
  width: 2.78rem;
height: 2.02rem;
background: #B5001F;
margin-right: 0.3rem;
}
.tcxinxin{
  display: flex;
}
.tctit{
  font-size: 0.3rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: 0.1rem;
}
.touzi{
  font-size: 0.2rem;
  color: #03050F;
  display: flex;
}
.tcnum{
  
font-weight: bold;
color: #EE1818;
margin-bottom: 0.15rem;
margin-left: 0.1rem;
}
.inpbox{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.inp{
  width: 2.8rem;
  height: 0.86rem;
  background: rgba(127,127,127,0);
  border: 0.01rem solid #8C8F9F;
  border-radius: 0.1rem;
  padding-left: 0.12rem;
}
.tcbut{
  width: 6.10rem;
height: 0.81rem;
background: #FECB32;
border-radius: 0.1rem;

font-size: 0.3rem;
font-weight: bold;
color: #03050F;
line-height: 0.24rem;
text-align: center;
line-height: 0.81rem;
margin-top: 0.2rem;
}
.tctext{
  
font-size: 0.24rem;

color: #8C8F9F;
margin-top: 0.3rem;

}
#launch-btn{
    width: 7rem;
  height: 1rem;
  background: #B5001F;
  position: fixed;
  bottom: 0.5rem;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  bottom: 0;
}
</style>
