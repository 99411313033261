import { createRouter, createWebHashHistory} from 'vue-router'
import Home from '../views/Home.vue' //首页
import Workbench from '../views/Workbench.vue' //工作台
import Radar from '../views/Radar.vue' //雷达
import Mine from '../views/Mine.vue' //我的
import Homestore from '../views/Homestore.vue'//家居卖场
import Cncba from '../views/Cncba.vue' //产业联盟
import Information from '../views/Information.vue' //资讯
import Tojoin from '../views/Tojoin.vue' //加盟
import Exhibition from '../views/Exhibition.vue' //展会
import Headline from '../views/Headline.vue' //爱家头条
import Branddetail from '../views/Branddetail.vue' //品牌详情
import Search from '../views/Search.vue' //搜索
import Szixun from '../views/Szixun.vue' //搜索
import Storedetail from '../views/Storedetail.vue' //家具卖场详情
import Map from '../views/Map.vue' //地图
import Sintroduce from '../views/Sintroduce.vue' //店铺介绍
import Activity from '../views/Activity.vue' //店铺介绍
import Storejihe from '../views/Storejihe.vue' //店铺集合更多
import Exhibidetail from '../views/Exhibidetail.vue' //展会详情
import Exhibilist from '../views/Exhibilist.vue' //展会列表
import Exhibiduce from '../views/Exhibiduce.vue' //展会详情
import Premises from '../views/Premises.vue' //楼盘
import PremisesActiv from '../views/PremisesActiv.vue' //楼盘活动
import Premduce from '../views/Premduce.vue' //楼盘
import Premdjihe from '../views/Premdjihe.vue' //楼盘
import Supplydetail from '../views/Supplydetail.vue' //楼盘

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/workbench',
    name: 'Workbench',
    component: Workbench
  },
  {
    path: '/radar',
    name: 'Radar',
    component: Radar
  },
  {
    path: '/mine',
    name: 'Mine',
    component: Mine
  },
  {
    path: '/homestore',
    name: 'Homestore',
    component: Homestore
  },
  {
    path: '/cncba',
    name: 'Cncba',
    component: Cncba
  },
  {
    path: '/information',
    name: 'Information',
    component: Information
  },
  {
    path: '/tojoin',
    name: 'Tojoin',
    component: Tojoin
  },
  {
    path: '/exhibition',
    name: 'Exhibition',
    component: Exhibition
  },
  {
    path: '/headline',
    name: 'Headline',
    component: Headline
  },
  {
    path: '/branddetail',
    name: 'Branddetail',
    component: Branddetail
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/szixun',
    name: 'Szixun',
    component: Szixun
  },
  {
    path: '/storedetail',
    name: 'Storedetail',
    component: Storedetail,
    meta: {
      keepAlive: true
     },
    //  component:()=>import('./index ')
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/sintroduce',
    name: 'Sintroduce',
    component: Sintroduce,
    meta: {
      keepAlive: true
     },
  },
  {
    path: '/activity',
    name: 'Activity',
    component: Activity
  },
  {
    path: '/Storejihe',
    name: 'Storejihe',
    component: Storejihe
  },
  {
    path: '/exhibidetail',
    name: 'Exhibidetail',
    component: Exhibidetail
  },
  {
    path: '/exhibilist',
    name: 'Exhibilist',
    component: Exhibilist
  },
 
  {
    path: '/exhibiduce',
    name: 'Exhibiduce',
    component: Exhibiduce
  },
 
  {
    path: '/premises',
    name: 'Premises',
    component: Premises
  },
  {
    path: '/premisesActiv',
    name: 'PremisesActiv',
    component: PremisesActiv
  },
  {
    path: '/premduce',
    name: 'Premduce',
    component: Premduce
  },
  {
    path: '/premdjihe',
    name: 'Premdjihe',
    component: Premdjihe
  },
 
  {
    path: '/supplydetail',
    name: 'Supplydetail',
    component: Supplydetail
  },
 
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// router.beforeEach((to, from, next) => {
//   to.meta.title?document.title = to.meta.title:document.title ='爱家帝'
//   next()
// })

export default router