<template>
    <div>
      <div class="title">{{allContent.title}}</div>
      <div class="contime">采编·{{allContent.source}}  &nbsp;{{allContent.look_time}}</div>
      <div  v-html="content" class="content"></div>
      
      <div class="bidbox">
        <div class="llnum">访问{{allContent.visitors_nums}}人&nbsp;浏览{{allContent.set_look_nums}}人</div>
        <div class="llnum">免责声明</div>
      </div>
      <div class="scbox">
        <div class="sctab"> <img class="x1img" src="../assets/imgs/x1.png" alt=""> 1</div>
        <div class="sctab"> <img class="x1img" src="../assets/imgs/x3.png" alt=""> 在线投稿报料</div>
      </div>
      <div class="scbox">
        <div class="sctab1"> 好文章,需要您的收藏</div>
        <div class="sctab1"> 好品牌,让更多人知道</div>
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      id:'',
      allContent:[],
      content:'',
    };
  },

  created() {
    console.log("ididididid",this.$route.query.id)
    this.id=this.$route.query.id
    console.log(this.id)
    
  },
  mounted() {
    this.getAllArr();
  },
  methods: {
    getAllArr() {
      let formData = new FormData()
      formData.append("id", this.id);
      this.$http({
        method: "post",
        url: "/v1/article-platform-public/get-article-detail",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.allContent=res. data.data
          this.content=res. data.data.content
          var content=this.content
            content = content.replace(/\<img/gi, '<img class="img"')
          this.content=content
          // console.log(content);
          var content=this.content
            content = content.replace(/\height/gi, ' ')
          this.content=content
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // //推荐品牌
    // tuijian(item,index){
    //   console.log('11111',item)
    //   // console.log('index',index)
    //   this.pinpailist=item.item
    //   this.clickid=index
    //   console.log(this.clickid)
    // }
  },
};
</script>
<style scoped>
.title{
  width: 6.69rem;
font-size: 0.42rem;
font-weight: bold;
color: #03050F;
line-height: 0.62rem;
margin: 0.2rem auto;
}
.contime{
  font-size: 0.23rem;
  color: #888;
  width: 6.9rem;
  margin: 0 auto;
  border-bottom: 00.01rem solid #EEEEEE;
  padding-bottom: 0.2rem;
}
.content{
  width: 6.9rem;
  margin: 0 auto;
  font-size: 00.3rem;
}
>>> .content  img{
    width: 6.9rem !important;
    /* height: 3.5rem !important; */
}
.llnum{
  font-size: 0.2rem;
  color: #888;
  margin-left: 0.3rem;
}
.bidbox{
  display: flex;
  justify-content: space-between;
  margin-right: 0.3rem;
}
.scbox{
  width: 100%;
  display: flex;
  padding: 0 0.3rem;
  box-sizing: border-box;
  justify-content: space-between;
  /* background-color: #FECB32; */
  margin-top: 1rem;

}
.sctab{
  width: 3.1rem;
  height: 1.3rem;
  background-color: #FECB32;
  display: flex;
  justify-content: center;
  z-index: 11;
  font-size: 0.3rem;
  line-height: 1.3rem;
}
.x1img{
  width: 0.72rem;
  height: 0.72rem;
  margin-top: 0.3rem;
  margin-right: 0.15rem;

}
.sctab1{
  font-size: 0.24rem;
  color: #8C8F9F;
  width: 3.1rem;
  text-align: center;
  margin-top: -0.8rem;
}


</style>