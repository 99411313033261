<template>
  <div class="navbox">产业联盟</div>
    <div class="allbox">
          <div class="cylmlist" v-for="(item, index) in lianmenglist" :key="index">
    <div class="cylmimg">
      <img class="c1img" :src="item.society_avatar" alt="" />
    </div>
    <div class="cylmcon">
      <div class="cymltit">{{ item.society_name }}</div>
      <div class="cymlsite">{{ item.society_address_detail }}</div>
      <div class="cymlqt">所属行业：{{ item.soc_class_name }}</div>
    </div>
  </div>
    </div>

</template>
<script>
export default {
  data() {
    return {
      lianmenglist: [],
    };
  },

  created() {
    this.getAllArr();
  },
  methods: {
    getAllArr() {
      let formData = new FormData();
      formData.append("lng", "1");
      formData.append("lat", "1");
      formData.append("province", "天津");
      formData.append("page", "1");
      formData.append("pageSize", "100");
      this.$http({
        method: "post",
        url: "/v1/app-society/get-society-list",
        params: {
          lng: "117.181784", //	是	float	经度
          lat: "39.083372", //是	float	维度
          province: "天津", //	否	string	省份
          page: "1", //	否	int	页码 默认1
          pageSize: "10", //	否	int	数量默认10  建材城定位: 定位：天津市當前经度：当前维度：
        },
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.lianmenglist = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.cylmlist {
  width: 6.9rem;
  margin: 0 auto;
  display: flex;
  padding: 0.3rem 0;
  border-bottom: 0.01rem solid #e8eaf3;
}
.cylmimg {
  width: 2.9rem;
  height: 1.8rem;
  border: 0.01rem solid #e8eaf3;
  margin-right: 0.3rem;
}
.cymltit {
  width: 3.5rem;
  font-size: 0.31rem;
  margin-top: 0.1rem;
  font-weight: bold;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cymlsite {
  font-size: 00.22rem;
  color: #5d5f6a;
  margin-top: 0.1rem;
  width: 3.21rem;
  height: 0.7rem;
  /* margin-top: -0.2rem; */
  font-family: PingFang SC;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.cymlqt {
  font-size: 0.24rem;
  color: #03050f;
  /* line-height: 0.24rem; */
  margin-top: 0.15rem;
}
.c1img {
  width: 100%;
  height: 100%;
}
.navbox{
  width: 100%;
  height: 1rem;
  background: #fecb32;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 1.1rem;
  font-weight: bold;
  font-size: 0.3rem;
  text-align: center;
  justify-content: center;

}
.allbox{
    margin-top: 1rem;
}

</style>