<template>
          <!-- <div class="navbox">
         <div class="heainputbox">
            <div class="ppbox">
                <div>品牌</div>
                <div class="ss"></div>
            </div>
            <input class="input" type="text" placeholder="" v-model="search">
         </div>
         <router-link :to="'/Szixun?search='+search">
            <div class="sstext" @click="ssbut">搜索</div>
         </router-link>
        </div> -->
  <div class="fenleibox">
    <div class="femls">
      <div class="taball" v-for="(item, index) in fenleiarr" :key="index" @click="flclick(index,item.corpclass_id)">
        <div class="tabtext">{{ item.corp_class_name }}</div>
        <div class="sansan" v-if="index==ssid?true:false"></div>
      </div>
    </div>
  </div>
  <div class="allbox">
    <div v-for="(item, index) in lianmenglist" :key="index" >
      <router-link :to="'/Headline?id='+item.article_id">
          <div class="cylmlist">
        <div class="cylmimg">
        <img class="c1img" :src="url + item.path_pic" alt="" />
        </div>
        <div class="cylmcon">
          <div class="cymlsite">{{ item.article_title }}</div>
          <div class="cymlqt">
            {{ item.create_time }} {{ item.source }} {{ item.set_look_nums }}浏览
          </div>
        </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lianmenglist: [], //列表
      fenleiarr: [], //分类
      url: "https://djdshop.vjiashuzi.com",
      search:""
    };
  },

  created() {
     this.search=this.$route.query.search
     console.log(this.search)
    this.getAllArr();
  },
  methods: {
    // 列表
    getAllArr() {
      let formData = new FormData();
      formData.append("search", this.search);
      formData.append("type", '1');
      formData.append("page", '1');
      formData.append("pageSize", '10');

      this.$http({
        method: "post",
        url: "/v1/app-index/home-page-search",
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.lianmenglist = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //分类
    getClassArr() {
      let formData = new FormData();
      formData.append("lng", "1");

      this.$http({
        method: "post",
        url: "/v1/app-join/get-corp-list",
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log("分类", res.data);
          this.fenleiarr = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    flclick(index,corpclass_id){
      this.ssid=index,
      this.corp_class_id = corpclass_id
      console.log(this.corp_class_id)
      this.getAllArr();
    }

  },
};
</script>
<style less>
body{
    background: #fff;
}
.cylmlist {
  width: 6.9rem;
  margin: 0 auto;
  display: flex;
  padding: 0.3rem 0;
  border-bottom: 0.01rem solid #e8eaf3;
}
.cylmimg {
  width: 2.1rem;
  height: 1.4rem;
  border: 0.01rem solid #e8eaf3;
  margin-right: 0.3rem;
  border-radius: 0.1rem;
  overflow: hidden;
}
.cymltit {
  width: 3.5rem;
  font-size: 0.31rem;
  margin-top: 0.1rem;
  font-weight: bold;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cymlsite {
  font-size: 00.26rem;
  color: rgb(7, 7, 7);
  margin-top: 0.1rem;
  width: 4.21rem;
  height: 0.7rem;
  /* margin-top: -0.2rem; */
  font-family: PingFang SC;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.cymlqt {
  font-size: 0.24rem;
  color: #979797;
  /* line-height: 0.24rem; */
  margin-top: 0.15rem;
}
.c1img {
  width: 100%;
  height: 100%;
}
.navbox {
  width: 100%;
  height: 1rem;
  background: #F9F9F9;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 1.1rem;
  font-weight: bold;
  font-size: 0.3rem;
}
.allbox {
  /* margin-top: 1.6rem; */
} 
.fenleibox {
  /* width: 100%; */
  height: 00.6rem;
  position: fixed;
  top: 0rem;
  left: 0;
  display: flex;
  justify-content: center;
  font-size: 0;
  background-color: rgb(241, 241, 241);
  overflow-x: scroll;
  display: flex;
}
.taball {
  width: 1.4rem;
  /* background-color: #d6d6d6; */
  text-align: center;
  /* margin: 0 0.2rem; */
}
.tabtext {
  font-size: 0.23rem;
  color: #5d5f6a;
  line-height: 0.6rem;
  /* margin: 0 0.1rem; */
}
.sansan {
  width: 0.28rem;
  height: 0.08rem;
  background: #fecb32;
  border-radius: 0.04rem;
  margin: 0 auto;
  margin-top: -0.08rem;
}
.femls {
  /* width: 100%; */
  display: flex;
  /* overflow-x: scroll; */
}
.navbox{
    display: flex;
    background: #F9F9F9;
    height: 1.28rem;
}
.heainputbox {
  width: 6rem;
  height: 0.68rem;
  background-color: #fff;
  border-radius: 0.34rem;
  /* position: ; */
  /* bottom: 0.3rem;
  left: 0.34rem; */
  display: flex;
  margin-top: 0.3rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;

}
.fhimg{

    font-size: 0.4rem;
  margin-top: 0.3rem;
  margin-left: 0.2rem;
  

}
.sstext{
    font-size: 00.3rem;
    line-height: 1.28rem;
}
.ppbox{
    display: flex;
    font-size: 0.26rem;
    line-height: 0.68rem;
    margin-left: 0.2rem;
    border-right: 0.01rem solid #E4E6F1;
    padding-right: 0.15rem;
}
.ss{
    width: 0;
    height: 0;
    border: 0.05rem solid transparent;;
    border-top: 0.1rem solid rgb(0, 0, 0);
    margin-top: 0.28rem;
    margin-left: 0.15rem;
}
.input{
    border: 0;
    outline: none;
    margin-left: 0.2rem;
}
</style>