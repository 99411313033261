<template>
    <div>
    <div class="heacon">
       <div class="content">
        <div class="conxx">
          <div class="contu"></div>
            <div>
            <div class="conname">到底</div>
            <div class="conpos">主管</div>
            </div>
          </div>
          <div class="concom">位加数字科技(天津)有限公司</div>
       </div>
    </div>
    <div class="mkbox">
      <div class="zxhbbox">
        <div class="zixun">
          <div class="zixtit">资讯</div>
          <div class="zixuntext"> <div>头条新闻早知道</div>  <img class="g1img" src="../assets/imgs/g1.png" alt=""> </div>
        </div>
        <div class="zixun haibao">
          <div class="zixtit">海报</div>
          <div class="zixuntext"> <div>头条新闻早知道</div>  <img class="g1img" src="../assets/imgs/g2.png" alt=""> </div>
        </div>
      </div>
      <div class="gnmkbox">
        <div class="tabgn">
          <img class="g3img" src="../assets/imgs/g3.png" alt="">
          <div class="textgn">数据看板</div>
        </div>
        <div class="tabgn">
          <img class="g3img" src="../assets/imgs/g4.png" alt="">
          <div class="textgn">递名片</div>
        </div>
        <div class="tabgn">
          <img class="g3img" src="../assets/imgs/g5.png" alt="">
          <div class="textgn">宣传册</div>
        </div>
        <div class="tabgn">
          <img class="g3img" src="../assets/imgs/g6.png" alt="">
          <div class="textgn">制作文章</div>
        </div>
        <div class="tabgn">
          <img class="g3img" src="../assets/imgs/g7.png" alt="">
          <div class="textgn">制作海报</div>
        </div>
        <div class="tabgn">
          <img class="g3img" src="../assets/imgs/g8.png" alt="">
          <div class="textgn">制作视频</div>
        </div>
        <div class="tabgn">
          <img class="g3img" src="../assets/imgs/g9.png" alt="">
          <div class="textgn">使用帮助</div>
        </div>


      </div>
    </div>
        <!-- 我的文章 -->
    <div class="titbox">
      <div class="tittext">我的文章</div>
      <div class="titgd">更多</div>
    </div>
    <div class="wenzhbox">
      <div class="wenlist">
        <div class="wlist1">
          <img class="g10img" src="../assets/imgs/g10.png" alt="">
          <div class="wemtit">4月25日早报丨北京 朝阳区进行常态化… </div>
        </div>
        <div class="wlist2">
          <div class="wlnum">分享3丨浏览2 </div>
          <div class="wfxbut">分享</div>
        </div>
      </div>
      <div class="wenlist">
        <div class="wlist1">
          <img class="g10img" src="../assets/imgs/g10.png" alt="">
          <div class="wemtit">4月25日早报丨北京 朝阳区进行常态化… </div>
        </div>
        <div class="wlist2">
          <div class="wlnum">分享3丨浏览2 </div>
          <div class="wfxbut">分享</div>
        </div>
      </div>
      <div class="wenlist">
        <div class="wlist1">
          <img class="g10img" src="../assets/imgs/g10.png" alt="">
          <div class="wemtit">4月25日早报丨北京 朝阳区进行常态化… </div>
        </div>
        <div class="wlist2">
          <div class="wlnum">分享3丨浏览2 </div>
          <div class="wfxbut">分享</div>
        </div>
      </div>
      <div class="wenlist">
        <div class="wlist1">
          <img class="g10img" src="../assets/imgs/g10.png" alt="">
          <div class="wemtit">4月25日早报丨北京 朝阳区进行常态化… </div>
        </div>
        <div class="wlist2">
          <div class="wlnum">分享3丨浏览2 </div>
          <div class="wfxbut">分享</div>
        </div>
      </div>
    </div>
     <!-- 我的海报-->
    <div class="titbox">
      <div class="tittext">我的海报</div>
      <div class="titgd">更多</div>
    </div>
    <div class="haibaobox" >
      <img class="hbimg" src="../assets/imgs/g10.png" alt="">
      <img class="hbimg" src="../assets/imgs/g10.png" alt="">
      <img class="hbimg" src="../assets/imgs/g10.png" alt="">
      <img class="hbimg" src="../assets/imgs/g10.png" alt="">
      <img class="hbimg" src="../assets/imgs/g10.png" alt="">
      <img class="hbimg" src="../assets/imgs/g10.png" alt="">
    </div>
      <!-- 我的宣传册 -->
    <div class="titbox">
      <div class="tittext">我的宣传册</div>
      <div class="titgd">更多</div>
    </div>
    <div class="xccbox">
      <div class="xcclist">
        <img class="xccimg" src="../assets/imgs/g10.png" alt="">
        <div>
          <div class="cxcctit">位加数字数字化营销多维的多角度的 及企业赋能全员的解决方案</div>
          <div class="xccnum">分享3丨浏览2</div>
          <div class="xccfx">
            <div></div>
            <div class="wfxbut xccfxbut">分享</div>
          </div>
        

        </div>
      </div>
    </div>

        <div class="loginbut">登录查看</div>
    <!-- 底部导航 -->
    <div class="tabBar">
      <div class="tab">
        <router-link to="/">
          <img class="t1img" src="../assets/imgs/t11.png" alt="">
          <div class="tabtext">首页</div>
        </router-link>
      </div>
      <div class="tab">
        <router-link to="/workbench">
          <img class="t1img" src="../assets/imgs/t33.png" alt="">
          <div class="tabtext">工作台</div>
       </router-link>
      </div>
      <div class="tab">
        <router-link to="/radar">
          <img class="t1img" src="../assets/imgs/t2.png" alt="">
          <div class="tabtext">雷达</div>
        </router-link>
      </div>
      <div class="tab">
        <router-link to="/mine">
          <img class="t1img" src="../assets/imgs/t4.png" alt="">
          <div class="tabtext">我的</div>
        </router-link>
      </div>

    </div>
    </div>
</template>
<style scoped>
.tabBar{
  width: 100%;
  height: 1rem;
  background: #FFFFFF;
  /* box-shadow: 0rem -0.01rem 0.1rem 0rem #EAEAEA; */
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.5rem;
}

.tab{
  text-align: center;
  /* background-color: #814220; */
  position: relative;
  width: 0.8rem;
}
.t1img{
  width: 0.38rem;
  height: 0.38rem;
  position: absolute;
  top: 0.15rem;
  left: 50%;
  transform: translate(-50%);
}
.tabtext{
  font-size: 0.18rem;
  margin-top: 0.6rem;

}
.loginbut{
    width: 2.5rem;
    text-align: center;
    font-size: 0.2rem;
    line-height: 0.6rem;
    background-color: #FECB32;
    border-radius: 0.5rem;
    margin: 3rem auto;
}
.heacon{
  background-color: #FECB32;
  height: 3.20rem;
}
.mkbox{
  width: 6.9rem;
  height: 5.42rem;
  background-color: #FFFFFF;
  margin: 0 auto;
  margin-top: -0.8rem;
  border-radius: 0.2rem;
  padding: 0.3rem;
  box-sizing: border-box;
}
.content{
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
  padding-top: 0.8rem;

}
.conxx{
  display: flex;
}
.contu{
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #464542;
  margin-right: 0.2rem;

}
.conname{
  font-size: 0.3rem;
  font-weight: bold;
  margin-top: 0.2rem;
}
.conpos{
  font-size: 0.2rem;
  /* font-weight: bold; */
  color: rgb(100, 100, 100);
}
.concom{
  font-size: 00.28rem;
  margin-top: 0.3rem;
}
.zxhbbox{
  display: flex;
  justify-content: space-between;
}
.zixun{
  width: 3rem;
  height: 1.6rem;
  border-radius: 0.2rem;
  background-color: #FFF7F4;
  padding: 0.25rem;
  box-sizing: border-box;
}
.haibao{
  background-color: #FEFAF2;
}
.zixtit{
  font-size: 0.28rem;
  font-weight: bold;
}
.zixuntext{
  font-size: 0.2rem;
  color: #464542;
  display: flex;
  margin-top: 0.1rem;
}
.g1img{
  width: 0.7rem;
  height: 0.67rem;
  margin-left: 0.12rem;
}
.gnmkbox{
  display: flex;
  flex-wrap: wrap;
  /* justify-content: ; */
}
.g3img{
  width: 0.50rem;
  height: 0.51rem;
}
.textgn{
  font-size: 0.24rem;
  margin-top: -0.1rem;
}
.tabgn{
  /* background: #FECB32; */
  text-align: center;
  /* margin: 0 0.36rem; */
  width: 1.52rem;
  /* border: 0.01rem solid red; */
}
.titbox{
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0.3rem;
}
.tittext{
font-size: 0.3rem;
font-weight: bold;
color: #222222;
}
.titgd{
font-size: 0.22rem;
color: #929294;
}
.wenzhbox{
  padding: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}
.wenlist{
  padding: 0.21rem;
  background-color: #FFFFFF;
  width: 3.35rem;
  border-radius: 00.1rem;
  box-sizing: border-box;
  margin-bottom: 0.2rem;
}
.wlist1{
  display: flex;

}
.g10img{
  width: 00.7rem;
  height: 00.7rem;
  margin-right: 0.1rem;
}
.wemtit{
  width: 2.14rem;
  font-size: 0.24rem;
}
.wlist2{
  display: flex;
  justify-content: space-between;
  margin-top: 0.1rem;
}
.wlnum{
  font-size: 00.22rem;
  margin-top: 0.1rem;
}
.wfxbut{
  width: 0.88rem;
  height: 0.42rem;
  font-size: 0.24rem;
  border-radius: 00.1rem;
  background-color: #FECB32;
  text-align: center;
  line-height: 00.42rem;
  margin-top: 0.08rem;
}
.haibaobox{
  display: flex;
  overflow-x: scroll;
  padding-left: 0.3rem;
  /* border: 00.01rem solid red; */
  height: 3.37rem;
}
.hbimg{
  width: 1.88rem;
  height: 3.34rem;
  border-radius: 0.1rem;
  margin-right: 0.2rem;
}
.xccbox{
  padding: 0.15rem 0.3rem;
}
.xcclist{
  background-color: #FFFFFF;
  display: flex;
  border-radius: 0.1rem;
  padding: 0.3rem;
}
.xccimg{
  width: 1.6rem;
  height: 2.2rem;
  margin-right: 0.3rem;
}
.cxcctit{
  font-size: 00.28rem;
}
.xccnum{
  font-size: 0.22rem;
  margin-top: 0.3rem;
}
.xccfx{
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;
}
.xccfxbut{
  width: 1.18rem;
  height: 0.46rem;
  line-height: 0.46rem;
}
</style>