<style scoped>
#page{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    z-index: -1;
}
.gxtit{
  display: flex;

  font-size: 0.4rem;
  padding: 0.3rem;
  font-weight: bold;
}
.bcbox{
    width: 7rem;
    height: 2rem;
    margin: 0 auto;
    background-color: #fff;
    padding: 0.35rem;
    box-sizing: border-box;
    border-radius: 0.1rem;

}
.bctop{
    display: flex;
}
.bcimg{
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
}
.bcname{
    font-size: 0.3rem;
    font-weight: bold;
    margin-top: 0.12rem;
    margin-left: 0.15rem;

    
}
.bcarea{
    font-size: 0.26rem;
    color: #8C8F9F;
    margin-top: 0.13rem;
    margin-left: 0.15rem;


}
.fabutixt{
    font-size: 0.28rem;
    color: #8C8F9F;

}
.fabu{
    font-size: 0.28rem;
    margin-right: 0.3rem;
}
.hangye{
    padding: 0.08rem 0.18rem;
    font-size: 0.24rem;
    background: #f5f5f5;
    margin-top: -0.03rem;

}
.bcbottom{
    margin-top: 0.2rem;
}
.supplytext{
    font-size: 0.3rem;
    padding: 0.3rem;

}
.supplyimgbox{
    width: 6.9rem;
    margin: 0 auto;
}
.supplyimg{
    width: 100%;
}
.tuijian{
    padding: 0.3rem;
    font-size: 0.32rem;
    font-weight: bold;
}
.tuijanlist{
    width: 7rem;
    height: 3.15rem;
    margin: 0 auto;
    background-color: #fff;
    padding: 0.25rem;
    box-sizing: border-box; 
    border-radius: 0.1rem;
    position: relative;
    margin-top: 0.35rem;
}
.supplytop{
    display: flex;
    justify-content: space-between;
}
.sutext{
    font-size: 0.32rem;
    font-weight: bold;
	width: 4.5rem;
	overflow:hidden; 
    text-overflow:ellipsis; 
    white-space: normal; 
    display:-webkit-box;  
    -webkit-box-orient:vertical; 
    -webkit-line-clamp:2;


}
.suimg{
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.1rem;
}
.subcimg{
    width: 0.64rem;
    height: 0.64rem;
    border-radius: 50%;
    margin-right: 0.1rem;
}
.sutime{
    font-size: 0.22rem;
    line-height: 0.7rem;
    color: #8C8F9F;
    display: flex;
}
.subcname{
    font-size: 0.26rem;
    line-height: 0.7rem;
    
}
.subcarae{
    font-size: 0.22rem;
    line-height: 0.7rem;
    color: #8C8F9F;
    margin-left: 0.05rem;
}
.suppttop{
    display: flex;
}
.supplybb{
    margin-top: 0.15rem;
    border-top: 0.01rem solid #f6f6f6;
    padding-top: 0.15rem;

}
.hangyebox{
    display: flex;
    margin-top: 0.2rem;
}
.xzAppbox{
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.3rem 0;
}
.xApp{
    width: 6.9rem;
    line-height: 0.7rem;
    color: #fff;
    margin: 0 auto;
    background-color:#ffcc42 ;
    font-size: 0.3rem;
    text-align: center;
    border-radius: 0.5rem;
}
.bqimgbox{
    width: 0.84rem;
    height: 0.34rem;
    background: #ffcc42;
}
.shijian{
    /* width: 1.32rem; */
    overflow: hidden;
    white-space: nowrap;
}
.fabu{
    /* width: 1.64rem; */
    overflow: hidden;
    white-space: nowrap;
}
.gyimgbox{
    width: 0.84rem;
    height: 0.34rem;
    position: absolute;
    left: 0.2rem;
    top: 0.4rem;
    color: #fff;
    font-size: 0.22rem;
    text-align: center;
    line-height: 0.34rem;
}
.gyimg{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.gyimg1{
    z-index: -1;
}
.gyimgbox1{
    width: 1rem;
    z-index: 2;
    top: -0.17rem;
    left: 0;
}
</style>
<template>
    <div id="page"></div>
    <div class="gxtit">
         <div class="gyimgbox" v-if="detailarr.type==1">供应<img class="gyimg" src="../assets/imgs/gy.png" alt=""></div>
         <div class="gyimgbox" v-if="detailarr.type==2">需求<img class="gyimg" src="../assets/imgs/xq.png" alt=""></div>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{detailarr.name}}</div>
    <div class="bcbox">
        <div class="bctop"> 
            <img class="bcimg" :src="detailarr.bc_avatar_url" alt="">  
            <div class="bcname">{{detailarr.bc_name}}</div>
            <div class="bcarea">丨{{detailarr.area_info}}</div>
        </div>
        <div class="bctop bcbottom">
            <div class="fabutixt">发布时间：</div>
            <div class="fabu">{{detailarr.create_time1}}</div>
            <div class="fabutixt">行业属性：</div>
            <div class="hangye">{{detailarr.corp_class_name}}</div>
        </div>
    </div>
    <div class="supplytext">{{detailarr.remark}}</div>
    <div class="supplyimgbox" v-for="(item,index) in detailarr.images" :key="index">
        <img   class="supplyimg" :src="item" alt="">
    </div>
    <div class="tuijian" v-if="recommend_list.length!==0">为你推荐</div>
    <div class="tuijanlist"  v-for="(item,index) in detailarr.recommend_list" :key="index">
         <router-link :to="'/Supplydetail?id='+item.demand_id">
         <div  v-if="item.type==1" class="gyimgbox gyimgbox1">供应<img class="gyimg gyimg1" src="../assets/imgs/gy.png" alt=""></div>
         <div  v-if="item.type==2" class="gyimgbox gyimgbox1">需求<img class="gyimg gyimg1" src="../assets/imgs/xq.png" alt=""></div>
        <div class="supplytop">
            <div class="sutextbox">
                <div class="sutext">{{item.name}}</div>
                <div class="hangyebox">
                    <div class="hangye">{{item.corp_class_name}}</div>
                </div>
                
            </div>
            <img class="suimg" :src="item.avatar" alt="">
        </div>
        <div class="supplytop supplybb">
            <div class="suppttop">
                <img class="subcimg" :src="item.bc_avatar_url" alt="">
                <div class="subcname">{{item.bc_name}}</div>
                <div class="subcarae">{{item.area_info}}</div>
            </div>
            <div>
                <div class=sutime> <div class="shijian">{{item.create_time1}} </div> 丨 {{item.look_times}}次浏览</div>
            </div>
        </div>
        </router-link>
    </div>
    <div class="xzAppbox">
        <div class="xApp" @click="download()">打开爱家帝App,查看联系方式</div>
    </div>
    <div style="height:1.5rem"></div>
</template>

<script>

import axios from 'axios'

export default {
  data() {
    return {
        id:'',
        detailarr:'',
        recommend_list:[]

    };
  },

  created() {
     this.id=this.$route.query.id
     console.log(this.id)

  },
  mounted() {
    this.getAllArr()
  },


  methods: {
    shearConfig(){
     let formData = new FormData()
      formData.append("url",window.location.href);
      axios({
         method: "post",
         url: "https://ajdh5.vjiashuzi.com/GetWxConfig.php",
        data: formData,
         
       }).then(
         response => {
          //  console.log("返回信息", response.data);
    
            this.$wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
            appId: 'wxfbd6f16c8b572b3d', // 必填，公众号的唯一标识
            timestamp:response.data.timestamp , // 必填，生成签名的时间戳
            nonceStr:response.data.noncestr, // 必填，生成签名的随机串
            signature:response.data.signature,// 必填，签名
            jsApiList: ['updateAppMessageShareData','updateTimelineShareData'], // 必填，需要使用的 JS 接口列表
           })
            this.$wx.checkJsApi({
              jsApiList: ['chooseImage', 'updateAppMessageShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: function(res) {
                  console.log(res, 'checkJsApi')
              }
           })        
           this.$wx.ready(()=>{
            this.$wx.updateTimelineShareData({//配置去分享朋友圈的选项
               title :this.detailarr.name,//分享标题
               link : window.location.href, //分享链接
               imgUrl : this.detailarr.avatar, //分享图标
               success : function(res){//分享成功 
                 console.log("已分享朋友圈成功")
                 //如分享成功后想做一些其他的业务处理，可以在这里做
               },
               cancel:function(){
                 //that.alert="失败弹框话术等等(注意 这里拿不到this实例的)"
                 console.log("已分享失败")
               }
             })
           });
            this.$wx.updateAppMessageShareData({//配置去分享好友的选项
                title:this.detailarr.name,  
                link: window.location.href,    		
                desc:this.detailarr.remark,             
                imgUrl :this.detailarr.avatar,                            
                success : function(res){
                  console.log("分享好友成功")
                },
                cancel:function(res){
                  console.log("分享失败") 
                }
            });
         },
         (error) => {
           ElMessage.error("注册请求失败");
          
         },
        //  console.log(this.marketJoinin.company_address+this.marketJoinin.company_address_detail)
       );


    },
   download(){
    window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.lpy.djd';

    },
    getarr(){
      let formData = new FormData()
      formData.append("demand_id","1");
      axios({
         method: "post",
         url: "https://djdwap.vjiashuzi.com/w1/demand/get-demand-h5-detail",
        data: formData,
                 headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
       }).then(
         response => {
           console.log(response);
         },
         (error) => {
           console.log(err);

          
         }
       );
    },
    getAllArr(){
      let formData = new FormData()
      formData.append("demand_id",this.id);
      this.$http({
        method: "post",
        url: "/v1/demand/get-demand-h5-detail",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
            console.log(res.data.data)
            this.detailarr=res.data.data
            this.recommend_list=res.data.data.recommend_list
            this.shearConfig()

        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },

},
 beforeRouteUpdate(to,form,next){
      this.id = to.query.id
    this.getAllArr()
   document.documentElement.scrollTop = 0;
      console.log(this.id)
          next()
    }
};


</script>
