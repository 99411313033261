<template>

<div v-if="showtcijd" class="tanchuang"> <div class="doc_content" v-html="doc_content"></div></div>
<img v-if="showtcijd" @click="guanyu1" class="h23img" src="../assets/imgs/h23.png" alt="">
<div v-if="showtcijd" class="black"></div>
<meta name="format-detection" content="telephone=yes"/>

<div class="page">
  <img v-if="position_1.pic_img!=''" @click="See(position_1.pic_url)" class="adimg" :src="position_1.pic_img" alt="">
  
    <div>
      <div class="heabox admr">

  
  <div class="block" style="width: 100%;">
    <!-- <span class="demonstration">.</span> -->
    
    <el-carousel height="5.2rem" indicator-position="none">
      <el-carousel-item v-for="(item,index) in expo_avatar_banner1" :key="item">
               <div class="numboxindex">{{index+1}}/{{expo_avatar_banner1.length}}</div>

               <img class="heaimg" v-lazy="item" :alt="require('../assets/imgs/zw.png')">
      </el-carousel-item>
    </el-carousel>
  </div>
        <!-- <img class="heaimg" v-lazy="marketdetail.expo_avatar" :alt="require('../assets/imgs/zw.png')"> -->
        
        <div class="timebox1" v-if="type=='2'"></div>
        <div class="timebox"  v-if="type=='2'">
          <div class="numbox">{{day}}</div>天
          <div class="numbox">{{hours}}</div>:
          <div class="numbox">{{minute}}</div>:
          <div class="numbox">{{second}}</div>后开展
        </div>
      </div>

    <div class="headbox">
        <div class="headtit">{{marketdetail.expo_name}}</div>
        <div class="hsjbox">
        <div class="headtime" v-if="showundf"> <img class="ttimg" src="../assets/imgs/time.png" alt="">{{marketdetail.expo_start_time+'至'+marketdetail.expo_stop_time}}</div>
          <div></div>

            <div class="dhbox">
                <div class="dhbox1" @click="callphone">
                  <img class="dhimg" src="../assets/imgs/dh.png" alt="">
                  <div class="headjuli dianhua">电话</div>  
                </div>
            </div>
        </div> 
    </div>
            <div class="conbox">
            <div class="context">
                <div>容纳人数</div>
                <div> {{marketdetail.expo_audience_mun}}</div>
            </div> 
            <div class="xian"></div>          
            <div class="context">
                <div>参展商</div>
                <div> {{marketdetail.ExpoCorpCount}}</div>
            </div>
            <div class="xian"></div>
            <div class="context">
                <div>展览面积</div>
                <div> {{marketdetail.expo_area_num}}㎡</div>
            </div>
        </div>
    </div>
            <div>
              <div class="dizhibox">
                <div class="headtit headtit2"><img class="ttimg1"  src="../assets/imgs/side.png" alt="">{{marketdetail.expo_address}}</div>
                <div class="headside">{{marketdetail.expo_address_detail}}</div>
              <router-link :to="'/Map?lng='+marketdetail.expo_lng+'&lat='+marketdetail.expo_lat">
                 <div class="daohang"> <img class="dht" src="../assets/imgs/dht.png" alt=""> <div>查看位置</div></div>
             </router-link>

                 <div class="headjuli">{{marketdetail.expo_map_remark}}</div>
              
                  <div class="demo-image__preview">

                    <el-image v-for="(item,index) in expo_map_avatar"  style="width: 100%; height: 4.5rem; margin-top: 0.2rem;"  :src="item" :preview-src-list="expo_map_avatar"></el-image>
                  </div>
              </div>
             
            </div>  
    <div class="qingq" @click="guanyu">关于爱家帝>>></div>

    <div class="jiben" v-if="expo_introduce!==''">
      <div class="tittle">展会介绍</div>
      <div>
        <div :class="{ 'xinxibox':true}" :style="{'height': hei}" v-html="expo_introduce"></div>
        <div class="zhanshou" @click="zhanshouu">{{zhanshou}}</div>
      </div>
    </div>
  
  <div v-if="position_2.pic_img!=''" @click="See(position_2.pic_url)"><img class="adimg" :src="position_2.pic_img" alt=""></div>
  <div v-if="position_3.pic_img!=''" @click="See(position_3.pic_url)" class="admr"><img class="adimg" :src="position_3.pic_img" alt=""></div>
  <div v-if="position_4.pic_img!=''" @click="See(position_4.pic_url)" class="admr"><img class="adimg" :src="position_4.pic_img" alt=""></div>
  

    <div class="jiben" v-if="expo_range!==''">
      <div class="tittle">展会范围</div>
      <div>
       <div class="xinxibox" :style="{'height': hei1}" v-html="expo_range"></div>
       <div class="zhanshou" @click="zhanshouu1">{{zhanshou1}}</div>
      </div>
    </div>
    <div class="jiben" v-if="position_5!=''">
      <div class="tittle">优选联展</div>
      <div>
       <div class="adimgbox">
        <div v-for="(item,index) in position_5" :key="index"><img @click="See(item.pic_url)" class="adimg5" :src="item.pic_img" alt=""> </div>
       </div>
       <!-- <div class="zhanshou" @click="zhanshouu1">{{zhanshou1}}</div> -->
      </div>
    </div>
                <!--  v-if="expoArea.length!==0" -->
    <div class="zhanshang" v-if="expoArea.length!==0">
    <div class="tittle">数字展厅</div>
 

    <div class="clbox">
      <div class="classbox">
        <div class="classitem " :style="{'background': (index==marketindex?'#FECB32':'')}"  @click="showtc(index,item)" v-for="(item,index) in expoArea" :key="index" >{{item.area_name}} <div>{{item.areaCount}}</div> </div>
      </div>
      <div class="classitem gengduo">
      <router-link :to="'/Exhibilist?id='+id">
       <div>更多</div>
      </router-link>
      </div>
    </div>
       <div class="adimgbox" v-if="position_6!=''">
        <div v-for="(item,index) in position_6" :key="index"> <img  @click="See(item.pic_url)" class="adimg5" :src="item.pic_img" alt=""> </div>
       </div>
    <div class="shopbox">
        <div class="shopli" v-for="(item,index) in expoCorp" :key="index">
           <router-link :to="'/Exhibiduce?id='+item.corp_id">
           <img class="shopimg" v-lazy="item.corp_pic" :alt="require('../assets/imgs/zw.png')">
            <div class="shoptit" :style="{'background': (item.bc_id!==null?'#FECB32':'')}">{{item.corp_name}}</div>   
            <img class="s11img" src="../assets/imgs/s11.png" alt="" v-if="item.bc_id!==null">
          </router-link>
        </div>
    </div>
    </div>


</div>
<div style="height:1rem"></div>
    <div class="xiazaiapp" @click="download()">
      <img class="ijdimg" src="../assets/imgs/ijd.png" alt="">
      <div class="ijdbut">下载app了解更多</div>
    </div>
      <!-- <el-button v-show="false"
    type="primary"
    @click="openFullScreen1"
    v-loading.fullscreen.lock="fullscreenLoading">
    指令方式
  </el-button> -->
 
</template>
<script>
import axios from 'axios'
import ElementPlus from 'element-plus';
import { Loading } from 'element-plus/es/components/loading/src/service'
import 'element-plus/theme-chalk/index.css'

// import wx from 'weixin-js-sdk';
  // import Viewer from "@/assets/js/viewer.min.js";
  // import '';


export default {
  data() {
    return {
      id:'',
      marketdetail:[],
      content:'',
      mclist:[],
      market:[],
      market_mark:'',
      marketindex:'0',
      mc_corplist:[],
      url: "https://djdshop.vjiashuzi.com",
      marketMactivity:[],
      marketJoinin:{},
      market_select:'',
      market_good:'',
      m_tags:[],
      showpptc:false,//品牌弹窗
      messagename:'',
      messagephone:'',
      zhanshou:'展开↓',
      zhanshou1:'展开↓',
      zhanshou2:'展开↓',
      xinxibox:'',
      hei:'1rem',
      hei2:'1rem',
      hei2:'1rem',
      hei1:'1rem',
      getLongitude:'',
      getLatitude:'',
      lng:"117.20809275",
      lat:'39.0911026',
      scrollTop: '',  /*保存当前滚动条*/
      h5Map:'',
      expo_introduce:'',
      expo_range:'',
      expoArea:'',
      expo_area_id:'',
      expoCorp:[],
      oldsecond:'',
      oldsecond1:'',
      day:"",
      hours:"",
      minute:"",
      second:"",
      type:'',
      ifexpoCorp:'',
      position_1:'',
      position_2:'',
      position_3:'',
      position_4:'',
      position_5:'',
      expo_avatar_banner:'',
           type:'',
      showtcijd:false,
      doc_content:'',
      expo_avatar_banner1:[],
      fullscreenLoading: false,
      showundf: false
      
      

    };
  },

  created() {
    // console.log("ididididid",this.$route.query.id)
    this.id=this.$route.query.id
    console.log(this.id)
  },
  mounted() {
    this.getijdjs();
    // this.openFullScreen1();

    this.getAllArr();
    this.getLongitudeLatitude();
    this.getadimg();
  },
  methods: {
    openFullScreen1() {
      this.fullscreenLoading = true;
      // setTimeout(() => {

      // }, 2000);
      },
        getijdjs(){
      let formData = new FormData()
      // formData.append("corp_id", this.id);

      this.$http({
        method: "post",
        url: "v1/system/about-us",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.doc_content=res.data.data.doc_content
                    var content=this.doc_content
            content = content.replace(/\<img/gi, '<img style="width: 100%;height: 100%;"')
          this.doc_content=content
          // console.log(content)

        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
        // 关于ijd
    guanyu(){
      this.showtcijd=true
    },
    guanyu1(){
      this.showtcijd=false
    },
     download(){
    window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.lpy.djd';
    // if(this.type == 'android') {
    //   console.log('android')
    // window.location.href = 'https://admin.vjiashuzi.com/app/ajd/ajd.apk';
    // } else {
    //   console.log('ios')
    // window.location.href = 'https://apps.apple.com/cn/app/%E7%88%B1%E5%AE%B6%E5%B8%9D/id1638856394?mt=8';
    
    // }
    },
   See (e) {
        console.log(e)
        if (e!==null) {
          if(e!==''){
            indow.location.href = e
          }
            w
        }
   },
    
  countdown(){
    // console.log(this.oldsecond)
    if (this.oldsecond>=0) {
      var day =Math.floor(this.oldsecond / 86400);
      var hours = Math.floor((this.oldsecond % 86400) / 3600);
      var minute = Math.floor(((this.oldsecond % 86400) % 3600) / 60);
      var second = Math.floor(((this.oldsecond % 86400) % 3600) % 60);
      this.day=day
      this.hours=hours
      this.minute=minute
      this.second=second
      this.oldsecond=this.oldsecond-1
      // console.log(day);
      if (day==0 && hours==0 && minute==0 && second==0) {
        this.getAllArr()
      }else{
        setTimeout(this.countdown, 1000);
      }
    }
},
    
    getadimg(){
      console.log("ididididid",this.expo_area_id)
      let formData = new FormData()
      formData.append("expo_id",this.id);

      this.$http({
        method: "post",
        url: "/v1/web-adv/get-adv-code",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.position_1=res.data.data.position_1
          this.position_2=res.data.data.position_2
          this.position_3=res.data.data.position_3
          this.position_4=res.data.data.position_4
          this.position_5=res.data.data.position_5
          this.position_6=res.data.data.position_6
        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗
    },

    shearConfig(){
     let formData = new FormData()
      formData.append("url",window.location.href);
      axios({
         method: "post",
         url: "https://ajdh5.vjiashuzi.com/GetWxConfig.php",
        data: formData,
         
       }).then(
         response => {
          //  console.log("返回信息", response.data);
    
            this.$wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
            appId: 'wxfbd6f16c8b572b3d', // 必填，公众号的唯一标识
            timestamp:response.data.timestamp , // 必填，生成签名的时间戳
            nonceStr:response.data.noncestr, // 必填，生成签名的随机串
            signature:response.data.signature,// 必填，签名
            jsApiList: ['updateAppMessageShareData','updateTimelineShareData'], // 必填，需要使用的 JS 接口列表
           })
            this.$wx.checkJsApi({
              jsApiList: ['chooseImage', 'updateAppMessageShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: function(res) {
                  // 以键值对的形式返回，可用的api值true，不可用为false
                  // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                  console.log(res, 'checkJsApi')
              }
           })        
           this.$wx.ready(()=>{
            this.$wx.updateTimelineShareData({//配置去分享朋友圈的选项
               title :this.marketdetail.expo_name,//分享标题
               link : window.location.href, //分享链接
               imgUrl : this.marketdetail.expo_avatar, //分享图标
               success : function(res){//分享成功 
                 console.log("已分享朋友圈成功")
                 //如分享成功后想做一些其他的业务处理，可以在这里做
               },
               cancel:function(){
                 //that.alert="失败弹框话术等等(注意 这里拿不到this实例的)"
                 console.log("已分享失败")
               }
             })
           });
            this.$wx.updateAppMessageShareData({//配置去分享好友的选项
                title:this.marketdetail.expo_name,  
                link: window.location.href,    		
                desc:this.marketdetail.expo_address_detail,             
                imgUrl :this.marketdetail.expo_avatar,                            
                success : function(res){
                  console.log("分享好友成功")
                },
                cancel:function(res){
                  console.log("分享失败") 
                }
            });
         },
         (error) => {
           ElMessage.error("注册请求失败");
          
         },
        //  console.log(this.marketJoinin.company_address+this.marketJoinin.company_address_detail)
       );


    },

    handleScroll(){
        //或者使用document.querySelector('.class或者#id').scrollTop
        this.scroll = document.documentElement.scrollTop||document.body.scrollTop
        // console.log(this.scroll)
    },
   getLongitudeLatitude() {
    // let diff=1000;//这里是要滚动到的位置高度
    //   var container = this.$refs.box
      // this.$refs.box.offsetTop = 0;
    // document.documentElement.scrollTop = 100000
    // console.log( document.documentElement.scrollTop)
  
      var _this = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          //locationSuccess 获取成功的话
          function (position) {
            _this.getLongitude = position.coords.longitude;
            _this.getLatitude = position.coords.latitude;
            // alert(_this.getLongitude,)
            // alert( _this.getLatitude)
            this.lng=_this.getLongitude
            this.lat=_this.getLatitude
            this.getAllArr()

          },
          //locationError  获取失败的话
          function (error) {
            var errorType = [
              "您拒绝共享位置信息",
              "获取不到位置信息",
              "获取位置信息超时",
            ];
            console.log(errorType[error.code - 1]);
          }
        );
      }
    },

    getAllArr(){
      console.log("ididididid",this.expo_area_id)
      let formData = new FormData()
      formData.append("expo_id", this.id);
      formData.append("expo_area_id", this.expo_area_id);
      formData.append("lng",this.lng);
      formData.append("lat",this.lat);
      this.$http({
        method: "post",
        url: "/v1/app-expo/get-expo-detail",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          this.expo_avatar_banner1=[]
          if(res.data.data.expoArea.length!==0){
            this.expo_area_id=res.data.data.expoArea[0].area_id
            console.log(this.ifexpoCorp)
             if(this.ifexpoCorp!=='1'){
              this.getAllArr1()
             }
            
          }
          console.log(res.data);
          this.marketdetail=res.data.data
          // this.fullscreenLoading = false;
          this.showundf = true;

          this.expo_introduce=res.data.data.new_expo_introduce
            var content1=this.expo_introduce
            content1 = content1.replace(/\<img/gi, '<img style="width: 100%;height: 100%;"')
            this.expo_introduce=content1
          this.expo_range=res.data.data.new_expo_range
            var content2=this.expo_range
            content2 = content2.replace(/\<img/gi, '<img style="width: 100%;height: 100%;"')
            this.expo_range=content2
          this.expoArea=res.data.data.expoArea
          this.expoCorp=res.data.data.expoCorp
          this.expo_avatar_banner=res.data.data.expo_avatar_banner
          for (var i=0;i<this.expo_avatar_banner.length;i++){ 
          //  console.log(this.expo_avatar_banner[i]);
           if(this.expo_avatar_banner[i]!==''){
              this.expo_avatar_banner1.push(this.expo_avatar_banner[i])
           }
              
          }
           console.log(this.expo_avatar_banner1)

          console.log(this.expo_avatar_banner)
          this.oldsecond1=res.data.data.time_type.time
          this.type=res.data.data.time_type.type
          document.title = this.marketdetail.expo_name
          this.expo_map_avatar=res.data.data.expo_map_avatar
          if(res.data.data.expo_map_avatar!==''){
          this.expo_map_avatar=res.data.data.expo_map_avatar
            this.expo_map_avatar=this.expo_map_avatar.split(",")
            // console.log(this.expo_map_avatar)
          }

          var d=new Date(this.marketdetail.expo_start_time)
          var t=Date.parse(d);
          this.oldsecond=(t-this.oldsecond1*1000)/1000


          

          this.shearConfig();
          this.countdown();



        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    getAllArr1(){
      console.log("idididididgetAllArr1",this.expo_area_id)

      let formData = new FormData()
      formData.append("expo_id", this.id);
      formData.append("expo_area_id", this.expo_area_id);
      formData.append("lng",this.lng);
      formData.append("lat",this.lat);
      this.$http({
        method: "post",
        url: "/v1/app-expo/get-expo-detail",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          this.expo_avatar_banner1=[]
          if (res.data.data.expoArea.length!==0) {
            // console.log(res.data);
          this.marketdetail=res.data.data
          this.expo_introduce=res.data.data.new_expo_introduce
          this.expo_range=res.data.data.new_expo_range
          this.expoArea=res.data.data.expoArea
              this.expo_avatar_banner=res.data.data.expo_avatar_banner
          for (var i=0;i<this.expo_avatar_banner.length;i++){ 
          //  console.log(this.expo_avatar_banner[i]);
           if(this.expo_avatar_banner[i]!==''){
              this.expo_avatar_banner1.push(this.expo_avatar_banner[i])
           }
              
          }
          this.oldsecond1=res.data.data.time_type.time
          this.type=res.data.data.time_type.type
          this.expoCorp=res.data.data.expoCorp
          document.title = this.marketdetail.expo_name

          var d=new Date(this.marketdetail.expo_start_time)
          var t=Date.parse(d);
          this.oldsecond=(t-this.oldsecond1*1000)/1000
          this.shearConfig();
          this.countdown();
          }
        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    getCustomerMarket() {
      console.log(this.messagephone)
      console.log(this.messagename)
      let formData = new FormData()
      formData.append("market_id", this.id);
      formData.append("phone", this.messagephone);
      formData.append("name",this.messagename);
      this.$http({
        method: "post",
        url: "/v1/app-join/save-customer-market",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data)
        if (res.data.error==0) {
           this.showpptc=false
        alert(res.data.data);
        this.messagename=''
        this.messagephone=''

        }else{
        alert(res.data.error_msg);

        }

     

        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    zhanshouu(){
      if(this.zhanshou=='展开↓'){
        console.log('222')
        this.zhanshou='收起↑'
        this.hei='100%'
      }else{
        this.zhanshou='展开↓',
        this.hei='1rem'

      }
      
  
    },
    zhanshouu1(){
      if(this.zhanshou1=='展开↓'){
        console.log('222')
        this.zhanshou1='收起↑'
        this.hei1='100%'
      }else{
        this.zhanshou1='展开↓',
        this.hei1='1rem'

      }
      
  
    },
    zhanshouu2(){
      if(this.zhanshou2=='展开↓'){
        console.log('222')
        this.zhanshou2='收起↑'
        this.hei2='100%'
      }else{
        this.zhanshou2='展开↓',
        this.hei2='1rem'
      }
    },
    showtc(index,item){
      this.marketindex=index
      this.expo_area_id=item.area_id,
      this.ifexpoCorp='1',
      console.log(item.area_id)
      console.log(this.expo_area_id)
      this.getAllArr()

    },
        //显示品牌弹窗
    //显示品牌弹窗
    showtccc(item){
      this.showpptc=true
    },
    yctc(){
      this.showpptc=false

    },
    // 调用拨号功能2
    callphone () {
     window.location.href = 'tel://' + this.marketdetail.expo_phone
    }

  },
    beforeDestroy(){
      clearinterval(this.countdown)
    },
  
  

};
</script>
<style scoped>
.doc_content{
  font-size: 0.24rem;
  padding: 0 0.2rem;
}
.ijdimg{
  width: 4rem;
  height: 1rem;
  margin-left: 0.2rem;

}
.ijdbut{
  font-size: 0.26rem;
  background-color: #FECB32;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  padding: 0 0.4rem;
  font-weight: bold;
  border-radius: 0.5rem;
  margin-top: 0.18rem;
  margin-left: 0.1rem;
}
.xiazaiapp{
  width: 100%;
  height: 1rem;
  background-color: #ffffff;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}
.qingq{
  width: 6.8rem;
  margin: 0 auto;
  font-size: 0.3rem;
  background-color: #FECB32;
  line-height: 0.75rem;
  text-align: center;
  font-weight: bold;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}
::v-deep .el-carousel__indicators--horizontal{
    position: absolute !important;
    left: 25%;
    top: 65%;
}
  .el-carousel__item h3 {
    /* color: #475669; */
    /* font-size: 0.2rem; */
    /* opacity: 0.75; */
    /* line-height: 0.2rem; */
    /* margin: 0; */
  }

  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
.admr{
  margin-top: -0.3rem;
}
.adimg5{
  width: 2rem;
  height: 1.2rem;
  /* background: #B5001F; */
  margin: 0 0.08rem;
  
}
.adimgbox{
  display: flex;
  flex-wrap: wrap;
  padding: 0.2rem 0.5rem;
  box-sizing: border-box;
}
.adimg{
  width: 100%;
  height: 1.5rem;
  /* background: #B5001F; */
  margin: 0;
}
.page{
    background-color:#F7F8FB;
    /* height: 100vh; */
}
.heaimg{
    width: 100%;
    position: relative; 
    top: 0;
    left: 0;
    height: 5.2rem;
    /* background-color: aqua; */
    /* margin-bottom: 2.2rem; */
}
.headbox{
    /* width: 6.9rem; */
    /* height: 3.1rem; */

    background-color: rgb(255, 255, 255);
    /* border: 0.01rem solid rgb(44, 42, 42); */
    border-radius: 0.1rem;
    box-sizing: border-box;
    padding:  0.35rem;
    /* margin-top: 0.5rem; */
}
.headtit{
  font-size: 0.3rem;
  font-weight: bold;
  display: flex;
  margin-bottom: 0.35rem;
}
.headtime{
  font-size: 0.24rem;
  display: flex;
  margin-top: 0.2rem;

}
.headside{
  width: 4.3rem;
  font-size: 0.2rem;
  font-weight: bold;
  display: flex;
  overflow: hidden; 
   text-overflow: ellipsis; 
  -webkit-box-orient:vertical;
  display: -webkit-box; 
    -webkit-line-clamp:2;
  /* background: #fff; */


}
.headjuli{
  font-size: 0.2rem;
  color: #8C8F9F;
  margin-top: 0.2rem;
}
.ttimg{
    width: 0.25rem;
    height: 0.25rem;
    margin-top: 0.05rem;
    margin-right: 0.1rem;
    margin-bottom: 0.2rem;
}
.ttimg1{
    width: 0.25rem;
    height: 0.3rem;
    margin-top: 0.05rem;
    margin-right: 0.1rem;
    /* margin-bottom: 0.2rem; */
}
.hsjbox{
    display: flex;
    justify-content: space-between;
}
.dhbox{
    border-left: 0.01rem solid #E8EAF3;
    padding: 0 0.31rem;
    height: 0.7rem;
}
.dhimg{
    width: 0.23rem;
    height: 0.23rem;
    margin-left: 0.1rem;
}
.dhbox1{
    margin-top: -0.75rem;
}
.tittle{
    border-left: 0.3rem solid #FECB32;
    font-size: 0.35rem;
    font-weight: bold;
    padding-left: 0.2rem;
    box-sizing: border-box;
    height: 0.45rem;
    margin: 0rem 0; 
    margin-bottom: 0.1rem;


}
.xinxibox{
  height: 1rem;
  overflow: hidden;
    /* width: 6.9rem; */
    padding: 0.3rem;
    /* border-radius: 0.2rem; */
    background-color: #fff;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 0.28rem;
    margin-bottom: 0.2rem;
    padding-top: 0;
}
.shopbox{
    width: 6.9rem;
    /* padding:  0.15rem; */
    border-radius: 0.2rem;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 0.28rem;
    display: flex;
    flex-wrap: wrap;
}
.shopli{
  width: 2rem;
  /* height: 2.5rem; */
  border-radius: 0.1rem;
  margin: 0.15rem;
    background-color: #f5f5f5;
    overflow: hidden;
    position: relative;

}
.shopimg{
  width: 100%;
  height: 1.5rem;
  /* background-color: #FECB32; */
}
.shoptit{
  text-align: center;
  font-size: 0.24rem;
  font-weight: bold;
  line-height: 0.54rem;
  /* background-color: #f5f5f5; */
  width: 2rem;
  white-space: nowrap; 
text-overflow: ellipsis; 
overflow: hidden; 


}
.classbox{
  width: 6rem;
  /* height: 1rem; */
  /* background: rgb(83, 76, 76); */
  overflow-x: scroll;
  white-space:nowrap;
  /* display: flex; */
  /* padding-top: -1rem; */
  margin-top: -0.35rem;


}
.classitem{
  /* width: 0.6rem; */
  height: 0.7rem;
  background: #f5f5f5;
  font-size: 0.24rem;
  display: inline-block;
  margin: 0.05rem;
  white-space:nowrap;
  padding:  0.05rem 0.2rem;
  text-align: center;
}
.clbox{
  display: flex;
  justify-content: space-between;
  padding: 0 0.3rem;
  margin-top: 0.2rem;

}
.gengduo{
    margin-top: 0.07rem;
    line-height: 0.7rem;
    /* color: #000; */
    
}
.color{
  background: #FECB32;
}
.jiben{
  width: 100%;
  background-color: #fff;
  padding: 0.3rem 0;
  margin: 0.3rem 0;
}
.jibentext{
  display: flex;
  padding: 0 0.3rem;
  margin: 0.15rem 0;

}
.jibentit{
  width: 1.2rem;
 font-size: 00.24rem;
 margin-right: 0.2rem;
color: rgb(97, 96, 96);
}
.jibencon{
 font-size: 00.24rem;
 display: flex;


}
.connn{
  margin-right: 0.15rem;
}
.red{
  color: red;
}
.hdox{
  width: 6.9rem;
  margin: 0 auto;
  /* border: 0.01rem solid #d7d7d8; */
  margin-top: 0.2rem;
  
}
.hdoxx{
  width: 6.9rem;
  /* height: 4rem; */
  /* background: #FECB32; */
  margin: 0 auto;
  border: 0.01rem solid #d7d7d8;
  margin-top: 0.2rem;
  display: flex;
  overflow-x: scroll;
  white-space:nowrap;

}
.hdoxxs{

  margin: 0 auto;
  /* border: 0.01rem solid #d7d7d8; */
  /* margin-top: 0.2rem; */
  display: flex;
  /* overflow-x: scroll;
  white-space:nowrap; */

}
.hdimg{
  width: 100%;
  height: 3.5rem;
}
.hdtit{
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.5rem;
  margin-left: 0.2rem;
  margin-bottom: 0.15rem;
}
.dibox{
  width: 100%;
  background: #ffffff;
  /* padding: 0.2rem; */
  position: fixed;
  bottom: 0;
  left: 0;
  
}
.dibutton{
  width: 6.8rem;
  background-color: #FECB32;
  /* color: #fff; */
  line-height: 0.7rem;
  font-size: 0.3rem;
  text-align: center;
  margin: 0.2rem auto;
  font-weight: bold;
  border-radius: 0.1rem;

}
.tcbox{
  width: 6.90rem;
  /* height: 8.4rem; */
  /* border: 0.01rem solid red; */
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 11;
  text-align: center;

}
.black{
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.h22img{
    width: 6.90rem;
  height: 8.4rem;

}
.tccon{
  width: 6.50rem;
height: 5.47rem;
background: #FFFFFF;
border-radius: 0.1rem;
position: absolute;
top: 2.76rem;
left: 0.2rem;
padding: 0.2rem;
box-sizing: border-box;
}
.h23img{
  width: 00.72rem;
  height: 0.72rem;

}
.tcimg{
  width: 2.78rem;
height: 2.02rem;
background: #B5001F;
margin-right: 0.3rem;
}
.tcxinxin{
  display: flex;
}
.tctit{
  width: 3rem;

  font-size: 0.3rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: 0.1rem;
  	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;

}
.touzi1{
  font-size: 0.3rem;
  color: #03050F;
  display: flex;
  font-weight: bold;
    	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
}
.touzi2{
  width: 3rem;
  font-size: 0.28rem;
  color: #03050F;
  font-weight: bold;
    	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
  margin-bottom: 0.3rem;
}
.touzi3{
  font-size: 0.2rem;
  color: #8d8d8d;
  display: flex;
  /* font-weight: bold; */
}
.tcnum{
  
font-weight: bold;
color: #EE1818;
margin-bottom: 0.15rem;
margin-left: 0.1rem;
}
.inpbox{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.inp{
  width: 2.8rem;
  height: 0.86rem;
  background: rgba(127,127,127,0);
  border: 0.01rem solid #8C8F9F;
  border-radius: 0.1rem;
  padding-left: 0.12rem;
}
.tcbut{
  width: 6.10rem;
height: 0.81rem;
background: #FECB32;
border-radius: 0.1rem;

font-size: 0.3rem;
font-weight: bold;
color: #03050F;
line-height: 0.24rem;
text-align: center;
line-height: 0.81rem;
margin-top: 0.2rem;
}
.tctext{
  
font-size: 0.24rem;

color: #8C8F9F;
margin-top: 0.3rem;

}
.s11img{
  position: absolute;
  top: 0;
  right: 0;
  width: 0.42rem;
  height: 0.37rem;

}
.zhanshou{
  text-align: center;
  font-size: 0.3rem;
  color: #FECB32;
}
.conbox{
    width: 100%;
    border-top: 0.01rem solid #f1f1f1;
    display: flex;
    justify-content: space-between;
    /* margin-top: 0.34rem; */
    background-color: #fff;
}
.context{
    font-size: 0.2rem;
    color: #616161;
    display: flex;
    justify-content: center;
    line-height: 0.8rem;
    padding: 0 0.3rem;
}
.xian{
    height: 0.2rem;
    width: 0.01rem;
    background: #f1f1f1;
    margin: 0 0rem;
    margin-top: 0.28rem;
    
}
.dizhibox{
  background: #fff;
  display: fixed;
  position: relative;
  margin-top: 0.2rem;
  padding: 0.3rem;
  box-sizing: border-box;
}
.daohang{
  position: absolute;
  font-size: 0.26rem;
  top: 0.5rem;
  right: 0.3rem;
  /* width: 1.5rem; */
  text-align: center;
  background: #141414;
  color: #fff;
  line-height: 0.57rem;
  border-radius: 0.35rem;
  padding: 0 0.2rem;
  display: flex;
  
}
.fangdaimg{
  width: 100%;
  height: 4.5rem;
  margin-top: 0.3rem;
}
.dianhua{
  margin-top: -0.1rem;
}
.zhanshang{
  background: #fff;
  margin-top: 0.3rem;
  padding: 0.2rem 0;
}
.heabox{
  position: relative;
  height: 5.2rem;
  display: flex;
}
.timebox{
  position: absolute;
  height: 0.5rem;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: right;
  color: #fff;
  line-height: 0.5rem;
  font-size: 0.24rem;
  padding-right: 0.2rem;
  box-sizing: border-box;
}
.timebox1{
  position: absolute;
  height: 0.5rem;
  width: 100%;
  background: #000000;
  opacity: 0.5;
  bottom: 0;
  left: 0;
}
.numbox{
  background-color: #ffcd29;
  height: 0.3rem;
  line-height: 0.3rem;
  margin: 0 0.1rem;
  margin-top: 0.095rem;
  padding: 0 0.05rem;
  color: #000000;


}
.dht{
  width: 0.3rem;
  height: 0.3rem;
  padding-top: 0.12rem;
  margin-right: 0.1rem;
}
.fangDabox{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.black1{
  background: #000000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  /* position: fixed; */
  top: 0;
  left: 0;
  z-index: 100  ;
}
.fangdaimg1{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 101;
  transform: translate(-50%,-50%);

}
.headtit2{
  margin-bottom: 0.2rem;
}
.el-carousel el-carousel--horizontal{
  height: 2rem;
}
.tanchuang{
  width: 6.5rem;
  height: 8.5rem;
  background-color: #fff;
  border-radius: 0.2rem;
  border: 0.1rem solid #FECB32;
  position: fixed;
  top: 40%;
  left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
  overflow-y: scroll;

}
.black{
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5rem;
  position: fixed;
  top: 0;
  left: 0;
  }
  .h23img{
  width: 1rem;
  height: 1rem;
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%,-50%);
    z-index: 100;

  }
  .numboxindex{
    position: fixed;
    right: 10px;
    top: 9%;
    z-index: 100;
    font-size: 12px !important;
    color: #fff;
    background: #03050F;
    opacity: 0.5;
    padding: 1px 8px;
    border-radius: 20px;

  }
  .el-carousel el-carousel--horizontal{
    /* font-size: 0.26rem; */

  }
.el-button--primary {
    --el-button-text-color: #f88440;
    --el-button-bg-color: #f88440;
    --el-button-border-color: #f88440;
    --el-button-outline-color: #f88440;
    --el-button-active-color: #f88440;
    --el-button-hover-text-color:#f88440;
    --el-button-hover-link-text-color: #f88440;
    --el-button-hover-bg-color: #f88440;
    --el-button-hover-border-color:#f88440;
    --el-button-active-bg-color: #f88440;
    --el-button-active-border-color: #f88440;
    --el-button-disabled-text-color: #f88440;
    --el-button-disabled-bg-color:#f88440;
    --el-button-disabled-border-color: #f88440;
}
    .el-loading-spinner .path{
      stroke: #c8291c;
    }
        .el-loading-spinner .path{
      stroke: #c8291c;
    }
:root{
  
}
</style>