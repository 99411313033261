<template>
  <div>
    <div class="heabbox">
      <img class="heaimg" src="../assets/imgs/zw.png" alt="" />
    </div>
    <div class="heacon">
      <img class="conimg" :src="AllArr.brand_logo" alt="" />
      <div class="concon">
        <div class="contit">{{AllArr.brand_name}}</div>
        <div class="concom">{{AllArr.company.company_name}}</div>
        <div class="confenlei">
          <!-- <div class="fltab">店面选址</div> -->
        </div>
      </div>
    </div>
    <div class="jiamzxbox">
      <img class="j1img" src="../assets/imgs/j1.png" alt="" />
      <div class="jmhea">
        <div class="yiyou">
          已有
          <div class="jmnum">{{AllArr.look_num}}</div>
          人申请加盟
        </div>
        <div class="jmbut" @click="showtc">加盟咨询</div>
      </div>
      <div class="jslistbox">
        <div class="jmlist">
          <div class="jmlisttit">主营产品</div>
          <div class="jmlisttext">{{AllArr.corpClass.corp_class_name}}</div>
        </div>
        <div class="xian"></div>
        <div class="jmlist">
          <div class="jmlisttit">投资金额</div>
          <div class="jmlisttext">{{AllArr.money_min}}-{{AllArr.money_max}}万</div>
        </div>
        <div class="xian"></div>

        <div class="jmlist">
          <div class="jmlisttit">门店数量</div>
          <div class="jmlisttext">{{AllArr.corp_store_num}}家</div>
        </div>
        <div class="jmlist">
          <div class="jmlisttit">品牌成立时间</div>
          <div class="jmlisttext" v-if="AllArrcorp_end_time!==''">{{AllArrcorp_end_time}}年</div>
          <div class="jmlisttext" v-if="AllArrcorp_end_time==''">未知</div>
        </div>
        <div class="xian"></div>

        <div class="jmlist">
          <div class="jmlisttit">总部所在地</div>
          <div class="jmlisttext cc" v-if="AllArr.company.company_address!==''">{{AllArr.company.company_address}}</div>
          <div class="jmlisttext cc" v-if="AllArr.company.company_address ==''">未知</div>
        </div>
        <div class="xian"></div>

        <div class="jmlist">
          <div class="jmlisttit">厂房面积</div>
          <div class="jmlisttext" v-if="AllArr.corp_store_area !==''">{{AllArr.corp_store_area}}㎡</div>
          <div class="jmlisttext" v-if="AllArr.corp_store_area ==''">未知</div>
        </div>
      </div>
    </div>
    <div class="jiamzxbox">
      <img class="j1img" src="../assets/imgs/j1.png" alt="" />
      <div class="jmhea">
        <div class="jmnum xx">
          {{AllArr.brand_name}}
          <div class="jmnum jj">加盟优势</div>
        </div>
        
      </div>
          <div class="jiamyoustext">{{AllArr.corp_mark}}</div>

    </div>
    <!-- 品牌弹窗 -->
    <div class="tcbox" v-if="showpptc">
      <img class="h22img" src="../assets/imgs/h22.png" alt="" />
      <div class="tccon">
        <div class="tcxinxin">
          <img class="tcimg" :src="AllArr.brand_logo" alt="" />
          <div>
            <div class="tctit">{{AllArr.brand_name}}</div>
            <div class="touzi">
              基本投资:
              <div class="tcnum">
                {{ AllArr.money_min }}万-{{ AllArr.money_min }}万
              </div>
            </div>
            <div class="touzi">
              全国门店:
              <div class="tcnum">{{ AllArr.corp_store_num }}家</div>
            </div>
          </div>
        </div>
        <div class="inpbox">
          <input class="inp" type="text" placeholder="请输入您的姓名" />
          <input class="inp" type="text" placeholder="请输入手机号码" />
        </div>
        <div class="tcbut" @click="yctc">提交加盟咨询</div>
        <div class="tctext">留下您的联系方式，我们马上为您服务</div>
      </div>
      <img class="h23img" @click="yctc" src="../assets/imgs/h23.png" alt="" />
    </div>
    <div class="black" v-if="showpptc"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      AllArr: [],
      showpptc:false,//品牌弹窗
      corp_id:'',
      AllArrcorp_end_time:''
      

    };
  },

  created() {
     this.corp_id=this.$route.query.corp_id
     console.log(this.corp_id)
    this.getAllArr()
   

  },
  methods: {
    getAllArr() {
      let formData = new FormData();
      formData.append("corp_id", this.corp_id);
      this.$http({
        method: "post",
        url: "/v1/app-join/get-join-detail",

        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          // console.log(res.data);
          this.AllArr=res.data.data
          console.log(this.AllArr)

          var date = new Date(Number(this.AllArr.corp_end_time))
           this.AllArrcorp_end_time=date.getFullYear()
           console.log(this.AllArrcorp_end_time) 

        })
        .catch((err) => {
          console.log(err);
        });
    },
        //显示品牌弹窗
    showtc(){
      this.showpptc=true
      console.log("111")
    },
    yctc() {
      this.showpptc = false;
    },
  },
};
</script>
<style scoped>
.heabbox {
  height: 5rem;
}
.heaimg {
  width: 100%;
  height: 100%;
  /* background-color: aquamarine; */
}
.heacon {
  width: 6.9rem;
  height: 2.15rem;
  background: #ffffff;
  box-shadow: 0 0.04rem 0.24rem 0 rgba(0, 0, 0, 0.08);
  opacity: 0.9;
  border-radius: 0.1rem;
  margin: 0 auto;
  margin-top: -0.6rem;
  padding: 0.4rem 0.3rem;
  box-sizing: border-box;
  display: flex;
}
.conimg {
  width: 2.31rem;
  height: 1.35rem;
  margin-right: 0.3rem;
}
.contit {
  font-size: 00.38rem;

  font-weight: bold;
}
.concom {
  font-size: 0.22rem;
  margin-top: 0.1rem;
}
.confenlei {
  display: flex;
}
.fltab {
  width: 0.84rem;
  height: 0.34rem;
  background: rgba(3, 5, 15, 0);
  border: 0.01rem solid #bd6d10;
  border-radius: 0.05rem;
  line-height: 0.34rem;
  text-align: center;
  color: #bd6d10;
  font-size: 00.18rem;
}
.jiamzxbox {
  width: 6.9rem;
  /* height: 3.75rem; */
  background: rgba(254, 203, 50, 0);
  border: 0.03rem solid #ee1818;
  border-radius: 0.1rem;
  margin: 0 auto;
  margin-top: 0.3rem;
  position: relative;
  padding-bottom: 0.2rem;
}
.j1img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.jmhea {
  padding: 0 0.28rem;
  display: flex;
  justify-content: space-between;
  height: 0.98rem;
}
.yiyou {
  display: flex;
  font-size: 0.24rem;
  margin-top: 0.3rem;
}
.jmnum {
  font-size: 0.28rem;
  color: #03050f;
  font-weight: bold;
  /* line-height: 0.24rem; */
  color: #ee1818;
  margin: 0 0.05rem;
  margin-top: -0.02rem;
  display: flex;
}
.jmbut {
  width: 1.6rem;
  height: 0.6rem;
  background-color: #ee1818;
  border-radius: 00.1rem;
  line-height: 0.6rem;
  margin-top: 0.18rem;

  font-size: 0.24rem;
  color: #ffffff;
  text-align: center;
}
.jslistbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.jmlist {
  text-align: center;
  width: 2.25rem;
  /* background-color: aliceblue; */
  padding: 0.3rem 0;
}
.jmlisttit {
  color: #5d5f6a;
  font-size: 0.24rem;
}
.jmlisttext {
  color: #ee1818;
  font-size: 0.34rem;

  font-weight: bold;
}
.xian {
  background-color: #e8eaf3;
  height: 00.6rem;
  width: 0.01rem;
  margin-top: 0.35rem;
}
.cc {
  font-size: 0.24rem;
  margin-top: 0.08rem;
}
.xx {
  color: #000;
  line-height: 0.98rem;
}
.jj {
  margin-top: 0;
}
.tcbox {
  width: 6.9rem;
  /* height: 8.4rem; */
  /* border: 0.01rem solid red; */
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11111;
  text-align: center;
}
.black {
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.h22img {
  width: 6.9rem;
  height: 8.4rem;
}
.tccon {
  width: 6.5rem;
  height: 5.47rem;
  background: #ffffff;
  border-radius: 0.1rem;
  position: absolute;
  top: 2.76rem;
  left: 0.2rem;
  padding: 0.2rem;
  box-sizing: border-box;
}
.h23img {
  width: 00.72rem;
  height: 0.72rem;
}
.tcimg {
  width: 2.78rem;
  height: 2.02rem;
  background: #b5001f;
  margin-right: 0.3rem;
}
.tcxinxin {
  display: flex;
}
.tctit {
  font-size: 0.3rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: 0.1rem;
}
.touzi {
  font-size: 0.2rem;
  color: #03050f;
  display: flex;
}
.tcnum {
  font-weight: bold;
  color: #ee1818;
  margin-bottom: 0.15rem;
  margin-left: 0.1rem;
}
.inpbox {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.inp {
  width: 2.8rem;
  height: 0.86rem;
  background: rgba(127, 127, 127, 0);
  border: 0.01rem solid #8c8f9f;
  border-radius: 0.1rem;
  padding-left: 0.12rem;
}
.tcbut {
  width: 6.1rem;
  height: 0.81rem;
  background: #fecb32;
  border-radius: 0.1rem;

  font-size: 0.3rem;
  font-weight: bold;
  color: #03050f;
  line-height: 0.24rem;
  text-align: center;
  line-height: 0.81rem;
  margin-top: 0.2rem;
}
.tctext {
  font-size: 0.24rem;

  color: #8c8f9f;
  margin-top: 0.3rem;
}
.jiamyoustext{
  font-size: 00.25rem;
  padding: 0.25rem;
  line-height: 0.4rem;
}
</style>