<template>

<meta name="format-detection" content="telephone=yes" />
<div class="page">
    <div class="clbox">
      <div class="classbox">
        <div class="classitem " :style="{'color': (index==marketindex?'#000':'')}"  @click="showtc(index,item)" v-for="(item,index) in corp_class_list" :key="index" >{{item.corp_class_name}} <div class="mc_count">({{item.count}})</div> <div v-if="index==marketindex" class="xian"></div></div>
      </div>
    </div>
    <div class="shopbox">
        <div class="shopli" v-for="(item,index) in CommunityCorpArr" :key="index">
           <router-link :to="'/Premduce?id='+item.ccorp_id">
           <img class="shopimg" v-lazy=" url+item.ccorp_pic" :alt="require('../assets/imgs/zw.png')">
            <div class="shoptit" :style="{'background': (item.bc_id!==null?'#FECB32':'')}">{{item.ccorp_name}}</div>   
            <img class="s11img" src="../assets/imgs/s11.png" alt="" v-if="item.bc_id!==null">
          </router-link>
        </div>
    </div>
<div :style="{'height':'2rem'}" v-if="mc_list_info.length!==0"></div>
</div>

</template>
<script>
export default {
  data() {
    return {
      id:'',
      marketdetail:[],
      content:'',
      mclist:[],
      market:[],
      market_mark:'',
      marketindex:'0',
      mc_corplist:[],
      url: "https://djdshop.vjiashuzi.com",
      marketMactivity:[],
      marketJoinin:{},
      market_select:'',
      market_good:'',
      m_tags:[],
      showpptc:false,//品牌弹窗
      messagename:'',
      messagephone:'',
      mc_list:[],
      market_class_id:"",
      mc_list_info:'',
      corp_class_list:''


    };
  },

  created() {
    document.title = '店铺列表'

    // console.log("ididididid",this.$route.query.id)
    this.id=this.$route.query.id
    
    
  },
  mounted() {
    this.getAllArr();
    this.getCustomerMarket();
    // this.getCurrentPosition();
  },
  methods: {
    getLongitudeLatitude() {
      //如果该对象存在，那么地理位置服务可用。
      if ('geolocation' in navigator) {
        /* 地理位置服务可用 */
        var options = {
          enableHighAccuracy: true, //布尔值，表示系统是否使用最高精度来表示结果，注意，这会导致较慢的响应时间或者增加电量消耗（比如对于支持gps的移动设备来说）。如果值为false ，设备会通过更快响应以及/或者使用更少的电量等方法来尽可能的节约资源。默认值fasle
          timeout: 5000, //它表明的是设备必须在多长时间（单位毫秒）内返回一个位置。默认直到获取到位置才会返回值。
          maximumAge: 0 //表明可以返回多长时间（即最长年龄，单位毫秒）内的可获取的缓存位置。如果设置为 0, 说明设备不能使用一个缓存位置，而且必须去获取一个真实的当前位置。默认0
        }
        function success(position) {
          //position.coords (只读) 返回一个定义了当前位置的Coordinates对象。
          //position.timestamp (只读) 返回一个时间戳DOMTimeStamp， 这个时间戳表示获取到的位置的时间。
          var lat = position.coords.latitude //当前位置的纬度
          var lng = position.coords.longitude //当前位置精度
        }
        function error(err) {
          var errorType = ['您拒绝共享位置信息', '获取不到位置信息', '获取位置信息超时']
          console.log(errorType[err.code - 1])
        }
 
        navigator.geolocation.getCurrentPosition(success, error, options)
      } else {
        /* 地理位置服务不可用 */
        console.log('无法获取您的位置，请检查定位是否开启或刷新重试')
      }
    },
    getAllArr() {
      let formData = new FormData()
      formData.append("community_id",this.id);
 
      this.$http({
        method: "post",
        url: "/v1/app-community/get-corp-list",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.marketdetail=res.data.data
          this.corp_class_list=res.data.data.corp_class_list
          this.CommunityCorpArr=res.data.data.corp_class_list[0].CommunityCorpArr


          // console.log(this.mc_corplist);

          // this.content=res. data.data.content
          // var content=this.content
          //   content = content.replace(/\<img/gi, '<img class="img"')
          // this.content=content
          // // console.log(content);
          // var content=this.content
          //   content = content.replace(/\height/gi, ' ')
          // this.content=content
        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    getCustomerMarket() {
      console.log(this.messagephone)
      console.log(this.messagename)
      let formData = new FormData()
      formData.append("market_id", this.id);
      formData.append("market_class_id", this.market_class_id);
      this.$http({
        method: "post",
        url: "/v1/app-market/get-mcorp-list",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data)
        if (res.data.error==0) {
           this.mc_list_info=res.data.data.mc_list_info
     

        }else{
        alert(res.data.error_msg);

        }

     

        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    showtc(index,item){
      this.marketindex=index
      this.CommunityCorpArr=this.corp_class_list[index].CommunityCorpArrex
     
      // this.mc_corplist=this.mclist[index].mc_corplist
      console.log(item)
      console.log(this.mclist)
    //   this.market_class_id=item.market_class_id
    //   this.getCustomerMarket()
      // console.log(index)
      // console.log(this.mclist[index])
    },
        //显示品牌弹窗
    //显示品牌弹窗
    showtccc(item){
      this.showpptc=true
    },
    yctc(){
      this.showpptc=false

    },
    // 调用拨号功能
    callphone () {
     window.location.href = 'tel://' + this.marketJoinin.contacts_phone
    }
    // //推荐品牌
    // tuijian(item,index){
    //   console.log('11111',item)
    //   // console.log('index',index)
    //   this.pinpailist=item.item
    //   this.clickid=index
    //   console.log(this.clickid)
    // }
  },
};
</script>
<style scoped>
.page{
    background-color:#F7F8FB;
    /* height: 100vh; */
}
.heaimg{
    width: 100%;
    position: relative; 
    top: 0;
    left: 0;
    height: 5.7rem;
    /* background-color: aqua; */
    margin-bottom: 2.2rem;
}
.headbox{
    width: 6.9rem;
    height: 3.1rem;
    position: absolute;
    top: 5rem;
    left: 50%;
    transform: translate(-50%);
    background-color: rgb(255, 255, 255);
    /* border: 0.01rem solid rgb(44, 42, 42); */
    border-radius: 0.1rem;
    box-sizing: border-box;
    padding:  0.35rem;
}
.headtit{
  font-size: 0.3rem;
  font-weight: bold;
  display: flex;
  margin-bottom: 0.35rem;
}
.headtime{
  font-size: 0.1rem;
  display: flex;

}
.headside{
  width: 4.5rem;
  font-size: 0.2rem;
  font-weight: bold;
  display: flex;
  margin-bottom: 0.2rem;

}
.headjuli{
  font-size: 0.2rem;
  color: #8C8F9F;
  display: flex;
}
.ttimg{
    width: 0.25rem;
    height: 0.25rem;
    margin-top: 0.05rem;
    margin-right: 0.1rem;
    margin-bottom: 0.2rem;
}
.hsjbox{
    display: flex;
    justify-content: space-between;
}
.dhbox{
    border-left: 0.01rem solid #E8EAF3;
    padding: 0 0.31rem;
    height: 0.8rem;
}
.dhimg{
    width: 0.23rem;
    height: 0.23rem;
    /* margin-top: -5rem; */
    margin-left: 0.3rem;
}
.dhbox1{
    margin-top: -0.75rem;
}
.tittle{
    border-left: 0.3rem solid #FECB32;
    font-size: 0.35rem;
    font-weight: bold;
    padding-left: 0.2rem;
    box-sizing: border-box;
    height: 0.45rem;
    margin: 0rem 0; 
    margin-bottom: 0.1rem;


}
.xinxibox{
    /* width: 6.9rem; */
    padding: 0.3rem;
    /* border-radius: 0.2rem; */
    background-color: #fff;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 0.28rem;
    margin-bottom: 0.2rem;
    padding-top: 0;
}
.shopbox{
    width: 6.9rem;
    /* padding:  0.15rem; */
    border-radius: 0.2rem;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 0.28rem;
    display: flex;
    flex-wrap: wrap;
}
.shopli{
  width: 3.15rem;
  /* height: 2.5rem; */
  border-radius: 0.1rem;
  margin: 0.15rem;
    background-color: #fff;
    overflow: hidden;
    position: relative;

}
.shopimg{
  width: 100%;
  height: 2.5rem;
  /* background-color: #FECB32; */
}
.shoptit{
  text-align: center;
  font-size: 0.24rem;
  font-weight: bold;
  line-height: 0.54rem;
  /* background-color: #adaca8; */
  /* width: 2rem; */


}
.classbox{
  /* width: 6rem; */
  /* height: 1rem; */
  /* background: rgb(83, 76, 76); */
  overflow-x: scroll;
  white-space:nowrap;
  display: flex;
  /* padding-top: -1rem; */
  /* margin-top: -0.35rem; */


}
.classitem{
  /* width: 0.6rem; */
  /* height: 0.7rem; */
  background: #fff;
  font-size: 0.28rem;
  /* display: inline-block; */
  /* margin: 0.05rem; */
  white-space:nowrap;
  padding:  0.05rem 0.2rem;
  text-align: center;
  color: #6e6d6d;
  position: relative;
}
.clbox{
  /* display: flex; */
  /* justify-content: space-between; */
  /* padding: 0 0.3rem; */
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 100;

}

.color{
  background: #FECB32;
}
.jiben{
  width: 100%;
  background-color: #fff;
  padding: 0.3rem 0;
  margin: 0.3rem 0;
}
.jibentext{
  display: flex;
  padding: 0 0.3rem;
  margin: 0.15rem 0;

}
.jibentit{
  width: 1.2rem;
 font-size: 00.24rem;
 margin-right: 0.2rem;
color: rgb(97, 96, 96);
}
.jibencon{
 font-size: 00.24rem;
 display: flex;


}
.connn{
  margin-right: 0.15rem;
}
.red{
  color: red;
}
.hdox{
  width: 6.9rem;
  /* height: 4rem; */
  /* background: #FECB32; */
  margin: 0 auto;
  border: 0.01rem solid #d7d7d8;
  margin-top: 0.2rem;
}
.hdimg{
  width: 100%;
  height: 3.5rem;
}
.hdtit{
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.5rem;
  margin-left: 0.2rem;
  margin-bottom: 0.15rem;
}
.dibox{
  width: 100%;
  background: #ffffff;
  /* padding: 0.2rem; */
  position: fixed;
  bottom: 0;
  left: 0;
  
}
.dibutton{
  width: 6.8rem;
  background-color: #FECB32;
  /* color: #fff; */
  line-height: 0.7rem;
  font-size: 0.3rem;
  text-align: center;
  margin: 0.2rem auto;
  font-weight: bold;
  border-radius: 0.1rem;

}
.tcbox{
  width: 6.90rem;
  /* height: 8.4rem; */
  /* border: 0.01rem solid red; */
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 11;
  text-align: center;

}
.black{
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.h22img{
    width: 6.90rem;
  height: 8.4rem;

}
.tccon{
  width: 6.50rem;
height: 5.47rem;
background: #FFFFFF;
border-radius: 0.1rem;
position: absolute;
top: 2.76rem;
left: 0.2rem;
padding: 0.2rem;
box-sizing: border-box;
}
.h23img{
  width: 00.72rem;
  height: 0.72rem;

}
.tcimg{
  width: 2.78rem;
height: 2.02rem;
background: #B5001F;
margin-right: 0.3rem;
}
.tcxinxin{
  display: flex;
}
.tctit{
  width: 3rem;

  font-size: 0.3rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: 0.1rem;
  	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;

}
.touzi1{
  font-size: 0.3rem;
  color: #03050F;
  display: flex;
  font-weight: bold;
    	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
}
.touzi2{
  width: 3rem;
  font-size: 0.28rem;
  color: #03050F;
  font-weight: bold;
    	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
  margin-bottom: 0.3rem;
}
.touzi3{
  font-size: 0.2rem;
  color: #8d8d8d;
  display: flex;
  /* font-weight: bold; */
}
.tcnum{
  
font-weight: bold;
color: #EE1818;
margin-bottom: 0.15rem;
margin-left: 0.1rem;
}
.inpbox{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.inp{
  width: 2.8rem;
  height: 0.86rem;
  background: rgba(127,127,127,0);
  border: 0.01rem solid #8C8F9F;
  border-radius: 0.1rem;
  padding-left: 0.12rem;
}
.tcbut{
  width: 6.10rem;
height: 0.81rem;
background: #FECB32;
border-radius: 0.1rem;

font-size: 0.3rem;
font-weight: bold;
color: #03050F;
line-height: 0.24rem;
text-align: center;
line-height: 0.81rem;
margin-top: 0.2rem;
}
.tctext{
  
font-size: 0.24rem;

color: #8C8F9F;
margin-top: 0.3rem;

}
.s11img{
  position: absolute;
  top: 0;
  right: 0;
  width: 0.42rem;
  height: 0.37rem;

}
.mc_count{
  display: inline-block;
}
.xian{
  width: 0.3rem;
  height: 0.08rem;
  background-color: #FECB32;
  border-radius: 0.1rem;
  margin: 0 auto;
  margin-top: 0.1rem;
}
</style>