<template>
  <div class="navbox">资讯</div>
  <div class="fenleibox">
    <div class="femls">
      <div class="taball" v-for="(item, index) in fenleiarr" :key="index" @click="flclick(index,item.corpclass_id)">
        <div class="tabtext">{{ item.corp_class_name }}</div>
        <div class="sansan" v-if="index==ssid?true:false"></div>
      </div>
    </div>
  </div>
  <div class="allbox">
     <div v-for="(item, index) in lianmenglist" :key="index" >
      <router-link :to="'/Headline?id='+item.article_id">
          <div class="cylmlist">
        <div class="cylmimg">
        <img class="c1img" :src="url + item.path_pic" alt="" />
        </div>
        <div class="cylmcon">
          <div class="cymlsite">{{ item.article_title }}</div>
          <div class="cymlqt">
            {{ item.create_time }} {{ item.source }} {{ item.set_look_nums }}浏览
          </div>
        </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lianmenglist: [], //列表
      fenleiarr: [], //分类
      url: "https://djdshop.vjiashuzi.com",
      ssid:'0',
      corp_class_id:'1'
    };
  },

  created() {
    this.getAllArr();
    this.getClassArr();
  },
  methods: {
    // 列表
    getAllArr() {
      let formData = new FormData();
      formData.append("corp_class_id", this.corp_class_id);

      this.$http({
        method: "post",
        url: "/v1/article-platform-public/get-corp-class-article",
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.lianmenglist = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //分类
    getClassArr() {
      let formData = new FormData();
      formData.append("lng", "1");

      this.$http({
        method: "post",
        url: "/v1/app-join/get-corp-list",

        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log("分类", res.data);
          this.fenleiarr = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    flclick(index,corpclass_id){
      this.ssid=index,
      this.corp_class_id = corpclass_id
      console.log(this.corp_class_id)
      this.getAllArr();
    }

  },
};
</script>
<style scoped>
.cylmlist {
  width: 6.9rem;
  margin: 0 auto;
  display: flex;
  padding: 0.3rem 0;
  border-bottom: 0.01rem solid #e8eaf3;
}
.cylmimg {
  width: 2.1rem;
  height: 1.4rem;
  border: 0.01rem solid #e8eaf3;
  margin-right: 0.3rem;
  border-radius: 0.1rem;
  overflow: hidden;
}
.cymltit {
  width: 3.5rem;
  font-size: 0.31rem;
  margin-top: 0.1rem;
  font-weight: bold;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cymlsite {
  font-size: 00.26rem;
  color: rgb(7, 7, 7);
  margin-top: 0.1rem;
  width: 4.21rem;
  height: 0.7rem;
  /* margin-top: -0.2rem; */
  font-family: PingFang SC;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.cymlqt {
  font-size: 0.24rem;
  color: #979797;
  /* line-height: 0.24rem; */
  margin-top: 0.15rem;
}
.c1img {
  width: 100%;
  height: 100%;
}
.navbox {
  width: 100%;
  height: 1rem;
  background: #fecb32;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center !important;
  line-height: 1.1rem;
  font-weight: bold;
  font-size: 0.3rem;
  justify-content: center;

}
.allbox {
  margin-top: 1.6rem;
}
.fenleibox {
  /* width: 100%; */
  height: 00.6rem;
  position: fixed;
  top: 1rem;
  left: 0;
  display: flex;
  justify-content: center;
  font-size: 0;
  background-color: rgb(241, 241, 241);
  overflow-x: scroll;
  display: flex;
}
.taball {
  width: 1.4rem;
  /* background-color: #d6d6d6; */
  text-align: center;
  /* margin: 0 0.2rem; */
}
.tabtext {
  font-size: 0.23rem;
  color: #5d5f6a;
  line-height: 0.6rem;
  /* margin: 0 0.1rem; */
}
.sansan {
  width: 0.28rem;
  height: 0.08rem;
  background: #fecb32;
  border-radius: 0.04rem;
  margin: 0 auto;
  margin-top: -0.08rem;
}
.femls {
  /* width: 100%; */
  display: flex;
  /* overflow-x: scroll; */
}
</style>