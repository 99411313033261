
<template>
  <div id="container" class="container"></div>
</template>
 
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  // 安全密钥
  securityJsCode: "e542b232f3e07f89abb9ad0d52bc3fa5",
};
export default {
  name: "TestIndex",
  data() {
    return {
      // 地图实例
      map: null,
      // 标记点
      marker: "",
      // 位置信息

        lng: "",
        lat: "",
        address: "",
        //地区编码
        adcode: "",
   
    };
  },
      created() {
    this.lat=this.$route.query.lat
    this.lng=this.$route.query.lng
    console.log(this.lat)
    console.log(this.lng)

    
  },
  methods: {
    initMap() {
      AMapLoader.load({
        // 你申请的Key
        key: "077e26a08e4fb329f94e8c6cfd5180b1",
        version: "2.0",
        // 需要用到的插件
        plugins: ["AMap.Geocoder", "AMap.AutoComplete"],
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            viewMode: "3D", //是否为3D地图模式
            zoom: 12, //初始化地图级别
            center: [this.lng, this.lng], //初始化地图中心点位置
          });
                     // 清除点
            this.removeMarker();
            // 标记点
            this.setMapMarker();
          //点击获取经纬度;
          // this.map.on("click", (e) => {
          //   // 获取经纬度
          //   this.lng = e.lnglat.lng;
          //   this.lng = e.lnglat.lat;
          // });
        })
        .catch((err) => {
          // 错误
          console.log(err);
        });
    },
    // 标记点
    setMapMarker() {
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
      this.marker = new AMap.Marker({
        map: this.map,
        position: [this.lng, this.lat],
      });
      this.map.setFitView();
      this.map.add(this.marker);
    },
    // 清除点
    removeMarker() {
      if (this.marker) {
        this.map.remove(this.marker);
      }
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>
 
<style>
.container {
  width: 500px;
  height: 1000px;
}
</style>



