<template>
<!-- <RouterView v-wechat-title='$route.meta.title'/> -->
<!-- <router-view v-wechat-title="HHH" /> -->
<!-- 　<router-view v-wechat-title="$route.meta.title"></router-view> -->
 <!-- <keep-alive> 
 <router-view v-if="$route.meta.keepAlive"></router-view>
 </keep-alive>
<router-view v-if="!$route.meta.keepAlive"></router-view> -->

  <div id="nav">
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  </div>
  <router-view/>
</template>

<style >
html {
    font-size: 13.3333vw;
}
a {
  text-decoration: none;
  color: #000;
}
body{
    
    margin: 0;
    padding: 0;
    background-color: #F7F8FB;
}

.atab{
  color: #000;
}

/* #app {

  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // background-color: aqua;
}

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// } */
</style>
