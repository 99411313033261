<template>

<meta name="format-detection" content="telephone=yes" />

<div class="page">
    <div>
    <!-- <img class="heaimg" :src="url+marketdetail .mcorp_pic" alt=""> -->
    <img class="heaimg" v-lazy=" url+marketdetail.ccorp_pic" :alt="require('../assets/imgs/zw.png')">

    <div class="headbox">
        <div class="headtit">{{marketdetail.ccorp_name}}</div>
        <div class="headtime"> <img class="ttimg" src="../assets/imgs/time.png" alt="">{{marketdetail.ccorp_run_time}}</div>
        <div class="hsjbox">
            <div>
              <!-- <router-link :to="'/Map'"> -->
             <div class="headside"><img class="ttimg"  src="../assets/imgs/side.png" alt="">{{marketdetail.ccorp_local}} </div>
             <div class="headjuli">距您{{marketdetail.community_distance}}公里 </div>
             <!-- </router-link> -->
            </div>
            <div class="dhbox">
                <div class="dhbox1" @click="callphone">
                  <img class="dhimg" src="../assets/imgs/dh.png" alt="">
                  <div class="headjuli">商家电话</div>  
                </div>
            </div>
        </div>
    </div>
    </div>
     <div class="tittle">项目介绍</div>
    <div class="jiben" v-if="ccorp_content!==''">
      <div class="xinxibox" v-html="ccorp_content"></div>
    </div>
      <div class="tittle" v-if="corpPic.length!==0">店铺图集</div>

    <div class="shopbox" >
        <div class="shopli" v-for="(item,index) in corpPic" :key="index">
          <!-- <img class="shopimg" :src="url+item.mcorp_path_pic" alt=""> -->
           <img class="shopimg" v-lazy=" url+item.mcorp_path_pic" :alt="require('../assets/imgs/zw.png')">
        </div>
    </div>

<div :style="{'height':'2rem'}"></div>
<div class="dibox">
<div >
    <wx-open-launch-weapp  v-if="marketdetail.bc_id!==null" id="launch-btn" username="gh_019f12d74e33" :path="'pages/index/index?share_id='+share_id" @launch="handleLaunchFn" @error="handleErrorFn">
      <div v-is="'script'" type="text/wxtag-template">
        <div class="btn" style="font-size:24px;color:white;width:6.9rem;visibility: hidden;height:1rem;">打开小程序</div>>
      </div>
    </wx-open-launch-weapp>
  </div>
  <div v-if="marketdetail.bc_id!=='0'" > <div class="dibutton" @click.stop="showtccc(item)" v-if="marketdetail.bc_id!==null">立即进入线上商城</div> </div>
  <div class="dibutton1" @click.stop="showtccc(item)" v-if="marketdetail.bc_id==null||marketdetail.bc_id=='0'">该商家暂未开通线上商城</div>
  
</div>
</div>
<div v-if="marketdetail.bc_id!=='0'">
<div class="shlogo" v-if="marketdetail.bc_id!==null">
    <div class="logotit">皇冠商户</div>
    <div class="logotext">已开通线上商城</div> 
</div>
</div>


</template>

<script>
import axios from 'axios'
import aaa from './Storedetail.vue'
import wx from 'weixin-js-sdk';
import { el } from 'element-plus/es/locale';

export default {
  data() {
    return {
      id:'',
      marketdetail:[],
      content:'',
      mclist:[],
      market:[],
      market_mark:'',
      marketindex:'0',
      mc_corplist:[],
      url: "https://djdshop.vjiashuzi.com",
      marketMactivity:[],
      marketJoinin:{},
      market_select:'',
      market_good:'',
      m_tags:[],
      showpptc:false,//品牌弹窗
      messagename:'',
      messagephone:'',
      market_arr:'',
      market_mark:'',
      corpPic:[],
      share_id:'',
      reloadaaa:'0',



    };
  },

  created() {
    // console.log(arar)
    console.log("ididididid",this.$route.query.id)
    this.id=this.$route.query.id
    
  },
  mounted() {
    this.reloadaaa = localStorage.getItem('userString');
    console.log(this.reloadaaa)
    this.getAllArr();
    this.Config();
    this.finishActivity();


  },
//   watch: {
//    $route(to, from) {
//     if(from.path == '/Storedetail'){ 
//     console.log('1111111111111111111111')
//        window.location.reload(); //监测到路由发生跳转时刷新一次页面
//     }else{
//     console.log('999999999999999999999')

//     }

//    },
// },
  methods: {
finishActivity() {
   let ua = navigator.userAgent.toLowerCase();
    //android终端
   let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
    //ios终端
   let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  
   if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
   //ios
   console.log(" 我是ios")
   console.log(this.reloadaaa)

         if (this.reloadaaa == '1') {
         localStorage.setItem('userString', '2');
         window.location.reload();
         console.log("我是ios")
         }
 
    } else{
      console.log('11')
    }
 },
     handleLaunchFn () {
      console.log('TIAO')
    },
    handleErrorFn () {
      console.log('Error')

    },

    getAllArr() {
      let formData = new FormData()
      formData.append("ccorp_id", this.id);
      formData.append("lng",'1111111');
      formData.append("lat", '1111111');
      this.$http({
        method: "post",
        url: "/v1/app-community/get-corp-detail",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
          this.marketdetail=res.data.data
          this.ccorp_content=res.data.data.ccorp_content
          var content=this.ccorp_content
          content = content.replace(/\<img/gi, '<img style="width: 100%;"')
          this.ccorp_content=content
          document.title = this.marketdetail.ccorp_name

        //   this.market_arr=res.data.data.market_arr
        //   this.market_mark=res.data.data.market_arr.market_mark
        //   this.corpPic=res.data.data.corpPic
          this.Shareid();

        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    getCustomerMarket() {
      console.log(this.messagephone)
      console.log(this.messagename)
      let formData = new FormData()
      formData.append("market_id", this.id);
      formData.append("phone", this.messagephone);
      formData.append("name",this.messagename);
      this.$http({
        method: "post",
        url: "/v1/app-join/save-customer-market",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data)
        if (res.data.error==0) {
           this.showpptc=false
        this.messagename=''
        this.messagephone=''

        }else{

        }

     

        })
        .catch((err) => {
          console.log(err);
        });
            //显示品牌弹窗

    },
    showtc(index){
      this.marketindex=index
      this.mc_corplist=this.mclist[index].mc_corplist
      // console.log(this.mc_corplist)
      // console.log(this.mclist)
      // console.log(index)
      // console.log(this.mclist[index])
    },
    
    //显示品牌弹窗
    showtccc(item){
      // alert('该商家暂未开通线上商城');
    },
    yctc(){
      this.showpptc=false

    },
    // 调用拨号功能
    callphone () {
     window.location.href = 'tel://' + this.marketdetail.ccorp_phone
    },
    Config(){
      // alert('get_url:'+window.location.href);
      
     let formData = new FormData()
      formData.append("url",window.location.href);
      axios({
         method: "post",
         url: "https://ajdh5.vjiashuzi.com/GetWxConfig.php",
        data: formData,
         
       }).then(
         response => {
           console.log("返回信息", response.data);
          //  var obj = JSON.parse(response.data);
          //  console.log(obj)
          //wx.config({
             this.$wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
            appId: 'wxfbd6f16c8b572b3d', // 必填，公众号的唯一标识
            timestamp:response.data.timestamp , // 必填，生成签名的时间戳
            nonceStr:response.data.noncestr, // 必填，生成签名的随机串
            signature:response.data.signature,// 必填，签名
            jsApiList: ['openLocation','chooseImage', 'previewImage'], // 必填，需要使用的 JS 接口列表
            openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
           })
          //  alert('ddd');

         },
         (error) => {
           ElMessage.error("注册请求失败");
          
         }
       );
    },
    //分享id
    Shareid(){
      var timestamp = Date.parse(new Date());
    timestamp = timestamp / 1000;
    var n = timestamp * 1000; //获取当前时间
    var date = new Date(n);
    var Y = date.getFullYear(); //年`
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1); //月
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(); //日
    var h = date.getHours(); //时
    var m = date.getMinutes(); //分
    var s = date.getSeconds(); //秒
    var i = Math.floor(Math.random() * 999999+100000);
    // console.log(Y+M+D+h+m+s+i)
    var share_id = 'f'+Y + M + D + h + m + s + i

    this.share_id=share_id
    console.log(share_id)
    console.log(this.marketdetail.bc_id)
     let formData = new FormData()
      formData.append("share_id", share_id);
      formData.append("bc_id", this.marketdetail.bc_id);//bc_id: this.data.message.id,
      this.$http({
        method: "post",
        url: "/api/wx/set-share-id",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);

        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeRouteEnter(to,from,next){
        
          // console.log('进来',this.reloadaaa)

        if(from.path == '/Storedetail'){ 
        console.log('111111111111111')
        
        // console.log(this.reload)
          // window.location.reload(); //监测到路由发生跳转时刷新一次页面
          // to.meta.keepAlive=true
          // window.scrollTop(0,1000)

        }else{
        console.log('22222222222222')
        }
        next()

    },
    
// watch: {
//    $route(to, from) {
//         console.log('22222222222222')

//        window.location.reload(); //监测到路由发生跳转时刷新一次页面
//         this.$router.go(0); 
//         console.log 
//  },
// },
};

</script>
<style scoped>
.page{
    background-color:#F7F8FB;
    /* height: 100vh; */
}
.heaimg{
    width: 100%;
    position: relative; 
    top: 0;
    left: 0;
    height: 5.7rem;
    /* background-color: aqua; */
    margin-bottom: 2.5rem;
}
.headbox{
    width: 6.9rem;
    height: 3.1rem;
    position: absolute;
    top: 5rem;
    left: 50%;
    transform: translate(-50%);
    background-color: rgb(255, 255, 255);
    /* border: 0.01rem solid rgb(44, 42, 42); */
    border-radius: 0.1rem;
    box-sizing: border-box;
    padding:  0.35rem;
}
.headtit{
  font-size: 0.3rem;
  font-weight: bold;
  display: flex;
  margin-bottom: 0.35rem;
}
.headtime{
  font-size: 0.2rem;
  display: flex;

}
.headside{
  width: 4.5rem;
  font-size: 0.2rem;
  font-weight: bold;
  display: flex;
  margin-bottom: 0.2rem;

}
.headjuli{
  font-size: 0.2rem;
  color: #8C8F9F;
  display: flex;
}
.ttimg{
    width: 0.25rem;
    height: 0.25rem;
    margin-top: 0.05rem;
    margin-right: 0.1rem;
    margin-bottom: 0.2rem;
}
.hsjbox{
    display: flex;
    justify-content: space-between;
}
.dhbox{
    border-left: 0.01rem solid #E8EAF3;
    padding: 0 0.31rem;
    height: 0.8rem;
}
.dhimg{
    width: 0.23rem;
    height: 0.23rem;
    /* margin-top: -5rem; */
    margin-left: 0.3rem;
}
.dhbox1{
    margin-top: -0.75rem;
}
.tittle{
    border-left: 0.3rem solid #FECB32;
    font-size: 0.35rem;
    font-weight: bold;
    padding-left: 0.2rem;
    box-sizing: border-box;
    height: 0.45rem;
    margin: 0rem 0; 
    margin-bottom: 0.1rem;
    margin-left: 0.3rem;


}
.xinxibox{
    width: 6.9rem;
    padding: 0.3rem;
    border-radius: 0.2rem;
    background-color: #fff;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 0.28rem;
    margin-bottom: 0.2rem;
    padding-top: 0;
}
.shopbox{
    width: 6.9rem;
    /* padding:  0.15rem; */
    border-radius: 0.2rem;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 0.28rem;
    display: flex;
    flex-wrap: wrap;
}
.shopli{
  width: 2rem;
  /* height: 2.5rem; */
  margin: 0.15rem;
    /* background-color: #fff; */
    overflow: hidden;

}
.shopimg{
  width: 100%;
  height: 2rem;
  /* background-color: #FECB32; */
  border-radius: 0.1rem;

}
.shoptit{
  text-align: center;
  font-size: 0.24rem;
  font-weight: bold;
  line-height: 0.54rem;
  /* background-color: #adaca8; */
  width: 2rem;


}
.classbox{
  width: 6rem;
  /* height: 1rem; */
  /* background: rgb(83, 76, 76); */
  overflow-x: scroll;
  white-space:nowrap;
  /* display: flex; */
  /* padding-top: -1rem; */
  margin-top: -0.35rem;


}
.classitem{
  /* width: 0.6rem; */
  height: 0.7rem;
  background: #fff;
  font-size: 0.24rem;
  display: inline-block;
  margin: 0.05rem;
  white-space:nowrap;
  padding:  0.05rem 0.2rem;
  text-align: center;
}
.clbox{
  display: flex;
  justify-content: space-between;
  padding: 0 0.3rem;
}
.gengduo{
    /* margin-top: -0.1rem; */
    line-height: 0.7rem;
    /* color: #000; */
}
.color{
  background: #FECB32;
}
.jiben{
  width: 6.9rem;
  background-color: #fff;
  padding: 0.2rem 0;
  margin: 0.3rem auto;
}
.jibentext{
  display: flex;
  padding: 0 0.3rem;
  margin: 0.15rem 0;

}
.jibentit{
  width: 1.2rem;
 font-size: 00.24rem;
 margin-right: 0.2rem;
color: rgb(97, 96, 96);
}
.jibencon{
 font-size: 00.24rem;
 display: flex;


}
.connn{
  margin-right: 0.15rem;
}
.red{
  color: red;
}
.hdox{
  width: 6.9rem;
  /* height: 4rem; */
  /* background: #FECB32; */
  margin: 0 auto;
  border: 0.01rem solid #d7d7d8;
  margin-top: 0.2rem;
}
.hdimg{
  width: 100%;
  height: 3.5rem;
}
.hdtit{
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.5rem;
  margin-left: 0.2rem;
  margin-bottom: 0.15rem;
}
.dibox{
  width: 100%;
  background: #ffffff;
  /* padding: 0.2rem; */
  position: fixed;
  bottom: 0;
  left: 0;
  
}
.dibutton{
  width: 6.8rem;
  background-color: #FECB32;
  /* color: #fff; */
  line-height: 0.7rem;
  font-size: 0.3rem;
  text-align: center;
  margin: 0.2rem auto;
  font-weight: bold;
  border-radius: 0.1rem;
}
.dibutton1{
  width: 6.8rem;
  background-color: #b3b3b3;
  /* color: #fff; */
  line-height: 0.7rem;
  font-size: 0.3rem;
  text-align: center;
  margin: 0.2rem auto;
  font-weight: bold;
  border-radius: 0.1rem;
}
.tcbox{
  width: 6.90rem;
  /* height: 8.4rem; */
  /* border: 0.01rem solid red; */
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 11;
  text-align: center;

}
.black{
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.h22img{
    width: 6.90rem;
  height: 8.4rem;

}
.tccon{
  width: 6.50rem;
height: 5.47rem;
background: #FFFFFF;
border-radius: 0.1rem;
position: absolute;
top: 2.76rem;
left: 0.2rem;
padding: 0.2rem;
box-sizing: border-box;
}
.h23img{
  width: 00.72rem;
  height: 0.72rem;

}
.tcimg{
  width: 2.78rem;
height: 2.02rem;
background: #B5001F;
margin-right: 0.3rem;
}
.tcxinxin{
  display: flex;
}
.tctit{
  width: 3rem;

  font-size: 0.3rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: 0.1rem;
  	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;

}
.touzi1{
  font-size: 0.3rem;
  color: #03050F;
  display: flex;
  font-weight: bold;
    	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
}
.touzi2{
  width: 3rem;
  font-size: 0.28rem;
  color: #03050F;
  font-weight: bold;
    	overflow: hidden;  
	white-space: nowrap;
	text-overflow: ellipsis;
  margin-bottom: 0.3rem;
}
.touzi3{
  font-size: 0.2rem;
  color: #8d8d8d;
  display: flex;
  /* font-weight: bold; */
}
.tcnum{
  
font-weight: bold;
color: #EE1818;
margin-bottom: 0.15rem;
margin-left: 0.1rem;
}
.inpbox{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.inp{
  width: 2.8rem;
  height: 0.86rem;
  background: rgba(127,127,127,0);
  border: 0.01rem solid #8C8F9F;
  border-radius: 0.1rem;
  padding-left: 0.12rem;
}
.tcbut{
  width: 6.10rem;
height: 0.81rem;
background: #FECB32;
border-radius: 0.1rem;

font-size: 0.3rem;
font-weight: bold;
color: #03050F;
line-height: 0.24rem;
text-align: center;
line-height: 0.81rem;
margin-top: 0.2rem;
}
.tctext{
  
font-size: 0.24rem;

color: #8C8F9F;
margin-top: 0.3rem;

}
.shlogo{
    position: fixed;
    width: 2.1rem;
    height: 0.9rem;
    background-color: #FECB32;
    border: 0.07rem solid #fff;
    top: 0.5rem;
    right: 0;
    font-size: 0.28rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-right: #FECB32;
    text-align: center;
}
.logotit{
    font-weight: bold;
    margin-top: 0.08rem;
}
.logotext{
    font-size: 0.2rem;
}
.divtiao{
  width: 7rem;
  height: 10rem;
  background: #B5001F;
  position: fixed;
  top: 0;
  left: 0;
}
#launch-btn{
    width: 7rem;
  height: 1rem;
  background: #B5001F;
  position: fixed;
  bottom: 0.5rem;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
}
</style>