<template>
    <div>
        <div class="navbox">
            <!-- <div class="fhimg">&lt;</div> -->
         <div class="heainputbox">
            <div class="ppbox">
                <div>品牌</div>
                <div class="ss"></div>
            </div>
            <input class="input" type="text" placeholder="" v-model="search">
         </div>
         <router-link :to="'/Szixun?search='+search">
            <div class="sstext" @click="ssbut">搜索</div>
         </router-link>
        </div>
        <div class="conbox">
            <div class="sstit">最近搜索</div>
            <div class="listbox">
                <div class="list">地板</div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
  data() {
    return {
      id:'',
      allContent:[],
      content:'',
      search:''
    };
  },

  created() {

  },
  mounted() {
  },
  methods: {
    getAllArr() {
      let formData = new FormData()
      formData.append("id", this.id);
      this.$http({
        method: "post",
        url: "/v1/app-index/home-page-search",
        params: {},
        data: formData,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((res) => {
          console.log(res.data);
    
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ssbut(e){
        console.log(this.search)

    }
  },
};
</script>
<style scoped>
body{
    height: 100%;
    background-color: #fff !important;
}
.navbox{
    display: flex;
    background: #F9F9F9;
    height: 1.28rem;
}
.heainputbox {
  width: 6rem;
  height: 0.68rem;
  background-color: #fff;
  border-radius: 0.34rem;

  display: flex;
  margin-top: 0.3rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;

}
.fhimg{

    font-size: 0.4rem;
  margin-top: 0.3rem;
  margin-left: 0.2rem;
  

}
.sstext{
    font-size: 00.3rem;
    line-height: 1.28rem;
}
.ppbox{
    display: flex;
    font-size: 0.26rem;
    line-height: 0.68rem;
    margin-left: 0.2rem;
    border-right: 0.01rem solid #E4E6F1;
    padding-right: 0.15rem;
}
.ss{
    width: 0;
    height: 0;
    border: 0.05rem solid transparent;;
    border-top: 0.1rem solid rgb(0, 0, 0);
    margin-top: 0.28rem;
    margin-left: 0.15rem;
}
.input{
    border: 0;
    outline: none;
    margin-left: 0.2rem;
}
.conbox{
    padding: 0.3rem;
    background-color: #fff;
    height: 11.5rem;
}
.sstit{
    font-size: 00.3rem;
    
font-weight: bold;
}
.list{
    height: 00.5rem;
    padding: 0rem 0.25rem;
    background: #F8F8F8;
    border-radius: 0.28rem;
    font-size: 0.24rem;
    color: #5D5F6A;
    line-height: 0.5rem;
}
.listbox{
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.3rem;
    
}
</style>