<template>
    <div>
    <div class="haebox">
        <div class="tuimg"></div>
        <div class="heacon">
            <div class="hname">佳佳</div>
            <!-- <div class="hpos">技术总监</div> -->
        </div>
    </div>
    <div class="conlist">
        <div class="list">
            <div class="listcon">
              <img class="m1img" src="../assets/imgs/m1.png" alt="">
              <div class="listtext">名片夹</div>
            </div>
            <div class="ss"></div>
    
        </div>
        
    </div>
    <div class="conlist">
        <div class="list">
            <div class="listcon">
              <img class="m1img" src="../assets/imgs/m1.png" alt="">
              <div class="listtext">我的足迹</div>
            </div>
            <div class="ss"></div>
    
        </div>
        
    </div>
    <div class="conlist">
        <div class="list">
            <div class="listcon">
              <img class="m1img" src="../assets/imgs/m1.png" alt="">
              <div class="listtext">成员管理</div>
            </div>
            <div class="ss"></div>
    
        </div>
        
    </div>
    <div class="conlist conlist1">
        <div class="list">
            <div class="listcon">
              <img class="m1img" src="../assets/imgs/m1.png" alt="">
              <div class="listtext">切换名片</div>
            </div>
            <div class="ss"></div>
    
        </div>
        
    </div>
    <div class="conlist">
        <div class="list">
            <div class="listcon">
              <img class="m1img" src="../assets/imgs/m1.png" alt="">
              <div class="listtext">设置</div>
            </div>
            <div class="ss"></div>
    
        </div>
        
    </div>
    <!-- 底部导航 -->
    <div class="tabBar">
      <div class="tab">
        <router-link to="/">
          <img class="t1img" src="../assets/imgs/t11.png" alt="">
          <div class="tabtext">首页</div>
        </router-link>
      </div>
      <div class="tab">
        <router-link to="/workbench">
          <img class="t1img" src="../assets/imgs/t3.png" alt="">
          <div class="tabtext">工作台</div>
       </router-link>
      </div>
      <div class="tab">
        <router-link to="/radar">
          <img class="t1img" src="../assets/imgs/t2.png" alt="">
          <div class="tabtext">雷达</div>
        </router-link>
      </div>
      <div class="tab">
        <router-link to="/mine">
          <img class="t1img" src="../assets/imgs/t44.png" alt="">
          <div class="tabtext">我的</div>
        </router-link>
      </div>

    </div>     

    </div>
</template>
<style scoped>
.tabBar{
  width: 100%;
  height: 1rem;
  background: #FFFFFF;
  /* box-shadow: 0rem -0.01rem 0.1rem 0rem #EAEAEA; */
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.5rem;
}.tab{
  text-align: center;
  /* background-color: #814220; */
  position: relative;
  width: 0.8rem;
}
.t1img{
  width: 0.38rem;
  height: 0.38rem;
  position: absolute;
  top: 0.15rem;
  left: 50%;
  transform: translate(-50%);
}
.tabtext{
  font-size: 0.18rem;
  margin-top: 0.6rem;

}
.haebox{
    display: flex;
    padding: 0.4rem;
    background-color: #FFFFFF;
    margin-bottom: 0.3rem;
}
.hname{
    font-size: 0.3rem;
    font-weight: bold;
    margin-top: 0.15rem;
}
.hpos{
    font-size: 0.2rem;
}
.tuimg{
    width: 1.1rem;
    height: 1.1rem;
    background-color: #414040;
    border-radius: 50%;
    margin-right: 0.3rem;
}
.conlist{
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 0.36rem ;
    border-bottom: 0.01rem solid #EEEEEE;
}
.conlist1{
    margin-top: 0.3rem;
}
.list{
    width: 100%;
    display: flex;
    justify-content: space-between;
}       
.listcon{
    display: flex;
}
.m1img{
    width: 0.32rem;
    height: 0.28rem;
    margin-right: 0.1rem;
    margin-top: 0.03rem;

}
.listtext{
    font-size: 0.2rem;
}
.ss{
    width: 0;
     height: 0;
     border-top: 0.1rem solid transparent;
     border-bottom: 0.1rem solid white;
     border-left: 0.1rem solid rgb(153, 153, 153);
     border-right: 0.1rem solid white;
     margin-top: 0.1rem;
}
</style>